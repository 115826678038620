// Redeclaring for clarity. Normally, a provider has different props than
// what it's providing but EnvProvider is a literal pass through.
export var ENV;

(function (ENV) {
  ENV["Off"] = "off";
  ENV["Development"] = "development";
  ENV["Staging"] = "staging";
  ENV["Integration"] = "integration";
  ENV["Production"] = "production";
})(ENV || (ENV = {}));