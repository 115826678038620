import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'react';
export var useRecentSearchesDropdown = function useRecentSearchesDropdown() {
  var ENTER_KEYCODE = 13;
  var node = useRef(null);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showOptions = _useState2[0],
      setShowOptions = _useState2[1];

  var handleClick = function handleClick(e) {
    var _node$current;

    if (node === null || node === void 0 ? void 0 : (_node$current = node.current) === null || _node$current === void 0 ? void 0 : _node$current.contains(e.target)) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  var handleExternalScroll = function handleExternalScroll(e) {
    var _node$current2;

    if (!(node === null || node === void 0 ? void 0 : (_node$current2 = node.current) === null || _node$current2 === void 0 ? void 0 : _node$current2.contains(e.target))) {
      setShowOptions(false);
    }
  };

  var handleKeydown = function handleKeydown(e) {
    if (e.keyCode === ENTER_KEYCODE) {
      setShowOptions(false);
    }
  };

  useEffect(function () {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('scroll', handleExternalScroll);
    document.addEventListener('wheel', handleExternalScroll);
    document.addEventListener('keydown', handleKeydown);
    return function () {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('scroll', handleExternalScroll);
      document.removeEventListener('wheel', handleExternalScroll);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  return {
    node: node,
    showOptions: showOptions,
    setShowOptions: setShowOptions
  };
};