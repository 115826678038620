import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  transform: ", ";\n  margin-left: 5px;\n  margin-top: inherit;\n  path {\n    &[stroke] {\n      stroke: ", ";\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  font-size: 14px;\n  line-height: 22px;\n  font-weight: 600;\n  border: none;\n  background: none;\n  text-align: start;\n  padding: 0px;\n  margin-top: 5px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { AcornsIcon } from '@acorns/icons';
import styled from '../utils/styling/styled-components';
import { ChevronDirection } from './constants';
export var ReadMoreButton = styled.button(_templateObject());
export var StyledChevron = styled(AcornsIcon)(_templateObject2(), function (props) {
  return props.direction === ChevronDirection.Down ? 'rotate(90deg)' : 'rotate(-90deg)';
}, function (props) {
  return props.theme.colors.slate;
});