import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  font-size: ", "px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: 120px;\n  object-fit: contain;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
import { DefaultSecurityLogo } from '../index';
var StyledLogo = styled.img(_templateObject(), function (props) {
  return props.width ? props.width : 100;
}, function (props) {
  return props.height ? props.height : 100;
});
var StyledDefaultSecurityLogo = styled(DefaultSecurityLogo)(_templateObject2(), function (props) {
  return props.width ? props.width : 100;
}, function (props) {
  return props.height ? props.height : 100;
}, function (props) {
  return props.fontSize ? props.fontSize : 14;
});
export { StyledLogo, StyledDefaultSecurityLogo };