import { createGlobalStyle } from 'styled-components'

const GlobalFontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Next Web';
    font-style: normal;
    font-weight: 400;
    src: local('Avenir Next Regular'), local('AvenirNext-Regular'), url('/assets/fonts/721263/1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir Next Web';
    font-style: medium;
    font-weight: 500;
    src: local('Avenir Medium'), local('AvenirNext-Medium'), url('/assets/fonts/721266/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir Next Web';
    font-style: demi-bold;
    font-weight: 600;
    src: local('Avenir DemiBold'), local('AvenirNext-DemiBold'), url('/assets/fonts/721266/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff') format('woff');
  }

  body {
    font-family: 'Avenir Next Web', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: 'Avenir Next Web', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  }
`
export { GlobalFontStyles }
