import ReactDOM from 'react-dom';
import { lifecycle } from 'recompose';
export default (function (handler) {
  var eventName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'click';
  return lifecycle({
    componentDidMount: function componentDidMount() {
      this.handleOutside = this.handleOutside.bind(this);
      this.toggleListeners(true);
    },
    componentWillUnmount: function componentWillUnmount() {
      this.toggleListeners(false);
    },
    handleOutside: function handleOutside(event) {
      var _ReactDOM$findDOMNode;

      // If no focus target is provided, we'll use this HOC as a way to locate the element
      var component = this.props.focusTarget || this;

      if (!((_ReactDOM$findDOMNode = ReactDOM.findDOMNode(component)) === null || _ReactDOM$findDOMNode === void 0 ? void 0 : _ReactDOM$findDOMNode.contains(event.target))) {
        handler(this.props);
      }
    },
    toggleListeners: function toggleListeners(on) {
      var method = on ? 'addEventListener' : 'removeEventListener';
      document[method](eventName, this.handleOutside, true);
    }
  });
});