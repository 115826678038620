import { validateMobileIdentity } from './utils';
var POLL_MOBILE_TOKEN_DELAY = 20;
var POLL_MOBILE_TOKEN_TIMEOUT = 5000;
var IOS_POST_MESSAGE_VALUE = 'send token';
var IOS_CLIENT_NAME = 'ios';
/**
 * Polls until iOS app has assigned credentials to window object
 */

var pollIosToken = function pollIosToken(resolve) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : POLL_MOBILE_TOKEN_DELAY;
  var totalDelay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (window.mobileToken !== undefined || totalDelay >= POLL_MOBILE_TOKEN_TIMEOUT) {
    resolve(validateMobileIdentity({
      sessionId: window.mobileSessionId,
      token: window.mobileToken,
      uuid: window.mobileUuid
    }));
    return;
  }

  setTimeout(function () {
    return pollIosToken(resolve, delay, totalDelay + delay);
  }, delay);
};
/**
 * Legacy implementation for resolving user credentials from iOS app
 */


export var resolveIosToken = function resolveIosToken(resolve) {
  ;
  window.webkit.messageHandlers.getAuthentication.postMessage(IOS_POST_MESSAGE_VALUE);
  pollIosToken(resolve);
};
/**
 * Returns true if it's determined that the parent window is housed inside iOS app
 */

export var isIos = function isIos(client) {
  var _webkit, _webkit$messageHandle;

  return client === IOS_CLIENT_NAME && ((_webkit = window.webkit) === null || _webkit === void 0 ? void 0 : (_webkit$messageHandle = _webkit.messageHandlers) === null || _webkit$messageHandle === void 0 ? void 0 : _webkit$messageHandle.getAuthentication) !== undefined;
};