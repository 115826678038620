import { UserScopedSessionStorage } from '../browser-storage';
import { DEFAULT_USER_ID } from '../providers/userId/UserIdProvider';
import { getWindow } from '../getWindow';
import { useUserId } from './useUserId';
export function useUserScopedSessionStorage() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useUserId = useUserId(),
      userId = _useUserId.userId;

  var userScopedSessionStorage = userId === DEFAULT_USER_ID ? null : new UserScopedSessionStorage(userId);
  var window = getWindow();

  if (options.addStorageToGlobalScope && userScopedSessionStorage) {
    window.acornsweb.userScopedSessionStorage = userScopedSessionStorage;
  } else {
    delete window.acornsweb.userScopedSessionStorage;
  }

  return {
    userScopedSessionStorage: userScopedSessionStorage
  };
}