import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 10px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 20px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  width: 600px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { Button, Modal, PrivateInput, TextInput, Title, styled } from '@acorns/web-components';
var StyledModal = styled(Modal)(_templateObject()); // Not a real error. Fixed with styled-components v5
//@ts-ignore

var StyledTitle = styled(Title)(_templateObject2());
var StyledTextInput = styled(TextInput)(_templateObject3());
var StyledPrivateInput = styled(PrivateInput)(_templateObject4());
var StyledButton = styled(Button)(_templateObject5());
export { StyledButton, StyledModal, StyledPrivateInput, StyledTitle, StyledTextInput };