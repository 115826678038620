import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
// Copied from https://usehooks.com/useScript/
import { useState, useEffect } from 'react'; // Hook

var cachedScripts = [];

var useScript = function useScript(src) {
  // Keeping track of script loaded and error state
  var _useState = useState({
    loaded: false,
    error: false
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false
      });
    } else {
      // Create script
      var script = document.createElement('script');
      script.src = src;
      script.async = true; // Script event listener callbacks for load and error

      var onScriptLoad = function onScriptLoad() {
        if (!cachedScripts.includes(src)) {
          cachedScripts.push(src);
        }

        setState({
          loaded: true,
          error: false
        });
      };

      var onScriptError = function onScriptError() {
        // Remove from cachedScripts we can try loading again
        var index = cachedScripts.indexOf(src);

        if (index >= 0) {
          cachedScripts.splice(index, 1);
        }

        script.remove();
        setState({
          loaded: true,
          error: true
        });
      };

      script.onload = onScriptLoad;
      script.onerror = onScriptError; // Add script to document body

      document.body.appendChild(script);
    }
  }, [src]); // Only re-run effect if script src changes

  return [state.loaded, state.error];
};

export { useScript };