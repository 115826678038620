import PropTypes from 'prop-types';
import { compose, getContext, lifecycle, withContext } from 'recompose';
import { Braze, createBrazeClient } from '../braze';
import { ChildrenOnly } from '../../ChildrenOnly';
var BrazePropTypes = {
  braze: PropTypes.instanceOf(Braze).isRequired
};
var Provider = compose(withContext(BrazePropTypes, function (_ref) {
  var brazeApiKey = _ref.brazeApiKey;
  return {
    braze: createBrazeClient(brazeApiKey)
  };
}));
var BrazeRecomposeProvider = Provider(ChildrenOnly);
var withBraze = getContext(BrazePropTypes);
var withBrazeMessage = compose(withBraze, lifecycle({
  componentDidMount: function componentDidMount() {
    this.props.braze.showMessage();
  }
}));
export { BrazeRecomposeProvider, withBraze, withBrazeMessage };