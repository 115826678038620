import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 0;\n  clip: rect(0, 0, 0, 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
import { SCREEN_READER_ONLY } from '../utils/testing/TestIDs'; // https://a11yproject.com/posts/how-to-hide-content/

export var ScreenReaderOnly = styled.span.attrs(_defineProperty({}, 'data-testid', SCREEN_READER_ONLY))(_templateObject());