import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router-dom'

import { theme } from '@acorns/web-components'
import { useAnalyticsClient } from '@acorns/web-utils'

import acornsTitle from 'assets/images/acorns-title.svg'
import { resetPasswordSuccessScreenView } from 'utils/segment'

import Layout from '../../components/Layout'
import {
  BodyText,
  CenterContainer,
  StyledButton,
  StyledDiv,
  StyledLoader,
  StyledPage,
  StyledTitle,
} from './styled-components'

export const ResetPasswordSuccess = () => {
  const history = useHistory()
  const analytics = useAnalyticsClient()

  const onPress = () => {
    history.push('/')
  }

  useEffect(() => {
    analytics.track('Screen Viewed', resetPasswordSuccessScreenView())
  }, [analytics])

  return (
    <>
      <MediaQuery minWidth={theme.breakpoints.sm + 1}>
        <Layout title={<img src={acornsTitle} width={97} height={26} />}>
          <CenterContainer>
            <StyledDiv>
              <StyledLoader loading={false} isStatic={true} />

              <StyledTitle>Password Changed!</StyledTitle>
              <BodyText>
                Your Acorns Password was successfully changed!
              </BodyText>

              <StyledButton onPress={onPress}>Go to Login</StyledButton>
            </StyledDiv>
          </CenterContainer>
        </Layout>
      </MediaQuery>

      <MediaQuery maxWidth={theme.breakpoints.sm}>
        <Layout title={<img src={acornsTitle} width={97} height={26} />}>
          <StyledPage
            content={
              <StyledDiv>
                <StyledLoader loading={false} isStatic={true} />

                <StyledTitle>Password Changed!</StyledTitle>
                <BodyText>
                  Your Acorns Password was successfully changed!
                </BodyText>
              </StyledDiv>
            }
            footer={<StyledButton onPress={onPress}>Go to Login</StyledButton>}
          />
        </Layout>
      </MediaQuery>
    </>
  )
}
