import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  line-height: 30px;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n      animation: ", " 0.5s;\n      animation-fill-mode: forwards;\n    "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n      animation: ", " 0.5s;\n      animation-fill-mode: forwards;\n    "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-weight: 600;\n  font-size: 26px;\n  user-select: none;\n  z-index: 5;\n\n  ", "\n\n  ", "\n    \n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n    transform: translateY(50px);\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(50px);\n  }\n  100% {\n    opacity: 1;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      animation: ", " 0.3s;\n      animation-fill-mode: forwards;\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      animation: ", " 0.3s;\n      animation-fill-mode: forwards;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  user-select: none;\n\n  ", "\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n    transform: translateY(50px);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(-50px);\n  }\n  100% {\n    opacity: 1;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css, keyframes } from '../../utils/styling/styled-components';
import { Heading } from '../../Typography';
var fadeInDown = keyframes(_templateObject());
var fadeOutUp = keyframes(_templateObject2());
export var InnerValueContainer = styled.div(_templateObject3(), function (props) {
  return props.animate === 1 && css(_templateObject4(), fadeOutUp);
}, function (props) {
  return props.animate === 0 && css(_templateObject5(), fadeInDown);
});
var fadeInUp = keyframes(_templateObject6());
var fadeOutDown = keyframes(_templateObject7());
export var InnerPercentContainer = styled.div(_templateObject8(), function (props) {
  return props.animate === -1 && "\n      display: none;\n    ";
}, function (props) {
  return props.animate === 1 && css(_templateObject9(), fadeInUp);
}, function (props) {
  return props.animate === 0 && css(_templateObject10(), fadeOutDown);
});
export var StyledHeading = styled(Heading)(_templateObject11(), function (props) {
  return props.fontSize;
});