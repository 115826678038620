export const LOGIN_FORM = 'login_form'
export const MAINTENANCE_CARD = 'maintenance_card'
export const TITLE_BAR_CLOSE_BUTTON = 'title_bar_close_button'
export const TITLE_BAR_SHARE_BUTTON = 'title_bar_share_button'
export const TITLE_BAR_MOBILE_CLOSE_BUTTON = 'title_bar_mobile_close_button'
export const TITLE_BAR_MOBILE_SHARE_BUTTON = 'title_bar_mobile_share_button'
export const SIGN_UP_INSTEAD_LINK = 'sign_up_instead_link'
export const OAUTH_PARTNER_TITLE = 'oauth_partner_title'
export const OAUTH_CHROME_EXTENSION_TITLE = 'oauth__chrome_extension_title'
export const OAUTH_BROWSER_EXTENSION_TITLE = 'oauth_browser_extension_title'
export const MODAL_BODY = 'modal_body'
export const RESET_PASSWORD = 'reset_password_component'
export const RESET_PASSWORD_INVALID_TOKEN = 'reset_password_invalid_token'
export const RESET_PASSWORD_SSN_FIELD = 'reset_password_ssn_field'
export const RESET_PASSWORD_BUTTON = 'reset_password_button'
export const RESET_PASSWORD_LOADER = 'reset_password_loader'
export const AGGREGATOR_CONTENT_LOGO = 'aggregator_content_logo'
export const PARTNER_CONTENT_LOGO = 'partner_content_logo'
export const OAUTH_AUTHORIZATION_PARTNER_LOGO =
  'oauth_authorization_partner_logo'
export const OAUTH_AUTHORIZATION_PARTNER_NAME =
  'oauth_authorization_partner_name'
export const OAUTH_AUTHORIZATION_ALLOW_BUTTON =
  'oauth_authorization_allow_button'
