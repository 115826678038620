import snakeCase from 'lodash-es/snakeCase';

var platform = function platform() {
  return /iPhone|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop';
};

var defaults = function defaults(event) {
  return {
    event_text: event,
    event: snakeCase(event),
    platform: platform
  };
};

export { defaults };