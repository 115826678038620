import { useContext } from 'react';
import * as styledComponents from 'styled-components'; // eslint-disable-line no-restricted-imports
// eslint-disable-line no-restricted-imports, import/named

var _ref = styledComponents,
    styled = _ref["default"],
    css = _ref.css,
    createGlobalStyle = _ref.createGlobalStyle,
    keyframes = _ref.keyframes,
    ThemeProvider = _ref.ThemeProvider,
    ThemeContext = _ref.ThemeContext,
    withTheme = _ref.withTheme;
export var useTheme = function useTheme() {
  return useContext(ThemeContext);
};
export { css, createGlobalStyle, keyframes, ThemeProvider, withTheme, styled };
export default styled;