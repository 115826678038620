import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 14px;\n  font-weight: 600;\n  margin: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var Heading2 = styled.h3(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.slate;
});
export { Heading2 };