import React from 'react'

import { ConfirmationModal, ModalActionTypes } from '@acorns/web-components'

import AlertBadge from 'assets/icons/alert-badge.png'
import Environment from 'utils/environment'

type Props = {
  isExceptionModalOpen: boolean
  handleCloseExceptionModal: () => void
  env: Environment
}

export const UserSuspendedExceptionModal = (props: Props) => {
  return (
    <ConfirmationModal
      isOpen={props.isExceptionModalOpen}
      actions={[
        {
          handler: props.handleCloseExceptionModal,
          label: 'Cancel',
          type: ModalActionTypes.secondary,
        },
        {
          handler: () => {
            window.location.href = props.env?.get('suspendedUserRedirectUrl')
          },
          label: 'Document Hub',
          type: ModalActionTypes.primary,
        },
      ]}
      title="Acorns Account Suspended"
      message="For security purposes, we've temporarily suspended your Acorns account. Please check your email for instructions on how to regain access. Or, feel free to contact our Support team."
      image={AlertBadge}
    />
  )
}
