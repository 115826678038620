import PropTypes from 'prop-types';
import { getContext, withContext, compose } from 'recompose';
import { ChildrenOnly } from '../../ChildrenOnly';
/**
 * This is not correct typing but we made Env a class before and PropTypes
 * don't play nicely with classes.
 */

var EnvPropTypes = {
  env: PropTypes.any
};
var EnvRecomposeProvider = compose(withContext(EnvPropTypes, function (_ref) {
  var env = _ref.env;
  return {
    env: env
  };
}))(ChildrenOnly);
var withEnv = getContext(EnvPropTypes);
export { EnvRecomposeProvider, withEnv };