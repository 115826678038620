import React from 'react'

import styled from 'styled-components'

import { TitleBar, TitleBarProps } from 'src/TitleBar'

// Note: We render a full viewport-sized container to
// center the Loader component in
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  padding-top: 164px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 76px 30px 0;
  }
`

const Layout: React.FC<TitleBarProps> = ({
  title,
  showBack,
  onBackPress,
  rightButton,
  children,
}) => (
  <Container>
    <TitleBar
      showBack={showBack}
      title={title}
      onBackPress={onBackPress}
      rightButton={rightButton}
    />
    {children}
  </Container>
)

export default Layout
