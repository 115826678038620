export var ButtonStyle;

(function (ButtonStyle) {
  ButtonStyle["column"] = "column";
  ButtonStyle["row"] = "row";
})(ButtonStyle || (ButtonStyle = {}));

export var ModalActionTypes;

(function (ModalActionTypes) {
  ModalActionTypes["danger"] = "danger";
  ModalActionTypes["primary"] = "primary";
  ModalActionTypes["secondary"] = "secondary";
})(ModalActionTypes || (ModalActionTypes = {}));