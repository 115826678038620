export default {
  '/redeem': {
    login: 'Log In',
    signupDescription: "Have a gift card but don't have an account yet?",
    signUpCta: 'Sign Up Instead',
  },
  default: {
    login: 'Log In',
    signUpCta: "Don't Have An Account?",
  },
}
