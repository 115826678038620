import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 44px;\n  height: 44px;\n  border-radius: 100%;\n  background: ", ";\n\n  &::after {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: url(\"", "\") no-repeat center center;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import LeftArrowURL from '../assets/icons/left-arrow.svg';
import { TouchableOpacity } from '../TouchableOpacity';
import styled from '../utils/styling/styled-components';
import { BACK_BUTTON } from '../utils/testing/TestIDs'; // @ts-ignore

var BackButton = styled(TouchableOpacity).attrs({
  'data-testid': BACK_BUTTON
})(_templateObject(), function (props) {
  return props.theme.colors.ivory;
}, LeftArrowURL);
export { BackButton };