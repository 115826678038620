import { conformToMask } from 'react-text-mask';
var allowedKeysRegex = /^\d{1}$/;
var phoneMask = ['(', allowedKeysRegex, allowedKeysRegex, allowedKeysRegex, ')', ' ', allowedKeysRegex, allowedKeysRegex, allowedKeysRegex, '-', allowedKeysRegex, allowedKeysRegex, allowedKeysRegex, allowedKeysRegex];

var formatPhoneLength = function formatPhoneLength() {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -10;
  return val.slice(length);
};

var stripNonDigits = function stripNonDigits() {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return val.replace(/\D/g, '');
}; // Cuts the phone  down to 10 digits (xxx) xxx - xxxx


var conformToPhoneMask = function conformToPhoneMask() {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return conformToMask(val, phoneMask, {
    guide: false
  });
};

export var usePhoneNumber = function usePhoneNumber(phoneNumber) {
  var formattedPhoneNumber = conformToPhoneMask(formatPhoneLength(stripNonDigits(phoneNumber).slice(-10))).conformedValue;
  return {
    formatPhoneLength: formatPhoneLength,
    stripNonDigits: stripNonDigits,
    conformToPhoneMask: conformToPhoneMask,
    phoneMask: phoneMask,
    formattedPhoneNumber: formattedPhoneNumber
  };
};