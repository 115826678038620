import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 50px;\n  margin-bottom: 50px;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  color: ", ";\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 300;\n  margin-top: 5px;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0.6;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100vh;\n  height: 100vh;\n  z-index: 100;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 14px;\n  line-height: 22px;\n  pointer-events: none;\n  &:focus {\n    outline: none;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: 14px;\n  line-height: 22px;\n  min-height: 60px;\n  justify-content: space-between;\n  padding: 0 15px;\n  transition: opacity 200ms;\n  ", "\n\n  &:focus {\n    outline: 0;\n  }\n\n  &:hover {\n    background-color: #fafafa;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 0;\n  list-style: none;\n  background-color: #fff;\n  border-radius: ", ";\n  box-shadow: ", ";\n  left: 0;\n  max-height: 356px;\n  overflow: auto;\n  outline: none;\n  position: absolute;\n  right: 0;\n  top: 64px;\n  z-index: 101;\n\n  ", " li:not(:last-child) {\n    border-bottom: 1px solid ", ";\n  }\n\n  ", ";\n\n  @media screen and (max-width: ", "px) {\n    ", "\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background: url(\"", "\") no-repeat center right 16px;\n  box-sizing: border-box;\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  &:disabled {\n    cursor: default;\n  }\n  &:focus,\n    &:hover {\n      outline: none;\n    }\n\n  ", "\n\n  ", "\n  -webkit-appearance: none;\n  -webkit-border-radius: 10px;\n\n  -moz-appearance: none;\n  text-indent: 0.01px;\n  text-overflow: '';\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  font-size: 12px;\n  font-weight: 600;\n  line-height: 16px;\n  margin-bottom: 2px;\n  color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 16px;\n  top: 20px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    min-height: 64px;\n    box-sizing: border-box;\n    background-color: ", ";\n    padding: 13px 36px 11px 16px;\n    border-radius: 10px;\n    display: block;\n    position: relative;\n    user-select: none;\n    transition: opacity 150ms ease-in-out;\n    ", "\n    ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/* stylelint-disable property-no-vendor-prefix */
import styled, { css } from '../../utils/styling/styled-components';
import { FieldError } from '../../Form/FieldError';
import Chevron from '../chevron.svg';
import { Spinner } from '../../Spinner';
export var Container = styled.div(function (props) {
  return css(_templateObject(), function (props) {
    return props.theme.colors.ivoryLight;
  }, props['data-error'] && "\n      border-color: ".concat(props.theme.colors.red, ";\n      color: ").concat(props.theme.colors.red, ";\n\n      &:focus, &:hover {\n        border-color: ").concat(props.theme.colors.red, ";\n      }\n    "), function (props) {
    return props['data-disabled'] && "\n      cursor: default;\n      opacity: 0.3;\n    ";
  });
});
export var IconContainer = styled.div(_templateObject2());
export var Label = styled.label(_templateObject3(), function (props) {
  return props.theme.colors.slate;
});
export var Select = styled.select(_templateObject4(), function (props) {
  return !props.icon && Chevron;
}, function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.placeholderVisible && "\n    color: ".concat(props.theme.colors.stoneLight, ";\n  ");
}, function (props) {
  return props.useNative && "\n    padding: 16px 15px 0;\n    outline: none;\n    border: 1px ".concat(props.theme.colors.stoneLight, ";\n  ");
});
export var Options = styled.div(_templateObject5(), function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return props.theme.shadows.lgMedium;
}, function (props) {
  return props.position === 'top' && "\n    bottom: 0;\n    margin-bottom: 10px;\n    top: auto;\n  ";
}, function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.offsetTop && "\n    top: ".concat(props.offsetTop, "px;\n  ");
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.showResponsiveOverlay && "\n      bottom: 20px;\n      left: 20px;\n      position: fixed;\n      right: 20px;\n      top: auto;\n      width: auto;\n      max-height: 60vh;\n      box-shadow: ".concat(function (props) {
    return props.theme.shadows.lgDark;
  }, ";\n\n      &::before {\n        display: none;\n      }");
});
export var Option = styled.li(_templateObject6(), function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.keyboardSelected ? 'background-color: #fafafa;' : '';
});
export var Value = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.slate;
});
export var Overlay = styled.div(_templateObject8(), function (props) {
  return props.theme.colors.slate;
});
export var Placeholder = styled(Value)(_templateObject9(), function (props) {
  return props.theme.colors.stone;
});
export var ErrorLabel = styled(FieldError)(_templateObject10(), function (props) {
  return props.theme.colors.red;
});
export var StyledSpinner = styled(Spinner)(_templateObject11());