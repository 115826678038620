import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import ReactDOM from 'react-dom';
import { lifecycle } from 'recompose';

function withClickOutside(handler) {
  var eventName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'click';
  return lifecycle({
    componentDidMount: function componentDidMount() {
      this.handleOutside = this.handleOutside.bind(this);
      this.toggleListeners(true);
    },
    componentWillUnmount: function componentWillUnmount() {
      this.toggleListeners(false);
    },
    handleOutside: function handleOutside(event) {
      // If no focus target is provided, we'll use this HOC as a way to locate the element
      var component = this.props.focusTarget || this;

      if (!ReactDOM.findDOMNode(component).contains(event.target)) {
        handler(_objectSpread(_objectSpread({}, this.props), {}, {
          clickTarget: event.target
        }));
      }
    },
    toggleListeners: function toggleListeners(on) {
      var method = on ? 'addEventListener' : 'removeEventListener';
      document[method](eventName, this.handleOutside, true);
    }
  });
}

export { withClickOutside };