import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  position: fixed;\n  left: 320px;\n  width: 100%;\n  top: 0;\n  height: ", "px;\n  z-index: 19;\n  ", "\n  line-height: 0.01px;\n  border-bottom: 1.5px solid ", ";\n  background-color: ", ";\n\n  @media screen and (min-width: ", "px) and (max-width: ", "px) {\n    left: ", ";\n  }\n\n  @media screen and (min-width: ", "px) and (max-width: 1050px) {\n    left: ", ";\n  }\n\n  @media screen and (max-width: ", "px) {\n    ", "\n  }\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n        position: relative;\n        min-width: initial;\n      "]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n        border-bottom: 1.5px solid ", ";\n      "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n      position: relative;\n      min-width: initial;\n    "]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  min-width: ", ";\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 0 auto;\n  box-sizing: content-box;\n  z-index: 20;\n  ", ";\n  @media screen and (min-width: ", "px) {\n    flex-wrap: wrap;\n    top: 0;\n  }\n\n  ", "\n\n  @media screen and (max-width: ", "px) {\n    height: 64px;\n    ", ";\n    padding: 0 20px;\n    ", "\n  }\n    height: ", "px;\n    flex: 1;\n    ", ";\n    ", "\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n        height: 64px;\n        position: fixed;\n        z-index: 20;\n      "]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n        height: ", "px;\n        z-index: 20;\n      "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n        background-color: ", ";\n      "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  top: 0;\n  ", "\n  ", "\n\n  height: 104px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  transition: height 0.1s;\n\n  @media screen and (min-width: ", "px) {\n    ", "\n\n    ", "\n  }\n\n  @media screen and (max-width: ", "px) {\n    background-color: ", ";\n    top: 0px;\n    align-items: center;\n    display: flex;\n    ", ";\n    ", "\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-height: ", ";\n\n  @media screen and (min-width: ", "px) {\n    position: sticky;\n    top: 0;\n    z-index: 20;\n  }\n\n  @media screen and (max-width: ", "px) {\n    ", ";\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  @media screen and (max-width: ", "px) {\n    height: 50%;\n    z-index: 10;\n    border-radius: 0;\n    opacity: 1;\n  }\n\n  @media screen and (min-width: ", "px) {\n    margin-right: 16px;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  > *:last-child {\n    margin: 0;\n  }\n\n  @media screen and (max-width: ", "px) {\n    flex: 1;\n    justify-content: flex-end;\n\n    ", ";\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  @media screen and (min-width: ", "px) {\n    display: none;\n  }\n  @media screen and (max-width: ", "px) {\n    display: block;\n    ", ";\n  }\n\n  @media screen and (max-width: ", "px) {\n    right: 6px;\n\n    ", ";\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    @media screen and (max-width: ", "px) {\n      flex: 1;\n      display: flex;\n      justify-content: center;\n      position: relative;\n      display: block;\n      text-align: center;\n      margin: auto;\n      left: 0;\n  \n      ", ";\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n    margin: 0 auto;\n    > h2 {\n      margin: 0;\n      font-size: 14px;\n      line-height: 22px;\n    }\n  \n    @media screen and (max-width: ", "px) {\n      text-align: center;\n      height: 100%;\n      display: flex;\n      justify-content: center;\n      white-space: nowrap;\n      position: relative;\n      flex: 1;\n      align-items: center;\n      left: 0;\n  \n      ", ";\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: inline;\n  font-size: 22px;\n  font-weight: 400;\n  line-height: 30px;\n\n  @media screen and (max-width: ", "px) {\n    ", ";\n  }\n  @media screen and (max-width: ", "px) {\n    opacity: 0.6;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 22px;\n  }\n\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: inline;\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 22px;\n  margin: 0;\n\n  @media screen and (max-width: ", "px) {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  @media screen and (max-width: ", "px) {\n    flex: 1;\n    justify-content: flex-start;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  justify-content: space-between;\n  margin: 0 auto;\n  max-width: ", "px;\n  box-sizing: content-box;\n\n  @media screen and (min-width: ", "px) {\n    flex-wrap: wrap;\n  }\n\n  @media screen and (min-width: ", "px) {\n    padding: 0 60px;\n  }\n\n  @media screen and (min-width: ", "px) and (max-width: ", "px) {\n    padding: 0 30px;\n  }\n\n  @media screen and (max-width: ", "px) {\n    flex: 1;\n    ", ";\n    padding: 0 20px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n        z-index: 20;\n        height: 64px;\n        position: fixed;\n      "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n        height: ", "px;\n        position: fixed;\n        z-index: 20;\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        background-color: ", ";\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  top: 70px;\n\n  position: fixed;\n\n  height: 104px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  transition: height 0.1s;\n\n  ", ";\n\n  @media screen and (min-width: ", "px) {\n    top: 80px;\n  }\n\n  @media screen and (min-width: ", "px) {\n    ", "\n\n    ", "\n  }\n\n  @media screen and (max-width: ", "px) {\n    background-color: ", ";\n    top: ", ";\n\n    ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-height: 63px;\n\n  @media screen and (max-width: ", "px) {\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { theme } from '../theme';
import styled, { css } from '../utils/styling/styled-components';
import { A4_BackButton as BackButton } from '../A4_BackButton';
import { SettingsButton } from '../SettingsButton';
var Container = styled.div(_templateObject(), theme.breakpoints.sm, function (props) {
  return props.showInverted && 'min-height: 0px';
});
var Content = styled.div(_templateObject2(), function (props) {
  return props.scrollPercent >= 0.1 && 'box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04)';
}, theme.breakpoints.md + 1, theme.breakpoints.sm + 1, function (props) {
  return props.scrollPercent >= 0.1 && css(_templateObject3(), props.stripColor);
}, function (props) {
  return props.sticky && css(_templateObject4(), 104 - (104 - props.stickyHeight) * props.scrollPercent);
}, theme.breakpoints.sm, function (props) {
  return props.stripColor;
}, function (props) {
  return "".concat(props.top, "px") || '69px';
}, function (props) {
  return props.sticky && css(_templateObject5());
});
var Constrainer = styled.div(_templateObject6(), function (props) {
  return props.maxWidth;
}, theme.breakpoints.sm + 1, theme.breakpoints.md + 1, theme.breakpoints.sm + 1, theme.breakpoints.md, theme.breakpoints.sm, function (props) {
  return props.customStyle ? props.customStyle : 'flex-wrap: wrap';
});
var TitleContainer = styled.div(_templateObject7(), theme.breakpoints.sm);
var Title = styled.h1(_templateObject8(), theme.colors.slate, theme.breakpoints.sm);
var Subtitle = styled.h2(_templateObject9(), theme.colors.slate, theme.breakpoints.md, function (props) {
  return props.sticky && "\n        text-align: center;\n        white-space: nowrap;\n      ";
}, theme.breakpoints.sm, function (props) {
  return props.customSubtextStyle && props.customSubtextStyle;
});
var VerticalSubTextContainer = styled.div(_templateObject10(), theme.breakpoints.sm, function (props) {
  return !props.hasActions && "position: absolute; \n        text-align: center; \n        width: 100%;";
});
var HorizontalSubtextContainer = styled.div(_templateObject11(), theme.breakpoints.sm, function (props) {
  return !props.hasActions && "position: absolute; \n        text-align: center; \n        width: 100%;";
});
var SettingsButtonStyled = styled(SettingsButton)(_templateObject12(), theme.breakpoints.md + 1, theme.breakpoints.md, function (props) {
  return props.sticky && "\n      background-color: transparent;\n      box-shadow: none;\n      right: 16px;\n      position: absolute;\n      top: 50%;\n      transform: translateY(-50%);\n      ";
}, theme.breakpoints.sm, function (props) {
  return props.sticky && "\n        right: 6px;\n      ";
});
var ActionContainer = styled.div(_templateObject13(), theme.breakpoints.sm, function (props) {
  return (props.sticky || props.showInverted) && !props.showMobileActions && "\n        display: none;\n      ";
});
var BackButtonStyled = styled(BackButton)(_templateObject14(), theme.breakpoints.sm, theme.breakpoints.sm + 1);
var SideNavContainer = styled.div(_templateObject15(), function (props) {
  return !props.withSideNav && '63px';
}, theme.breakpoints.sm + 1, theme.breakpoints.sm, function (props) {
  return props.showInverted && 'min-height: 0px';
});
var SideNavContent = styled.div(_templateObject16(), function (props) {
  return !props.withSideNav && "\n    position: fixed;\n  ";
}, function (props) {
  return !props.withSideNav && "\n    @media screen and (min-width: ".concat(theme.breakpoints.md + 1, "px){\n      padding: 0 60px;\n    }\n    @media screen and (min-width: ").concat(theme.breakpoints.sm + 1, "px) and (max-width: ").concat(theme.breakpoints.md, "px){\n      padding: 0 30px;\n    }\n  ");
}, theme.breakpoints.sm + 1, function (props) {
  return props.scrollPercent >= 0.1 && css(_templateObject17(), props.stripColor);
}, function (props) {
  return props.sticky && css(_templateObject18(), 104 - (104 - props.stickyHeight) * props.scrollPercent);
}, theme.breakpoints.sm, function (props) {
  return props.stripColor;
}, function (props) {
  return props.sticky && css(_templateObject19());
}, function (props) {
  return props.scrollPercent >= 0.1 && "border-bottom: 1.5px solid ".concat(function (props) {
    return props.theme.colors.ivory;
  }, ";");
});
var SideNavConstrainer = styled.div(_templateObject20(), function (props) {
  return "".concat(props.minWidth, "px") || '1200px';
}, function (props) {
  return props.maxWidth && "max-width:".concat(props.maxWidth, "px");
}, theme.breakpoints.sm + 1, function (props) {
  return !props.withSideNavEnabled && css(_templateObject21());
}, theme.breakpoints.sm, function (props) {
  return props.customStyle ? props.customStyle : 'flex-wrap: wrap';
}, function (props) {
  return props.scrollPercent >= 0.1 && css(_templateObject22(), theme.colors.ivory);
}, function (props) {
  return 104 - (104 - props.stickyHeight) * props.scrollPercent;
}, function (props) {
  return props.customStyle ? props.customStyle : 'flex-wrap: wrap';
}, function (props) {
  return props.withSideNavEnabled && css(_templateObject23());
});
var Underline = styled.div(_templateObject24(), function (props) {
  return props.scrollPercent <= 0.1 && "display: none;";
}, function (props) {
  return 104 - (104 - props.stickyHeight) * props.scrollPercent;
}, function (props) {
  return !props.withSideNav && "width: 100vw; \n    left: 0; \n    height: ".concat(104 - (104 - props.stickyHeight) * props.scrollPercent + 1, "px; \n    ");
}, function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.breakpoints.sm + 1;
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return !props.withSideNav ? '0px' : '94px';
}, function (props) {
  return props.theme.breakpoints.md + 1;
}, function (props) {
  return !props.withSideNav ? '0px' : '230px';
}, theme.breakpoints.sm, function (props) {
  return !props.withSideNav && 'top: 50px;';
});
export { Subtitle, TitleContainer, Title, Content, Constrainer, VerticalSubTextContainer, Container, BackButtonStyled, ActionContainer, SettingsButtonStyled, HorizontalSubtextContainer, SideNavContainer, Underline, SideNavConstrainer, SideNavContent };