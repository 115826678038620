import React from 'react'

import { createGlobalStyle } from 'styled-components'

import CloseBackSVG from 'assets/icons/close-black.svg'
import theme from 'theme'

const GlobalStyling = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  body {
    background-color: ${theme.colors.white};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    outline: 0;
  }

  .Resizer {
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .Pane2 {
    overflow-y: auto;
  }

  /* Adds close icon to Braze messages so that we don't need font-awesome */
  .ab-close-button {
    background-image: url(${CloseBackSVG});
    background-position: center center;
    background-repeat: no-repeat;
    color: rgb(196, 196, 196);
    opacity: 0.6;
  }

  body .ab-in-app-message {
    font-family: 'Avenir Next Web', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    color: ${theme.colors.slate}
  }

  body .ab-in-app-message .ab-message-button {
    font-size: 16px;
    font-weight: bold;
    color: #fff !important;
    background-color: ${theme.colors.green} !important;
  }

  body .ab-in-app-message.ab-fullscreen .ab-message-buttons~.ab-message-text {
    margin-bottom: 100px;
  }
`

export const GlobalStyles = () => {
  return <GlobalStyling />
}
