import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  width: 100%;\n  z-index: 199;\n  top: 0;\n  left: 0;\n  opacity: 0.1;\n  height: 100%;\n  background-color: ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../../utils/styling/styled-components';
export var Overlay = styled.div(_templateObject(), function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return !props.showOverlay && 'display: none;';
});