module.exports={
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "mutation",
      "name": {
        "kind": "Name",
        "value": "ChallengeAuthenticate"
      },
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "AuthenticateInput"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "challengeAnswerInput"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ChallengeAnswerInput"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "alternateAuthenticatorId"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "oauth"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          },
          "defaultValue": {
            "kind": "BooleanValue",
            "value": false
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "authenticate"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "input"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "challengeAnswerInput"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "challengeAnswerInput"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "alternateAuthenticatorId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "alternateAuthenticatorId"
                  }
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "__typename"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "SMSAuthChallenge"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "maskedPhoneNumber"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "alternateAuthenticators"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "type"
                              },
                              "arguments": [],
                              "directives": []
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "EmailAuthChallenge"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "maskedEmail"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "alternateAuthenticators"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "type"
                              },
                              "arguments": [],
                              "directives": []
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "AuthSession"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "token"
                        },
                        "arguments": [],
                        "directives": [
                          {
                            "kind": "Directive",
                            "name": {
                              "kind": "Name",
                              "value": "skip"
                            },
                            "arguments": [
                              {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "if"
                                },
                                "value": {
                                  "kind": "Variable",
                                  "name": {
                                    "kind": "Name",
                                    "value": "oauth"
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "refreshToken"
                        },
                        "arguments": [],
                        "directives": [
                          {
                            "kind": "Directive",
                            "name": {
                              "kind": "Name",
                              "value": "skip"
                            },
                            "arguments": [
                              {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "if"
                                },
                                "value": {
                                  "kind": "Variable",
                                  "name": {
                                    "kind": "Name",
                                    "value": "oauth"
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "identityId"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "AuthChallengeAnswerIncorrectException"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "message"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "AuthChallengeAnswerAttemptsExhaustedException"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "message"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "AuthChallengeExpiredException"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "message"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "UserLockedException"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "message"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "UserSuspendedException"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "message"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "suspensionReasonValid"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "suspendedToken"
                        },
                        "arguments": [],
                        "directives": [
                          {
                            "kind": "Directive",
                            "name": {
                              "kind": "Name",
                              "value": "skip"
                            },
                            "arguments": [
                              {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "if"
                                },
                                "value": {
                                  "kind": "Variable",
                                  "name": {
                                    "kind": "Name",
                                    "value": "oauth"
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 1114
  }
};