import { compose, defaultProps, withProps } from 'recompose';
import { withTheme } from '../../utils/styling/styled-components';
// Sets error properties related to minimum value and maximum values
var withErrorProps = compose(withTheme, defaultProps({
  maxValueError: '',
  minValueError: ''
}), withProps(function (_ref) {
  var maxValue = _ref.maxValue,
      minValue = _ref.minValue,
      maxValueError = _ref.maxValueError,
      minValueError = _ref.minValueError,
      theme = _ref.theme,
      value = _ref.value;
  var parsedValue = parseFloat(value) || 0;
  var valueError;
  var valueErrorColor;

  if (maxValue && parsedValue > maxValue) {
    valueError = maxValueError;
    valueErrorColor = theme.colors.red;
  } else if (minValue && parsedValue < minValue) {
    valueError = minValueError;
    valueErrorColor = theme.colors.stoneLight;
  }

  if (parsedValue === 0) {
    valueErrorColor = theme.colors.stoneLight;
  }

  return {
    valueError: valueError,
    valueErrorColor: valueErrorColor
  };
}));
export { withErrorProps };