import { Type, write } from '@acorns/web-utils'
import qs from 'qs'
import { compose, equals, pathOr } from 'ramda'
import { string } from 'yup'

export const isTimeoutError = compose(
  equals('query_timeout'),
  pathOr(null, ['0', 'code']),
)

export const isValidEmail = (str: string = '') => {
  return string().email().isValidSync(str)
}

export const timeoutErrorDetails = {
  body: 'Sorry, we are having issues connecting to our servers. Please try again later.',
  title: 'Oops!',
}

export const isWhitelisted = (referrer: string) => {
  const redirectWhitelist = Object.freeze(
    process.env.REDIRECT_WHITELIST
      ? process.env.REDIRECT_WHITELIST.split(',')
      : [],
  )

  const redirectWhitelistRegex = Object.freeze(
    process.env.REDIRECT_WHITELIST_REGEX
      ? process.env.REDIRECT_WHITELIST_REGEX.split(',').map(
          (x) => new RegExp(x),
        )
      : [],
  )

  return (
    redirectWhitelist.some((whitelistedRedirectUrl) =>
      referrer.startsWith(whitelistedRedirectUrl),
    ) ||
    redirectWhitelistRegex.some((whitelistedRedirectUrlRegex) =>
      whitelistedRedirectUrlRegex.test(referrer),
    )
  )
}

type RegistrationUrlArgs = {
  registrationUrl: string
  windowLocation: Location
}

export const registrationUrlRedirect = (
  config: RegistrationUrlArgs,
): string => {
  const parseQueryString = (queryString) => qs.parse(queryString.split('?')[1])

  const queryParams = parseQueryString(config.windowLocation.search)

  let redirectRegistrationUrl: string

  if (queryParams.partner && queryParams.partner.toLowerCase() === 'groupon') {
    redirectRegistrationUrl = `${
      config.registrationUrl + window.location.search
    }&s1=${queryParams.partner}`
  } else if (config.windowLocation.pathname === '/redeem') {
    redirectRegistrationUrl = `${config.registrationUrl}?gift_card=true`
  } else {
    redirectRegistrationUrl = `${config.registrationUrl}${config.windowLocation.search}`
  }

  return redirectRegistrationUrl
}
