export var LearnTipLabelViewed = function LearnTipLabelViewed(id, screen) {
  return {
    event: 'container_viewed',
    object_name: 'learnTipTextLabel',
    screen: screen,
    id: id
  };
};
export var LearnTipTapped = function LearnTipTapped(id, screen) {
  return {
    event: 'button_tapped',
    object_name: 'learnTipTextLabelCta',
    screen: screen,
    id: id
  };
};
export var LearnTipViewed = function LearnTipViewed(id, screen) {
  return {
    event: 'container_viewed',
    object_name: 'learnTipCutoutModal',
    screen: screen,
    id: id
  };
};
export var LearnTipExited = function LearnTipExited(id, screen) {
  return {
    event: 'button_tapped',
    object_name: 'learnTipCutoutModalCloseCta',
    screen: screen,
    id: id
  };
};