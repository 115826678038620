import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0.6;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100vh;\n  height: 100vh;\n  z-index: 100;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  bottom: 30px;\n  color: ", ";\n  font-size: 14px;\n  padding: 14px 16px;\n  position: absolute;\n  pointer-events: none;\n\n  ", ";\n\n  &:focus {\n    outline: none;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: 14px;\n  min-height: 60px;\n  justify-content: space-between;\n  padding: 0 20px;\n  transition: opacity 200ms;\n  ", "\n\n  &:focus {\n    outline: 0;\n  }\n\n  &:hover {\n    background-color: #fafafa;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 0;\n  list-style: none;\n  background-color: #fff;\n  border-radius: ", ";\n  box-shadow: ", ";\n  left: 0;\n  margin: 10px 0 0;\n  max-height: 356px;\n  overflow: auto;\n  outline: none;\n  position: absolute;\n  right: 0;\n  top: ", ";\n  z-index: 101;\n\n  ", " li:not(:last-child) {\n    border-bottom: 1px solid ", ";\n  }\n\n  ", ";\n\n  @media screen and (max-width: ", "px) {\n    bottom: 20px;\n    left: 20px;\n    position: fixed;\n    right: 20px;\n    top: auto;\n    width: auto;\n    max-height: 60vh;\n    box-shadow: ", ";\n\n    &::before {\n      display: none;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: url(\"", "\") no-repeat center right 16px;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  box-sizing: border-box;\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  height: 44px;\n  padding: 0 16px;\n  width: 100%;\n\n  &:disabled {\n    cursor: default;\n  }\n\n  &:focus {\n    outline: 0;\n    border-color: #9a9fa1;\n  }\n\n  @media screen and (max-width: ", "px) {\n    font-size: 16px;\n  }\n\n  ", "\n\n  ", "\n\n  -webkit-appearance: none;\n  -webkit-border-radius: 10px;\n\n  -moz-appearance: none;\n  text-indent: 0.01px;\n  text-overflow: '';\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  color: ", ";\n  margin-bottom: 8px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  user-select: none;\n  transition: opacity 150ms ease-in-out;\n\n  /* Note: This gives us room to render an error label below the text field */\n  padding-bottom: 30px;\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/* stylelint-disable property-no-vendor-prefix */
import styled from '../../utils/styling/styled-components';
import Chevron from '../chevron.svg';
export var Container = styled.div(_templateObject(), function (props) {
  return props['data-disabled'] && "\n    cursor: default;\n    opacity: 0.3;\n  ";
});
export var Label = styled.label(_templateObject2(), function (props) {
  return props.theme.colors.slate;
});
export var Select = styled.select(_templateObject3(), Chevron, function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return props.theme.colors.stoneLight;
}, function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.theme.breakpoints.sm;
}, function (props) {
  return props['data-error'] && "\n    border-color: ".concat(props.theme.colors.red, ";\n    color: ").concat(props.theme.colors.red, ";\n  ");
}, function (props) {
  return props.placeholderVisible && "\n    color: ".concat(props.theme.colors.stoneLight, ";\n  ");
});
export var Options = styled.div(_templateObject4(), function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return props.theme.shadows.lgMedium;
}, function (props) {
  return props.label ? '12px' : '-12px';
}, function (props) {
  return props.position === 'top' && "\n    bottom: 0;\n    margin-bottom: 10px;\n    top: auto;\n  ";
}, function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.offsetTop && "\n    top: ".concat(props.offsetTop, "px;\n  ");
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.theme.shadows.lgDark;
});
export var Option = styled.li(_templateObject5(), function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.keyboardSelected ? 'background-color: #fafafa;' : '';
});
export var Value = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props['data-error'] && "\n    color: ".concat(props.theme.colors.red, ";\n  ");
});
export var Overlay = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.slate;
});
export var Placeholder = styled(Value)(_templateObject8(), function (props) {
  return props.theme.colors.stoneLight;
});