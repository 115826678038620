import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1.5em;\n  text-align: center;\n  overflow: auto;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 1.5em;\n  margin-bottom: 8px;\n  text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var ModalTitle = styled.div(_templateObject(), function (props) {
  return props.theme.colors.slate;
});
var ModalBody = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.slate;
});
export { ModalTitle, ModalBody };