import { compose, withProps } from 'recompose'

export default compose<any, {}>(
  withProps(({ analytics }) => {
    const { sessionId = '' } = analytics.analytics
    analytics.identify(sessionId, {
      userId: sessionId,
    })
  }),
)
