import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: #fff;\n  font-size: 12px;\n  font-style: italic;\n  font-weight: 500;\n  line-height: 16px;\n  margin-top: 2px;\n  opacity: 0.6;\n  text-align: center;\n  white-space: nowrap;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
import { HERO_FOOTNOTE } from '../utils/testing/TestIDs';
var HeroFootnote = styled.div.attrs({
  'data-testid': HERO_FOOTNOTE
})(_templateObject());
export { HeroFootnote };