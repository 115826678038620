import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _contentAlignmentReso;

import { Alignment } from './types';
export var contentAlignmentResolvers = (_contentAlignmentReso = {}, _defineProperty(_contentAlignmentReso, Alignment.BottomCenter, function (params) {
  return "\n    top: calc(100% + ".concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.BottomLeft, function (params) {
  return "\n    top: calc(100% + ".concat(params.gutter, "px);\n    left: ").concat(params.offset, "px;\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.BottomRight, function (params) {
  return "\n    top: calc(100% + ".concat(params.gutter, "px);\n    right: ").concat(params.offset, "px;\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.LeftBottom, function (params) {
  return "\n    bottom: ".concat(params.offset, "px;\n    right: calc(100% + ").concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.LeftCenter, function (params) {
  return "\n    right: calc(100% + ".concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.LeftTop, function (params) {
  return "\n    right: calc(100% + ".concat(params.gutter, "px);\n    top: ").concat(params.offset, "px;\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.RightBottom, function (params) {
  return "\n    bottom: ".concat(params.offset, "px;\n    left: calc(100% + ").concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.RightCenter, function (params) {
  return "\n    left: calc(100% + ".concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.RightTop, function (params) {
  return "\n    top: ".concat(params.offset, "px;\n    left: calc(100% + ").concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.TopCenter, function (params) {
  return "\n    bottom: calc(100% + ".concat(params.gutter, "px);\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.TopLeft, function (params) {
  return "\n    bottom: calc(100% + ".concat(params.gutter, "px);\n    left: ").concat(params.offset, "px;\n  ");
}), _defineProperty(_contentAlignmentReso, Alignment.TopRight, function (params) {
  return "\n    bottom: calc(100% + ".concat(params.gutter, "px);\n    right: ").concat(params.offset, "px;\n  ");
}), _contentAlignmentReso); // Will return true if positioned above or below anchor

export var isAlignmentVertical = function isAlignmentVertical(alignment) {
  return alignment >= Alignment.TopCenter && alignment <= Alignment.BottomRight;
};