import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _containerAlignmentRe, _arrowAlignmentResolv;

import { Alignment } from '../Position';
export var containerAlignmentResolvers = (_containerAlignmentRe = {}, _defineProperty(_containerAlignmentRe, Alignment.BottomCenter, function () {
  return "\n    align-items: flex-start;\n    justify-content: center;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.BottomLeft, function () {
  return "\n    align-items: flex-start;\n    justify-content: flex-start;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.BottomRight, function () {
  return "\n    align-items: flex-start;\n    justify-content: flex-end;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.LeftBottom, function () {
  return "\n    align-items: flex-end;\n    justify-content: flex-end;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.LeftCenter, function () {
  return "\n    align-items: center;\n    justify-content: flex-end;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.LeftTop, function () {
  return "\n    align-items: flex-start;\n    justify-content: flex-end;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.RightBottom, function () {
  return "\n    align-items: flex-end;\n    justify-content: flex-start;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.RightCenter, function () {
  return "\n    align-items: center;\n    justify-content: flex-start;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.RightTop, function () {
  return "\n    align-items: flex-start;\n    justify-content: flex-start;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.TopCenter, function () {
  return "\n    align-items: flex-end;\n    justify-content: center;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.TopLeft, function () {
  return "\n    align-items: flex-end;\n    justify-content: flex-start;\n  ";
}), _defineProperty(_containerAlignmentRe, Alignment.TopRight, function () {
  return "\n    align-items: flex-end;\n    justify-content: flex-end;\n  ";
}), _containerAlignmentRe);
export var arrowAlignmentResolvers = (_arrowAlignmentResolv = {}, _defineProperty(_arrowAlignmentResolv, Alignment.BottomCenter, function (props) {
  return "\n    border-bottom-color: ".concat(props.arrowColor, ";\n    bottom: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.BottomLeft, function (props) {
  return "\n    border-bottom-color: ".concat(props.arrowColor, ";\n    bottom: 100%;\n    margin-left: ").concat(props.arrowOffset, "px;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.BottomRight, function (props) {
  return "\n    border-bottom-color: ".concat(props.arrowColor, ";\n    bottom: 100%;\n    margin-right: ").concat(props.arrowOffset, "px;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.LeftBottom, function (props) {
  return "\n    border-left-color: ".concat(props.arrowColor, ";\n    left: 100%;\n    margin-bottom: ").concat(props.arrowOffset, "px;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.LeftCenter, function (props) {
  return "\n    border-left-color: ".concat(props.arrowColor, ";\n    left: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.LeftTop, function (props) {
  return "\n    border-left-color: ".concat(props.arrowColor, ";\n    left: 100%;\n    margin-top: ").concat(props.arrowOffset, "px;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.RightBottom, function (props) {
  return "\n    border-right-color: ".concat(props.arrowColor, ";\n    margin-bottom: ").concat(props.arrowOffset, "px;\n    right: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.RightCenter, function (props) {
  return "\n    border-right-color: ".concat(props.arrowColor, ";\n    right: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.RightTop, function (props) {
  return "\n    border-right-color: ".concat(props.arrowColor, ";\n    margin-top: ").concat(props.arrowOffset, "px;\n    right: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.TopCenter, function (props) {
  return "\n    border-top-color: ".concat(props.arrowColor, ";\n    top: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.TopLeft, function (props) {
  return "\n    border-top-color: ".concat(props.arrowColor, ";\n    margin-left: ").concat(props.arrowOffset, "px;\n    top: 100%;\n  ");
}), _defineProperty(_arrowAlignmentResolv, Alignment.TopRight, function (props) {
  return "\n    border-top-color: ".concat(props.arrowColor, ";\n    margin-right: ").concat(props.arrowOffset, "px;\n    top: 100%;\n  ");
}), _arrowAlignmentResolv);