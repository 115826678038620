/**
 * Each enum member represents a different page within the login
 * flow. For example, "login" represents the email/pass page while
 * "challenge" represents the 2 factor auth page. Changing this will
 * navigate users to the corresponding page.
 */
var LOGIN_FLOW_STATES;

(function (LOGIN_FLOW_STATES) {
  LOGIN_FLOW_STATES[LOGIN_FLOW_STATES["login"] = 0] = "login";
  LOGIN_FLOW_STATES[LOGIN_FLOW_STATES["challenge"] = 1] = "challenge";
  LOGIN_FLOW_STATES[LOGIN_FLOW_STATES["error"] = 2] = "error";
  LOGIN_FLOW_STATES[LOGIN_FLOW_STATES["closed"] = 3] = "closed";
})(LOGIN_FLOW_STATES || (LOGIN_FLOW_STATES = {}));

export { LOGIN_FLOW_STATES };