import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: calc(100% / ", ");\n  height: 100%;\n  z-index: 0;\n  border-radius: 100px;\n  transition: all 250ms ease-in-out;\n  background-color: ", ";\n  transform: translateX(", "%);\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: ", ";\n  z-index: 2;\n  transform: translate3d(0, 0, 0);\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  cursor: pointer;\n  border: none;\n  background: none;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 300px;\n  margin-top: 28px;\n  height: 32px;\n  border-radius: 99px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: 100px;\n  width: 300px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n  @media screen and (max-width: ", ";px) {\n    height: 193px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  padding: 0px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: flex-start;\n  padding: 10px 0px 0px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../../utils/styling/styled-components';
export var CUSTOM_TABLET_WIDTH = 900;
export var CUSTOM_SMALL_WIDTH = 700;
export var Container = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.md;
});
export var GraphContainer = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.md;
});
export var ChartContainer = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.theme.breakpoints.sm;
}); //-----------Time Frame Press -----------

export var TimeFrameContainer = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.md;
});
export var TimeFrameLabel = styled.button(_templateObject5(), function (props) {
  return props.theme.colors.stone;
}, function (props) {
  return props.theme.breakpoints.md;
});
export var TimeFrameMarker = styled.div(_templateObject6(), function (props) {
  return props.widthDivisor;
}, function (props) {
  return props.theme.colors.ivoryLight;
}, function (props) {
  return 100 * props.offset;
});