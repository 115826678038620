import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: ", "px;\n  margin: 0 auto;\n  padding: 40px 60px 0;\n\n  @media screen and (max-width: ", "px) {\n    padding: 40px 30px 0;\n  }\n\n  @media screen and (max-width: ", "px) {\n    padding: 20px 20px 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var Content = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.lg;
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.theme.breakpoints.sm;
});
export { Content };