import { AcornsIcon } from '@acorns/icons'
import { BackButton, Button, Heading } from '@acorns/web-components'
import styled from 'styled-components'

import { theme } from 'theme'

const BackButtonContainer = styled.div`
  left: 0;
  position: absolute;
  top: -65px;
  width: 100%;
`
const BackButtonStyled = styled(BackButton)`
  margin-right: 16px;
  @media screen and (max-width: ${theme('breakpoints.sm')}px) {
    left: 0;
    position: absolute;
  }
`

const HeaderSubcopy = styled.div`
  color: ${theme('colors.slate')};
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  max-width: 340px;
  text-align: center;
  margin-bottom: 10px;
`

const ResendButton = styled.a`
  cursor: pointer;
  color: ${theme('colors.green')};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`

const HeaderSubcopyBolded = styled.span`
  color: ${theme('colors.slate')};
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  padding: 10px 0 0px 0;
`

const SubmitButton = styled(Button)`
  margin: auto;
  max-width: 340px;
  width: 100%;
`

const SwitchModeButton = styled.a`
  margin: auto;
  color: ${theme('colors.slate')};
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const SwitchModeContainer = styled.div`
  margin-top: 20px;
`

const SwitchModeIcon = styled(AcornsIcon)`
  margin-right: 17px;
`

const NeedHelpButton = styled(Button)`
  text-align: center;
  margin: 20px auto auto auto;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 335px;
`

const TrustThisDeviceContainer = styled.div`
  margin-top: 19px;
  margin-right: 135px;
`

export {
  BackButtonContainer,
  BackButtonStyled,
  HeaderSubcopyBolded,
  NeedHelpButton,
  SubmitButton,
  HeaderSubcopy,
  BottomContainer,
  TrustThisDeviceContainer,
  ResendButton,
  SwitchModeButton,
  SwitchModeIcon,
  SwitchModeContainer,
}
