import sha256 from 'crypto-js/sha256';

/**
 *
 * @param imagePath The relative path from `deploy/v1`...  Ex: `/home/tourtips/invest`
 * @param fileType The file extension as a string...        Ex: `png` or `svg`
 * @param imageSize An optional parameter for the image size in the format `@<#>x`... Ex: `@3x`
 * @returns An ImageAsset object with a `url` key and the full image URL as its value.
 */
var getImageAsset = function getImageAsset(imagePath, fileType) {
  var imageSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var BASE_URL = 'https://dwhmnen8pl3zw.cloudfront.net/v1/web';
  var CLIENT_KEY = '1de6925c7fa68eb6c22728a94834b9fbcc356ad8e53681a648c5aa377bf7188b';
  var hash = sha256("".concat(CLIENT_KEY).concat(imagePath)).toString();
  var imageUrl = "".concat(BASE_URL).concat(imagePath, "/").concat(hash).concat(imageSize, ".").concat(fileType);
  var imageAsset = {
    url: imageUrl
  };
  return imageAsset;
};

export { getImageAsset };