import { getDaysInMonth as getDays } from 'date-fns';
import { range } from 'ramda';

function getOptionRange(start, end) {
  return range(start, end + 1).map(function (num) {
    return {
      label: num.toString(),
      value: num.toString()
    };
  });
}

function getDaysInMonth(month, year) {
  return getDays(new Date(year, month - 1));
}

function getFirstValidValue(obj) {
  for (var key in obj) {
    if (!!obj[key]) return key;
  }

  return null;
}

export { getOptionRange, getDaysInMonth, getFirstValidValue };