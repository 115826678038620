import { useEffect } from 'react'

import { Type, destroy, read } from '@acorns/web-utils'

import { useLogoutMutation } from 'generated/types'

// This component is intended to close any existing session that may be open when a customer visits login-app.
export function CloseExistingSession() {
  const [logoutMutation] = useLogoutMutation()

  useEffect(() => {
    const logout = async () => {
      // Avoid calling the `logout` mutation unnecessarily; call it only when we know we have auth tokens to expire.
      if (read(Type.csrfToken)) {
        await logoutMutation()
      }

      destroy(Type.token)
      destroy(Type.partition)
      destroy(Type.suspendedToken)

      // The HTTP response for the `logout` mutation is expected to expire all the auth-related cookies, including the CSRF token cookie.
      // Destroying the CSRF token here is "just in case" if for whatever reason it doesn't, which effectively terminates the session too.
      destroy(Type.csrfToken)

      // Destroying the refresh token cookie is only relevant when developing locally.
      destroy(Type.refreshToken)
    }

    logout()
  }, [logoutMutation])

  return null
}
