import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _typeof from "@babel/runtime/helpers/typeof";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { path } from 'ramda';
export var resolveName = function resolveName(name, props) {
  switch (_typeof(name)) {
    case 'function':
      return name(props);

    case 'object':
      return path(['definitions', 0, 'name', 'value'], name) || '';

    default:
      return name;
  }
};
export var mergeDefaults = function mergeDefaults(defaultDetails) {
  var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var normalizedDetails = _objectSpread({}, details);

  if (details.body) {
    normalizedDetails.message = details.body;
  }

  if (details.header) {
    normalizedDetails.title = details.header;
  }

  return _objectSpread(_objectSpread({}, defaultDetails), normalizedDetails);
};
export var customHandlerReducer = function customHandlerReducer(state, action) {
  if (action.type === 'add' && action.handler) {
    state.set(action.name, action.handler);
  } else if (action.type === 'remove') {
    state["delete"](action.name);
  }

  return state;
};