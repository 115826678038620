import Raven from 'raven-js'

export default (url, environment, release) => {
  if (environment !== 'dev' && window.location.hostname !== 'localhost') {
    Raven.config(url, {
      environment,
      release,
      tags: {
        majorVersion: '3',
      },
    }).install()
  }
}
