import { UserScopedLocalStorage } from '../browser-storage';
import { DEFAULT_USER_ID } from '../providers/userId/UserIdProvider';
import { getWindow } from '../getWindow';
import { useUserId } from './useUserId';
export function useUserScopedLocalStorage() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useUserId = useUserId(),
      userId = _useUserId.userId;

  var userScopedLocalStorage = userId === DEFAULT_USER_ID ? null : new UserScopedLocalStorage(userId);
  var window = getWindow();

  if (options.addStorageToGlobalScope && userScopedLocalStorage) {
    window.acornsweb.userScopedLocalStorage = userScopedLocalStorage;
  } else {
    delete window.acornsweb.userScopedLocalStorage;
  }

  return {
    userScopedLocalStorage: userScopedLocalStorage
  };
}