import React from 'react'
import { useQuery } from 'react-apollo'
import { useLocation } from 'react-router-dom'

import { theme } from '@acorns/web-components'

import { InvalidResetToken } from './invalidResetToken'
import validatePasswordResetTokenSuccess from './queries/validate-password-reset-token-query.gql'
import { ResetPasswordForm } from './resetPasswordForm'
import { StyledSpinner } from './styled-components'

export const ResetPasswordPage = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const resetPasswordToken = params.get('reset_password_token')

  const { data, loading } = useQuery(validatePasswordResetTokenSuccess, {
    fetchPolicy: 'no-cache',
    variables: {
      resetToken: resetPasswordToken,
    },
  })

  if (loading) {
    return <StyledSpinner color={theme.colors.greenLight} />
  }

  if (
    data &&
    data.validatePasswordResetToken.__typename ===
      'ValidatePasswordResetTokenSuccess'
  ) {
    return (
      <ResetPasswordForm
        showSsnField={data.validatePasswordResetToken.displaySsnField}
        resetToken={resetPasswordToken}
      />
    )
  }

  return <InvalidResetToken />
}
