import { Type, setCookies, write } from '@acorns/web-utils'
import { ExecutionResult } from 'graphql'
import { object, string } from 'yup'

import { AuthType, AuthenticateMutation } from 'generated/types'
import { routes } from 'src/routes'

import { isValidEmail, isWhitelisted } from '../../utils/login-utils'
import {
  MfaScreenViewed,
  loadingTimeLogIn,
  loadingTimeLogInSuccess,
  logInNext,
} from '../../utils/segment'
import { getUdidForAuthentication } from '../../utils/udid-utils'

export type Values = {
  email: string
  password: string
}

// at this point, we are just validating that user has entered text into both fields, not that it is an email format
export const validationSchema = object().shape({
  email: string().required(),
  password: string().required(),
})

export const emailToPrefill = document.cookie
  .split(';')
  .filter((cookie) => cookie.includes('emailToPrefill'))
  .toString()
  .split('=')
  .pop()

// Used by Formik to set initial form values
export const mapPropsToValues = (props) => {
  const { email = '', password = '' } = props

  return {
    email: emailToPrefill ? emailToPrefill : email,
    password,
  }
}

export const resolveRedirect = (
  defaultRedirect: string,
  referrer?: string,
): string => {
  let redirect = defaultRedirect
  if (
    referrer &&
    !referrer.includes(window.location.host) &&
    isWhitelisted(referrer)
  ) {
    redirect = referrer
  } else {
    const { pathname, search } = window.location

    redirect = `${redirect}${pathname}`

    if (search) {
      redirect = `${redirect}${search}`
    }
  }

  return redirect
}

export const handleFormSubmit = async (
  values,
  { props, setSubmitting }: any,
) => {
  const preservedRedirect = props.location.pathname + props.location.search
  const preservedQueryParams = props.location.search
  const { chromeExtensionPartnerFlow, oAuthPartnerParams } = props

  values.email = values.email.trim()

  if (!isValidEmail(values.email)) {
    setSubmitting(false)
    props.setIsValidationModalOpen(true)
    return
  }
  props.analytics.track('Button Tapped', logInNext)
  props.analytics.track(
    'Time Analysis',
    loadingTimeLogIn(Math.floor((Date.now() - props.timeOnLogInView) / 1000)),
  )
  setSubmitting(true)
  const trackLoadingTimeLogInSuccess = () =>
    props.analytics.track(
      'Time Analysis',
      loadingTimeLogInSuccess(
        Math.floor((Date.now() - props.timeOnLogInView) / 1000),
      ),
    )

  values.udid = getUdidForAuthentication()

  if (!props.trustThisDeviceWebIsEnabled || props.isOAuth) {
    // Append an arbitrary string to the udid when "trust this device" is disabled as a
    // small hack to always force MFA, even if the device/udid was previously trusted.
    values.udid += '__remember_me_disabled'
  }

  return props
    .login({
      variables: {
        input: {
          ...values,
        },
        oauth: props.isOAuth ?? false,
      },
    })
    .then((result: ExecutionResult<AuthenticateMutation>) => {
      const { authenticate } = result.data

      switch (authenticate.__typename) {
        case 'SMSAuthChallenge':
          props.analytics.track(
            'Screen Viewed',
            MfaScreenViewed(props.location.pathname),
          )
          props.history.push({
            pathname: props.isOAuth ? '/oauth/mfa-challenge' : '/mfa-challenge',
            data: {
              values,
              challengeId: authenticate.id,
              phoneNumber: '(•••) ••• ' + authenticate.maskedPhoneNumber,
              mode: AuthType.Phone,
              alternateAuthenticatorId:
                authenticate.alternateAuthenticators.find(
                  (authenticator) => authenticator?.type !== AuthType.Phone,
                )?.id,
              preservedRedirect,
              preservedQueryParams,
              chromeExtensionPartnerFlow,
              oAuthPartnerParams,
              isMfaExemptOAuthUser: false,
            },
          })
          trackLoadingTimeLogInSuccess()

          break

        case 'EmailAuthChallenge':
          props.analytics.track(
            'Screen Viewed',
            MfaScreenViewed(props.location.pathname),
          )
          props.history.push({
            pathname: props.isOAuth ? '/oauth/mfa-challenge' : '/mfa-challenge',
            data: {
              values,
              challengeId: authenticate.id,
              email: authenticate.maskedEmail,
              mode: AuthType.Email,
              alternateAuthenticatorId:
                authenticate.alternateAuthenticators.find(
                  (authenticator) => authenticator.type !== AuthType.Email,
                )?.id,
              preservedRedirect,
              preservedQueryParams,
              chromeExtensionPartnerFlow,
              oAuthPartnerParams,
              isMfaExemptOAuthUser: false,
            },
          })
          trackLoadingTimeLogInSuccess()

          break

        case 'AuthSession':
          if (!props.isOAuth) {
            setCookies(authenticate)

            window.location.href = resolveRedirect(
              props.env.get('defaultRedirect'),
              document.referrer,
            )
          } else {
            const twoMinuteFromNow = new Date(
              new Date().getTime() + 2 * 60 * 1000,
            )
            write(Type.identityId, authenticate.identityId, {
              expires: twoMinuteFromNow,
            })

            props.history.push({
              pathname: routes.oAuth.authorize,
              data: {
                preservedRedirect,
                preservedQueryParams,
                chromeExtensionPartnerFlow,
                oAuthPartnerParams,
                isMfaExemptOAuthUser: true,
              },
            })
          }

          trackLoadingTimeLogInSuccess()

          break

          {
            /* All suspended users should also be challenged with MFA, so they shouldn't fall into this case.
             But this will be kept in case of a rollback, to support both flows. */
          }
        case 'UserSuspendedException':
          if (!authenticate.suspendedToken) {
            props.setException('NoSuspendedTokenException')
          } else {
            props.setException(authenticate.__typename)

            write(Type.suspendedToken, authenticate.suspendedToken)
          }

          props.setIsModalOpen(true)

          trackLoadingTimeLogInSuccess()

          break

        default:
          props.setException(authenticate.__typename)
          props.setIsModalOpen(true)
      }
    })
    .finally(() => setSubmitting(false))
}
