import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

export const FormContainer = styled.div`
  padding-bottom: 80px;
`
