import React from 'react'

import { Title } from '@acorns/web-components'
import styled from 'styled-components'

import AcornsLogoSvgPath from 'assets/icons/acorns-logo.svg'
import ChromeLogoSvgPath from 'assets/icons/chrome-logo.svg'
import ConnectorSvgPath from 'assets/icons/connector.svg'
import GreenCheckmarkSvgPath from 'assets/icons/green-checkmark.svg'
import {
  AGGREGATOR_CONTENT_LOGO,
  OAUTH_BROWSER_EXTENSION_TITLE,
  OAUTH_CHROME_EXTENSION_TITLE,
  OAUTH_PARTNER_TITLE,
  PARTNER_CONTENT_LOGO,
} from 'src/utils/testing/testIds'
import { getDecodedBase64UrlStrings } from 'utils/decode-base64'

const ACORNS_EARN_CHROME_EXTENSION_CLIENT_ID =
  '018caeb2cb9e36cbf4937ea2770553a189ff1411c365953b8578519e84c0c0d1'
const BROWSER_EXTENSION_PAGE_TYPE = 'browser_extension'
const AGGREGATOR_PAGE_TYPE = 'aggregator'
const PARTNER_PAGE_TYPE = 'partner'

export const KNOWN_OAUTH_PARTNERS = [
  ACORNS_EARN_CHROME_EXTENSION_CLIENT_ID,
  BROWSER_EXTENSION_PAGE_TYPE,
  AGGREGATOR_PAGE_TYPE,
  PARTNER_PAGE_TYPE,
]

const PartnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin-top: 30px;
`

const GoogleChromeLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  img:first-child {
    margin-right: 50px;
  }
`

const PartnerAggregatorLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

const PartnerContentLogo = styled.img`
  height: 64px;
`

const PartnerContentTitle = styled(Title)`
  font-weight: 600;
  font-size: 32px;
  line-height: 37.5px;
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
  align-self: center;
  width: 280px;
`

const PartnerContentUnorderedList = styled.ul`
  list-style-type: none;
  list-style-position: outside;
  padding-left: 20px;
  margin-bottom: 15px;
`

const PartnerContentListItem = styled.li`
  background-image: url(${GreenCheckmarkSvgPath});
  background-repeat: no-repeat;
  background-position: left top 6px;
  padding-left: 25px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 10px;
`

const PartnerContentDescription = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 40px;
  margin-top: 5px;
`

const Connector = styled.img`
  padding: 10px;
`

function OAuthPartnerContent({
  oAuthPartnerParams,
  chromeExtensionPartnerFlow,
}) {
  const oAuthClient = chromeExtensionPartnerFlow
    ? oAuthPartnerParams.client_id
    : oAuthPartnerParams.page_type

  switch (oAuthClient) {
    case ACORNS_EARN_CHROME_EXTENSION_CLIENT_ID:
      return (
        <PartnerContentContainer>
          <GoogleChromeLogoContainer>
            <PartnerContentLogo src={AcornsLogoSvgPath} alt="Acorns logo" />
            <PartnerContentLogo
              src={ChromeLogoSvgPath}
              alt="Google Chrome logo"
              height={'64px'}
            />
          </GoogleChromeLogoContainer>
          <PartnerContentTitle data-testid={OAUTH_CHROME_EXTENSION_TITLE}>
            Your Acorns Earn
            <br />
            Chrome Extension
            <br />
            is ready to go
          </PartnerContentTitle>
          <PartnerContentUnorderedList>
            <PartnerContentListItem>
              Customers have earned over $35,000,000 through Acorns Earn.
            </PartnerContentListItem>
            <PartnerContentListItem>
              Earn from 15,000+ top brands.
            </PartnerContentListItem>
            <PartnerContentListItem>
              Start earning while you shop, now!
            </PartnerContentListItem>
          </PartnerContentUnorderedList>
        </PartnerContentContainer>
      )
    case BROWSER_EXTENSION_PAGE_TYPE:
      return (
        <PartnerContentContainer>
          <GoogleChromeLogoContainer>
            <PartnerContentLogo src={AcornsLogoSvgPath} alt="Acorns logo" />
            <PartnerContentLogo
              src={getDecodedBase64UrlStrings(oAuthPartnerParams.app_image_uri)}
              alt="Browser Extension logo"
              height={'64px'}
            />
          </GoogleChromeLogoContainer>
          <PartnerContentTitle data-testid={OAUTH_BROWSER_EXTENSION_TITLE}>
            Your {oAuthPartnerParams.app_name} is ready to go
          </PartnerContentTitle>
          <PartnerContentUnorderedList>
            <PartnerContentListItem>
              Customers have earned over $35,000,000 through Acorns Earn.
            </PartnerContentListItem>
            <PartnerContentListItem>
              Earn from 15,000+ top brands.
            </PartnerContentListItem>
            <PartnerContentListItem>
              Start earning while you shop, now!
            </PartnerContentListItem>
          </PartnerContentUnorderedList>
        </PartnerContentContainer>
      )
    case PARTNER_PAGE_TYPE:
      return (
        <PartnerContentContainer>
          <PartnerAggregatorLogoContainer>
            <PartnerContentLogo
              src={getDecodedBase64UrlStrings(oAuthPartnerParams.app_image_uri)}
              data-testid={PARTNER_CONTENT_LOGO}
              alt="Partner logo"
              height={'64px'}
            />
            <Connector src={ConnectorSvgPath} alt="connector" />
            <PartnerContentLogo src={AcornsLogoSvgPath} alt="Acorns logo" />
          </PartnerAggregatorLogoContainer>
          <PartnerContentTitle data-testid={OAUTH_PARTNER_TITLE}>
            Log in
          </PartnerContentTitle>

          <PartnerContentDescription>
            Your login and password will be used by{' '}
            {oAuthPartnerParams.app_name} to connect to your Acorns account.
          </PartnerContentDescription>
        </PartnerContentContainer>
      )
    case AGGREGATOR_PAGE_TYPE:
      return (
        <PartnerContentContainer>
          <PartnerAggregatorLogoContainer>
            <PartnerContentLogo
              src={getDecodedBase64UrlStrings(oAuthPartnerParams.app_image_uri)}
              data-testid={AGGREGATOR_CONTENT_LOGO}
              alt="Aggregator logo"
              height={'64px'}
            />
            <Connector src={ConnectorSvgPath} alt="connector" />
            <PartnerContentLogo src={AcornsLogoSvgPath} alt="Acorns logo" />
          </PartnerAggregatorLogoContainer>
          <PartnerContentTitle data-testid={OAUTH_PARTNER_TITLE}>
            Log in
          </PartnerContentTitle>

          <PartnerContentDescription>
            Your login and password will be used by{' '}
            {oAuthPartnerParams.app_name} to connect your Acorns account with{' '}
            {oAuthPartnerParams.app_client_name}.
          </PartnerContentDescription>
        </PartnerContentContainer>
      )
    default:
      return null
  }
}

export default OAuthPartnerContent
