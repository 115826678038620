import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  user-select: none;\n  transition: opacity 150ms ease-in-out, transform 150ms linear;\n  &:active,\n  &:focus {\n    opacity: 0.5;\n  }\n  &:hover {\n    opacity: 0.75;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var TouchableOpacity = styled.div(_templateObject());
export { TouchableOpacity };