// Copy pasta from the React god
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react';

var useTimeout = function useTimeout(callback, delay) {
  var savedCallback = useRef();
  useEffect(function () {
    savedCallback.current = callback;
  });
  useEffect(function () {
    function tick() {
      var _savedCallback$curren;

      (_savedCallback$curren = savedCallback.current) === null || _savedCallback$curren === void 0 ? void 0 : _savedCallback$curren.call(savedCallback);
    }

    var id = setTimeout(tick, delay);
    return function () {
      return clearTimeout(id);
    };
  }, [delay]);
};

var useInterval = function useInterval(callback, delay) {
  var savedCallback = useRef();
  useEffect(function () {
    savedCallback.current = callback;
  });
  useEffect(function () {
    function tick() {
      var _savedCallback$curren2;

      (_savedCallback$curren2 = savedCallback.current) === null || _savedCallback$curren2 === void 0 ? void 0 : _savedCallback$curren2.call(savedCallback);
    }

    var id = setInterval(tick, delay);
    return function () {
      return clearInterval(id);
    };
  }, [delay]);
};

export { useInterval, useTimeout };