import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 12px;\n  font-style: italic;\n  font-weight: 400;\n  margin: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var Caption = styled.p(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.stone;
});
export { Caption };