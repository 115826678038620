export var ACTION_BUTTON = 'action_button';
export var ACTION_STRIP = 'action_strip';
export var BACK_BUTTON = 'back_button';
export var BUTTON = 'button';
export var CHECKBOX_INPUT = 'checkbox_input';
export var CHECKBOX_LABEL = 'checkbox_label';
export var CHECKBOX_TOP_LABEL = 'checkbox_top_label';
export var CONFIRMATION_MODAL_SIMPLE_IMAGE = 'CONFIRMATION_MODAL_SIMPLE_IMAGE';
export var CONFIRMATION_MODAL_CUSTOM_IMAGE = 'CONFIRMATION_MODAL_CUSTOM_IMAGE';
export var CURRENCY_INPUT_DOLLARS = 'currency_input_dollar';
export var CURRENCY_INPUT_CENTS = 'currency_input_cents';
export var SCREEN_READER_ONLY = 'screen_reader_only';
export var FIELD_ERROR = 'field_error';
export var DOLLAR = 'dollar_input';
export var DROPDOWN_BUTTON = 'dropdown_button';
export var DROPDOWN_ITEM = 'dropdown_item';
export var DROPDOWN_LIST = 'dropdown_list';
export var ERROR_STATE = 'error_state';
export var HERO_CARD = 'hero_card';
export var HERO_TITLE = 'hero_title';
export var HERO_BODY = 'hero_body';
export var HERO_FOOTNOTE = 'hero_footnote';
export var MODAL = 'modal';
export var MODAL_BODY = 'modal_body';
export var DRAWER = 'drawer';
export var DRAWER_ACTION = 'drawer_action';
export var DRAWER_BANNER = 'drawer_banner';
export var NOTE = 'note';
export var OPTION_TOGGLE = 'option_toggle';
export var PILL = 'pill';
export var PROGRESS_LOADING_SPINNER = 'progress_loading_spinner';
export var SELECTION_LIST = 'selection_list';
export var SELECTION_LIST_ROW = 'selection_list_row';
export var SEARCH_TRIGGER = 'search_trigger';
export var SEARCH_TERM = 'search_term';
export var SEARCH_CLEAR = 'search_clear';
export var SEARCH_CTA = 'search_CTA';
export var PAGE = 'page';
export var PRIVATE_INPUT_TOGGLE = 'private_input_toggle';
export var PROGRESS_BAR = 'progress_bar';
export var PROGRESS_BAR_HELP_TEXT = 'progress_bar_help_text';
export var PROGRESS_BAR_MAX = 'progress_bar_max';
export var PROGRESS_BAR_MIN = 'progress_bar_min';
export var PROGRESS_BAR_TOOLTIP = 'progress_bar_tooltip';
export var RADIO_GROUP = 'radio_group';
export var RADIO_INPUT = 'radio_input';
export var RADIO_INPUT_LABEL = 'radio_input_label';
export var REACT_PLAYER_VIDEO = 'react_player_video';
export var RELOAD_BUTTON = 'reload_button';
export var SPINNER = 'spinner';
export var TEXT_AREA = 'text_area';
export var TEXT_AREA_LABEL = 'text_area_label';
export var TEXT_INPUT = 'text_input';
export var TEXT_INPUT_LOCKED_ICON = 'text_input_locked_icon';
export var TEXT_INPUT_ERROR = 'text_input_error';
export var TEXT_INPUT_LABEL = 'text_input_label';
export var TEXT_INPUT_HELP_TEXT = 'text_input_help_text';
export var THICC_MENU = 'thicc_menu';
export var THICC_MENU_ITEM = 'thicc_menu_item';
export var SELECT_INPUT = 'select_input';
export var SELECT_INPUT_CONTAINER = 'select_input_container';
export var SELECT_INPUT_OPTION = 'select_input_option';
export var SELECT_INPUT_ERROR = 'select_input_error';
export var SETTINGS_BUTTON = 'settings_button';
export var TAP_INPUT = 'tap_input';
export var STATIC_INPUT = 'static_input';
export var WEBVIEW_ACTION_STRIP = 'webview_action_strip';
export var WEBVIEW_ACTION_STRIP_CANCEL = 'webview_action_strip_cancel';
export var WEBVIEW_ACTION_STRIP_BACK = 'webview_action_strip_back';
export var SEARCH_BAR = 'search_bar';
export var SEARCH_BAR_INPUT = 'search_bar_input';
export var SEARCH_RESULT = 'search_result';
export var SEARCH_OVERLAY = 'search_overlay';
export var SEARCH_PAGE_LINK = 'search_page_link';
export var SEARCH_BAR_TOOLTIP = 'search_bar_tooltip';
export var SEARCH_NOT_FOUND_MESSAGE = 'search_not_found_message';
export var ALLOCATOR_MODAL_LEFT_BUTTON = 'allocator_modal_left_button';
export var ALLOCATOR_MODAL_RIGHT_BUTTON = 'allocator_modal_right_button';
export var ALLOCATOR_MODAL_INPUT = 'allocator_modal_input';
export var LEARN_TIP = 'learn_tip';
export var PERFORMANCE_CURRENT_PRICE = 'performance_current_price';
export var PERFORMANCE_PRICE_CHANGE = 'performance_price_change';
export var FUND_GRAPH_DATE = 'fund_graph_date_';
export var CAROUSEL_NEXT_BUTTON = 'carousel_next_button';
export var CAROUSEL_ARROW_BUTTON = 'carousel_arrow_button_';
export var ACTION_STRIP_TITLE = 'action_strip_title';
export var ACTION_STRIP_SUBTEXT = 'action_strip_subtext';
export var LOADING_SPINNER = 'loading_spinner';
export var LOADING_MESSAGE = 'loading_message';
export var TOAST_NOTIFICATION = 'toast_notification';
export var TOAST_NOTIFICATION_CONTAINER = 'toast_notification_container';
export var DRAWER_CLOSE = 'drawer_close_button';
export var UNDERLINE = 'actionstrip_underline_container';