import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 17px;\n  height: 44px;\n  border-radius: 100%;\n  @media screen and (min-width: ", "px) {\n    background: ", ";\n    width: 44px;\n  }\n  &::after {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TouchableOpacity } from '../TouchableOpacity';
import styled from '../utils/styling/styled-components';
import { BACK_BUTTON } from '../utils/testing/TestIDs';
import { theme } from '../theme'; // @ts-ignore

var A4_BackButton = styled(TouchableOpacity).attrs({
  'data-testid': BACK_BUTTON
})(_templateObject(), theme.breakpoints.sm + 1, function (props) {
  return props.theme.colors.ivory;
});
export { A4_BackButton };