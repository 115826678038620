var ANDROID_CLIENT_NAME = 'android';
/**
 * Legacy implementation for resolving user credentials from Android app
 */

export var resolveAndroidToken = function resolveAndroidToken(resolve) {
  resolve(JSON.parse(window.JSInterface.getAuthentication()));
};
/**
 * Returns true if it's determined that the parent window is housed inside Android app
 */

export var isAndroid = function isAndroid(client) {
  var _JSInterface;

  return client === ANDROID_CLIENT_NAME && ((_JSInterface = window.JSInterface) === null || _JSInterface === void 0 ? void 0 : _JSInterface.getAuthentication) !== undefined;
};