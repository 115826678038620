import { Type, setCookies, write } from '@acorns/web-utils'
import { ExecutionResult } from 'graphql'
import { object, string } from 'yup'

import { ChallengeAuthenticateMutation } from 'generated/types'
import { hasUdid } from 'utils/udid-utils'

import { isWhitelisted } from '../../utils/login-utils'
import {
  ConfirmButtonTapped,
  ExceptionModalViewed,
  ExpiredCodeModalViewed,
  InvalidCodeModalViewed,
  TrustThisDeviceValue,
} from '../../utils/segment'

export type Values = {
  challengeAnswer: string
  challengeId: string
  trustThisDevice: boolean
}

export const validationSchema = object().shape({
  challengeAnswer: string()
    .required()
    .matches(/^[0-9]+$/),
})

// Used by Formik to set initial form values
export const mapPropsToValues = (props): Values => {
  const { challengeAnswer = '', challengeId = '' } = props
  return {
    challengeAnswer,
    challengeId,
    trustThisDevice: false,
  }
}

export const resolveRedirect = (
  defaultRedirect: string,
  referrer?: string,
  preservedRedirect?: string,
): string => {
  let redirect = defaultRedirect
  if (
    referrer &&
    !referrer.includes(window.location.host) &&
    isWhitelisted(referrer)
  ) {
    redirect = referrer
  } else if (preservedRedirect) {
    redirect = `${redirect}${preservedRedirect}`
  } else {
    const { search } = window.location
    redirect = `${redirect}`
    if (search) {
      redirect = `${redirect}${search}`
    }
  }
  return redirect
}

export const handleFormSubmit = (values, { props, setSubmitting }: any) => {
  setSubmitting(true)
  props.setIsLoading(true)
  props.setTitle('Verifying code')
  const rememberMe = values.trustThisDevice
  const challengeAnswer = values.challengeAnswer.toString()
  let challengeId = props.location.data.challengeId
  // this is to check for resendCode option
  if (props.resendChallengeId !== '') {
    challengeId = props.resendChallengeId
  }

  const redirect = resolveRedirect(
    props.env.get('defaultRedirect'),
    document.referrer,
    props.location.data.preservedRedirect,
  )

  return props
    .login({
      variables: {
        input: { ...props.location.data.values, rememberMe },
        challengeAnswerInput: {
          challengeId,
          challengeAnswer,
        },
        oauth: props.isOAuth ?? false,
      },
    })
    .then((result: ExecutionResult<ChallengeAuthenticateMutation>) => {
      const { authenticate } = result.data

      switch (authenticate.__typename) {
        case 'AuthSession':
          props.analytics.track(
            'Button Tapped',
            ConfirmButtonTapped(redirect, 'Confirm'),
          )

          if (props.trustThisDeviceWebIsEnabled && hasUdid()) {
            // only track if the checkbox was shown; this is the same visiblity logic to display the checkbox input
            props.analytics.track(
              `Trust This Device ${rememberMe ? 'Accepted' : 'Declined'}`,
              TrustThisDeviceValue('Trust This Device'),
            )
          }

          if (props.isOAuth) {
            write(Type.identityId, authenticate.identityId, {
              expires: new Date(new Date().getTime() + 2 * 60 * 1000), // expires two minutes from now
            })
          } else {
            props.setTitle('Success')
            setCookies(authenticate)
          }

          props.setIsLoading(false)

          break

        case 'UserSuspendedException':
          if (!authenticate.suspendedToken) {
            props.setException('NoSuspendedTokenException')
          } else {
            props.setException(authenticate.__typename)

            write(Type.suspendedToken, authenticate.suspendedToken)
          }

          props.setIsExceptionModalOpen(true)
          props.analytics.track('Container Viewed', ExceptionModalViewed)

          break

        case 'AuthChallengeExpiredException':
          props.setIsExpiredModalOpen(true)
          props.analytics.track('Container Viewed', ExpiredCodeModalViewed)

          break

        default:
          props.setIsInvalidCodeModalOpen(true)
          props.analytics.track('Container Viewed', InvalidCodeModalViewed)
      }
    })
}
