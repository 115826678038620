import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      background-image: url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100%' opacity='0.1' ry='100%' stroke='%23191919FF' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\");\n      border-radius: 100%;\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  /* Note: This determines the size of the actual chart */\n  width: ", ";\n  height: ", ";\n  transform: rotateZ(90deg);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from '../utils/styling/styled-components';
export var Container = styled.div(_templateObject()); // Circular dashed border generated via: https://kovart.github.io/dashed-border-generator/

export var ChartContainer = styled.div(_templateObject2(), function (props) {
  return props.width;
}, function (props) {
  return props.height;
}, function (props) {
  return (props === null || props === void 0 ? void 0 : props.dashedBorder) && css(_templateObject3());
});