import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  &:before {\n    content: url(\"", "\");\n    height: 10px;\n    margin-right: 5px;\n    position: relative;\n    top: 2px;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  &:before {\n    content: url(\"", "\");\n    height: 10px;\n    margin-right: 5px;\n    position: relative;\n    top: 2px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  &:before {\n    content: url(\"", "\");\n    height: 10px;\n    margin-right: 5px;\n    position: relative;\n    top: 2px;\n  }\n   &::placeholder {\n    color: ", ";\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  font-size: 12px;\n  margin: 5px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 80px;\n  margin-top: 10px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../../utils/styling/styled-components';
import CIRCLE_CHECK_GREEN_ICON from '../../assets/icons/circle-check-green.svg';
import CIRCLE_CHECK_GREY_ICON from '../../assets/icons/circle-check-grey.svg';
import SUBTRACT_RED_ICON from '../../assets/icons/subtract-red.svg';
import { PrivateInput } from '../../Form/PrivateInput';
import { PrivateInputV2 } from '../../FormV2/TextInputs/PrivateInputV2';
var Container = styled.div(_templateObject());
var StyledPrivateInputV1 = styled(PrivateInput)(_templateObject2());
var StyledPrivateInputV2 = styled(PrivateInputV2)(_templateObject3());
var ValidatorContainer = styled.div(_templateObject4());
var ValidationItemLabel = styled.span(_templateObject5());
var Default = styled(ValidationItemLabel)(_templateObject6(), function (props) {
  return props.theme.colors.stone;
}, CIRCLE_CHECK_GREY_ICON, function (props) {
  return props.theme.colors.stoneLight;
});
var Error = styled(ValidationItemLabel)(_templateObject7(), function (props) {
  return props.theme.colors.red;
}, SUBTRACT_RED_ICON);
var Success = styled(ValidationItemLabel)(_templateObject8(), function (props) {
  return props.theme.colors.stone;
}, CIRCLE_CHECK_GREEN_ICON);
export { Container, Default, Error, Success, StyledPrivateInputV1, StyledPrivateInputV2, ValidatorContainer };