import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { AcornsIcon } from '@acorns/icons'
import { theme, withViewportMeta } from '@acorns/web-components'
import { ArrowButton } from '@acorns/web-components'
import { ActionButton } from '@acorns/web-components'
import styled from 'styled-components'

export type TitleBarProps = {
  onBackPress?: () => void
  rightButton?: ActionStripAction[]
  title: JSX.Element | string
  showBack?: boolean
  scrollTop?: number
}

export type ActionStripAction = {
  text: string
  actionText?: string
  handler?: () => void
}

const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  height: 84px;
  background: ${theme.colors.white};
  top: 0px;
  border-bottom: 2px solid ${theme.colors.ivoryLight};
  z-index: 12;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    height: 66px;
    box-shadow: none;
    border-bottom: 1px solid rgba(25, 25, 25, 0);
    transition: border-bottom 250ms ease;

    &.has-scrolled {
      border-bottom: 1px solid rgba(25, 25, 25, 0.04);
    }
  }
`

const ThirdContainer1 = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex: 1;
`
const ThirdContainer2 = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ThirdContainer3 = styled.div`
  display: flex;
  flex: 1;
  justify-content: right;
`

const StyledActionButton = styled(ActionButton)`
  margin-right: 20px;
`

const ActionLink = styled.a`
  margin-right: 20px;
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.stone};
  letter-spacing: 0px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
`

const BackButton = styled(ArrowButton)`
  margin-left: 20px;
  background-color: ${theme.colors.ivory};
  border-color: ${theme.colors.ivory};
`

const MobileBackButton = styled(AcornsIcon)`
  margin-left: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const TitleBar = withViewportMeta((props: TitleBarProps) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm}px)`,
  })

  return (
    <Container className={props.scrollTop > 0 && 'has-scrolled'}>
      <>
        <ThirdContainer1>
          {props.showBack &&
            (isMobile ? (
              <MobileBackButton
                icon={AcornsIcon.Icon.ArrowLeft}
                onClick={props.onBackPress}
                direction={0}
              />
            ) : (
              <BackButton onClick={props.onBackPress} direction={0} />
            ))}
        </ThirdContainer1>
        <ThirdContainer2>{props.title}</ThirdContainer2>
        <ThirdContainer3>
          {props.rightButton &&
            (isMobile
              ? props.rightButton.map((action) => (
                  <ActionLink key={action.text} onClick={action.handler}>
                    {action.text}
                  </ActionLink>
                ))
              : props.rightButton.map((action) => (
                  <StyledActionButton
                    key={action.text}
                    text={action.text}
                    onPress={action.handler}
                    actionText={action.actionText}
                  />
                )))}
        </ThirdContainer3>
      </>
    </Container>
  )
})
