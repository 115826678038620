export var formatPercentage = function formatPercentage(percent) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return (percent * 100).toFixed(precision) + '%';
};
export var getPercentageCompleted = function getPercentageCompleted(numCompleted, numForGoal) {
  // If numForGoal is for some reason 0 avoid a dividing by 0 error
  if (numForGoal === 0) {
    return 0;
  }

  return numCompleted >= numForGoal ? 100 : numCompleted / numForGoal * 100;
};