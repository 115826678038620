// When adding new HTTP header enum values here, please ensure they are all lower-case for consistency.
// Trivia Fact: RFC 2616 states that HTTP headers are expected to be case-insensitive:
// - https://www.rfc-editor.org/rfc/rfc9110.html#:~:text=Field%20names%20are%20case%2Dinsensitive
var HttpHeader;

(function (HttpHeader) {
  HttpHeader["AuthStrategy"] = "auth-strategy";
  HttpHeader["Authorization"] = "authorization";
  HttpHeader["ClientAuthMethod"] = "x-client-auth-method";
  HttpHeader["CsrfToken"] = "x-csrf-token";
  HttpHeader["ClientAppName"] = "x-client-app";
  HttpHeader["ClientBrowserName"] = "x-client-browser";
  HttpHeader["ClientBrowserVersion"] = "x-client-browser-version";
  HttpHeader["ClientBuild"] = "x-client-build";
  HttpHeader["ClientHardware"] = "x-client-hardware";
  HttpHeader["ClientOperatingSystem"] = "x-client-os";
  HttpHeader["ClientPlatform"] = "x-client-platform";
})(HttpHeader || (HttpHeader = {}));

export default HttpHeader;