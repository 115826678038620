import _defineProperty from "@babel/runtime/helpers/defineProperty";
import Cookies from 'js-cookie';
import AuthStrategy from '../../enums/auth-strategy-enum';
import ClientAuthMethod from '../../enums/client-auth-method-enum';
import HttpHeader from '../../enums/http-header-enum';
/**
 * The string value returned for the JWTs when they are scrubbed/redacted from auth-related
 * GraphQL responses to prevent them from being read by client-side JavaScript.
 */

var REDACTED_TOKEN_VALUE = 'REDACTED';
export var Type;

(function (Type) {
  Type["partition"] = "partition";
  Type["token"] = "token";
  Type["csrfToken"] = "csrf_token";
  Type["refreshToken"] = "refresh_token";
  Type["identityId"] = "identityId";
  Type["suspendedToken"] = "suspended_token";
  Type["userScopes"] = "userScopes";
})(Type || (Type = {}));

export var Domain;

(function (Domain) {
  Domain["dev"] = ".dev.acorns.io";
  Domain["staging"] = ".staging.acorns.io";
  Domain["production"] = ".acorns.com";
})(Domain || (Domain = {}));

var NAMESPACE_VALUE = 'acornsweb_';
var HTTP_PROTOCOL = 'http:';
/**
 * Infers the desired cookie `domain` value based on current url
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#Domain_attribute
 */

export var inferDomain = function inferDomain() {
  var _window$location$host = window.location.hostname,
      hostname = _window$location$host === void 0 ? '' : _window$location$host;
  return Object.values(Domain).find(function (domain) {
    return hostname.endsWith(domain);
  }) || hostname;
};
/**
 * Infers the desired cookie `secure` value based on current url
 * When `true`, the cookie will only work for https protocol
 */

export var inferSecure = function inferSecure() {
  var _window$location$prot = window.location.protocol,
      protocol = _window$location$prot === void 0 ? HTTP_PROTOCOL : _window$location$prot;
  return protocol !== HTTP_PROTOCOL;
};
/**
 * Create cookie configuration used by write and destroy functions
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#SameSite_attribute
 */

export var createCookieConfig = function createCookieConfig() {
  return {
    domain: inferDomain(),
    expires: 1 / 48,
    // 30 minutes
    samesite: 'strict',
    secure: inferSecure()
  };
};
/**
 * Namespaces cookie name
 */

export var namespace = function namespace(type) {
  return "".concat(NAMESPACE_VALUE).concat(type);
};
/**
 * Returns namespaced cookie
 */

export var read = function read(type) {
  return Cookies.get(namespace(type));
};
/**
 * Writes namespaced cookie
 */

export var write = function write(type) {
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var cookieConfigOverrides = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var cookieConfig = createCookieConfig();

  if (cookieConfigOverrides.expires) {
    cookieConfig.expires = cookieConfigOverrides.expires;
  }

  Cookies.set(namespace(type), value, cookieConfig);
};
/**
 * Removes namespaced cookie
 */

export var destroy = function destroy(type) {
  return Cookies.remove(namespace(type), createCookieConfig());
};
/**
 * Creates headers for Apollo clients
 */

export var inferAuthHeaders = function inferAuthHeaders(tokenOverride) {
  // The existence of a cookie storing a CSRF token means we are using "implicit" cookie-auth.
  // This means the access/refresh tokens were set as HttpOnly cookies by a GraphQL gateway response
  // and the CSRF token needs to be sent with subsequent GraphQL requests.
  // Note: This is the 'double-submit' cookie strategy for CSRF protection:
  // https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie
  var csrfToken = read(Type.csrfToken);

  if (csrfToken) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, HttpHeader.AuthStrategy, AuthStrategy.Jwt), _defineProperty(_ref, HttpHeader.ClientAuthMethod, ClientAuthMethod.Cookies), _defineProperty(_ref, HttpHeader.CsrfToken, csrfToken), _ref;
  } // This token override solely exists because web-app has a legacy mechanism to persist and
  // retrieve a token from session storage.
  // To maintain some backwards compatibility, this token override support is being added here
  // for web-app to pass in a token it retrieves from session storage, but this isn't expected
  // to be used with JWT-based auth.
  // Phase-out/delete after JWT-based auth is 100% rolled out and stable.


  var token = tokenOverride || read(Type.token); // JWT-based authentication for local development

  if (token && token.length > 64) {
    var _ref2;

    return _ref2 = {}, _defineProperty(_ref2, HttpHeader.AuthStrategy, AuthStrategy.Jwt), _defineProperty(_ref2, HttpHeader.Authorization, "Bearer ".concat(token)), _ref2;
  } // legacy auth; phase-out/delete after JWT-based auth is 100% rolled out and stable.


  return _defineProperty({}, HttpHeader.Authorization, "token ".concat(token));
};
/**
 * Deterministically hashes string into number between 0 and 99.
 * Used by the web-roller-lambda for determining what partition the current user falls under for rolling deployments.
 */

export var toPartition = function toPartition() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '0';
  var hex = parseInt(value.split('').map(function (_char) {
    return _char.charCodeAt(0);
  }).join(''), 10);
  return Math.floor(hex / 100 % 100);
};
/**
 * Sets auth cookies based on AuthSession response
 */

export var setCookies = function setCookies(authSession) {
  if (authSession.token !== REDACTED_TOKEN_VALUE) {
    write(Type.token, authSession.token);
  }

  if (authSession.refreshToken && authSession.refreshToken !== REDACTED_TOKEN_VALUE) {
    write(Type.refreshToken, authSession.refreshToken);
  }

  write(Type.partition, toPartition(authSession.identityId).toString());
};