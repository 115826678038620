import {
  Button,
  Loader,
  Page,
  PasswordInputV4,
  PrivateInputV3,
  Spinner,
  Title,
} from '@acorns/web-components'
import styled from 'styled-components'

import { style } from 'theme'

export const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 27px;
`

export const BodyText = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
`

export const StyledButton = styled(Button)`
  max-width: 340px;
  width: 100%;
  margin: auto;

  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    max-width: 314px;
    margin-bottom: 30px;
  }
`
export const CenterContainer = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
`

// reset password page

export const StyledPasswordInputV4 = styled(PasswordInputV4)`
  > div {
    > div {
      width: unset;
    }
  }
`

export const LoaderContainer = styled.div`
  margin: auto;
  margin-top: -84px;
  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    margin-top: unset;
    margin-bottom: 4px;
  }
`

export const FormContainer = styled.div`
  margin: auto;
  max-width: 340px;

  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    margin-top: unset;
  }
`

export const ChangePasswordButton = styled(Button)`
  max-width: 340px;
  width: 100%;
  margin-top: 50px;
`
export const PasswordFieldContainer = styled.div`
  margin-top: 20px;
`

export const SsnField = styled(PrivateInputV3)`
  margin: auto;
  width: 100%;
  border-color: ${(props) =>
    props.errorText && style((theme) => theme.colors.red)};
  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    /* max-width: 314px; */
  }
`

// reset success  & expired token pages
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 210px;
  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    margin-top: 183px;
  }
`
export const StyledLoader = styled(Loader)`
  margin-bottom: 27px;
`

export const StyledPage = styled(Page)`
  margin: auto;
  height: 100%;
  width: 100%;
`

export const StyledSpinner = styled(Spinner)`
  margin-top: 325px;
  @media screen and (max-width: ${style((theme) => theme.breakpoints.sm)}px) {
    margin-top: 250px;
  }
`
