import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  color: ", ";\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 300;\n  margin-top: 5px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { FieldError } from '../FieldError';
import styled from '../../utils/styling/styled-components';
var ErrorLabel = styled(FieldError)(_templateObject(), function (props) {
  return props.theme.colors.red;
});
export { ErrorLabel };