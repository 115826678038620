import { useContext } from 'react';
import { UserIdContext } from '../providers/userId/UserIdProvider';
export var useUserId = function useUserId() {
  var _useContext = useContext(UserIdContext),
      userId = _useContext.userId,
      setUserId = _useContext.setUserId;

  return {
    userId: userId,
    setUserId: setUserId
  };
};