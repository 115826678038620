import { Children, Component } from 'react'
import PropTypes from 'prop-types'

import { getContext } from 'recompose'

export enum ENV {
  Off = 'off', // Not a real evironment. Just used for completely turning off features.
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

// Note: Provide a helper Provider component that add's an `env` key to it's children's context
export class EnvironmentProvider extends Component<{ env: any }, any> {
  public static propTypes = {
    env: PropTypes.object.isRequired,
  }

  public static childContextTypes = {
    env: PropTypes.object.isRequired,
  }

  public getChildContext() {
    const { env } = this.props
    return { env }
  }

  public render() {
    // `Children.only` enables us not to add a <div /> for nothing
    return Children.only(this.props.children)
  }
}

// Note: Provide a helper HOC that set's a `env` key on it's childrens context
export const withEnv = getContext({
  env: PropTypes.object,
})

export default class Environment {
  public mode: ENV
  private configuration: any

  constructor(configuration, mode) {
    this.configuration = configuration
    this.mode = mode
  }

  public get(key: string) {
    const configurationValue = this.configuration[key]
    // Note: If a configuration value is a string we assume it's the same for each environment
    const value =
      typeof configurationValue === 'object'
        ? configurationValue[this.mode]
        : this.configuration[key]

    if (value === undefined) {
      throw new Error(`Missing "${key}" key in environment "${this.mode}"`)
    }

    return value
  }
}
