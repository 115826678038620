// tslint:disable

export const log = (version?: string, tag?: string) => {
  if (!version && !tag) {
    console.info(
      `%c No "HEAD" environment variable was found to show here!`,
      'background: rgba(255,255,255,0.1); color: #FA2A2B;',
    )
  }

  if (version) {
    console.info(
      `%c Running application build (${version.substring(0, 7)})`,
      'background: #222; color: #57c7e8;',
    )
  }

  if (tag) {
    console.info(
      `%c Running application version (${tag})`,
      'background: #222; color: #57c7e8;',
    )
  }
}
