import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  display: block;\n  content: ' ';\n  position: absolute;\n  background-size: 28px 14px;\n  ", ";\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  all: unset;\n  color: ", ";\n  text-decoration: underline;\n  margin-bottom: 20px;\n  font-size: 14px;\n  cursor: pointer;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 12px;\n  height: 12px;\n  margin-right: 5px;\n  path {\n    &[fill] {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 24px;\n  height: 24px;\n  path {\n    &[fill] {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  box-sizing: border-box;\n  ", "\n  min-width: 290px;\n  max-width: 420px;\n  border-radius: ", ";\n  position: relative;\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  color: #000;\n  font-size: 14px;\n  margin-bottom: 20px;\n  margin-top: 10px;\n  line-height: 22px;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-size: 18px;\n  margin-top: 10px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  height: 30px;\n  background-color: ", ";\n  border-radius: 100px;\n  font-weight: 600;\n  cursor: pointer;\n  border: none;\n  padding: 0px 16px;\n  /* Default placement is right*/\n  ", ";\n  :focus {\n    outline: 0;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n  height: fit-content;\n  margin-top: 4px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 9px;\n  margin-left: 9px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 30px;\n  height: 30px;\n  margin-left: auto;\n  border-radius: 50%;\n  background-color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 0px 20px 30px 30px;\n  height: auto;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 0px 20px 0px 30px;\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 20px 20px 0px 30px;\n  height: ", ";\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 30px;\n  display: flex;\n  justify-content: space-between;\n  line-height: 30px;\n  padding: 20px 20px 30px 30px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { AcornsIcon } from '@acorns/icons';
import styled from '../utils/styling/styled-components';
import Badge from '../Badge';
import { Bodytext, Heading } from '../Typography';
import { StepButtonPlacementTypes } from '../@types';
import { Pointer, correspondingDirection } from './utils';
var Container = styled.div(_templateObject());
var TopContainer = styled(Container)(_templateObject2(), function (props) {
  return props.backgroundImageUrl ? '130px' : 'auto';
}, function (props) {
  return props.backgroundImageUrl && "background: url('".concat(props.backgroundImageUrl, "') no-repeat center center;");
}, function (props) {
  return props.backgroundImageUrl && "border-radius: 10px 10px 0px 0px;";
});
var BodyContainer = styled.div(_templateObject3(), function (props) {
  return props.hasBottomPadding && 'padding-bottom: 10px;';
});
var FooterContainer = styled(Container)(_templateObject4());
var SkipButton = styled.div(_templateObject5(), function (props) {
  return props.skipButtonColor ? props.skipButtonColor : props.theme.colors.ivoryLight;
});
var SkipIcon = styled(AcornsIcon)(_templateObject6());
var StyledBadge = styled(Badge)(_templateObject7(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.badgeColor ? props.badgeColor : props.theme.colors.purple;
});
var Button = styled.button(_templateObject8(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.position === StepButtonPlacementTypes.Left ? 'margin: 0' : props.position === StepButtonPlacementTypes.Middle ? 'margin: auto' : 'margin-left: auto';
});
var LearnTipTitle = styled(Heading)(_templateObject9());
var LearnTipBodyContent = styled(Bodytext)(_templateObject10());
var LearnTipBody = styled.div(_templateObject11(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.width && "width: ".concat(props.width, ";");
}, function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return props.tipAdjustment && props.direction && "".concat(correspondingDirection[props.direction], ": ").concat(props.tipAdjustment, ";");
});
var BadgeIcon = styled(AcornsIcon)(_templateObject12(), function (props) {
  return props.theme.colors.stoneLight;
});
var BadgeTextIcon = styled(AcornsIcon)(_templateObject13(), function (props) {
  return props.theme.colors.white;
});
var BodyCTA = styled.button(_templateObject14(), function (props) {
  return props.theme.colors.stone;
});
var Arrow = styled.div(_templateObject15(), function (props) {
  return props.direction && Pointer[props.direction](props.pointerAdjustment);
});
export { Arrow, Container, TopContainer, BodyContainer, FooterContainer, SkipButton, SkipIcon, StyledBadge, Button, LearnTipTitle, LearnTipBodyContent, LearnTipBody, BadgeIcon, BadgeTextIcon, BodyCTA };