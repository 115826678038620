import React from 'react'

import styled from 'styled-components'

const Message = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
type Props = {
  message: string
}

const AccessibilityAnnouncement: React.SFC<Props> = props => {
  return (
    <Message role="alert" aria-live="polite" aria-atomic="true">
      {props.message}
    </Message>
  )
}

export default AccessibilityAnnouncement
