import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useState } from 'react';
import { isMobile } from 'is-mobile';
var DEFAULT_HEIGHT = 600;
var DEFAULT_WIDTH = 550;
var DEFAULT_WINDOW_NAME = 'Acorns';

/**
 * Converts vanilla object to feature string: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_features
 */
export var generateFeaturesString = function generateFeaturesString(features) {
  return Object.entries(features).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return "".concat(key, "=").concat(value);
  }).join(',');
};
/**
 * Determines optimal position of window on desktop screen
 */

export var calculatePosition = function calculatePosition(height, width) {
  var _window = window,
      screenX = _window.screenX,
      screenY = _window.screenY,
      innerHeight = _window.innerHeight,
      innerWidth = _window.innerWidth;
  return {
    height: height,
    left: (innerWidth - width) / 2 + screenX,
    top: (innerHeight - height) / 2 + screenY,
    width: width
  };
};
/**
 * Low level function for opening new window
 */

export var launchWindowHelper = function launchWindowHelper(url) {
  var features = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _features$windowName = features.windowName,
      windowName = _features$windowName === void 0 ? DEFAULT_WINDOW_NAME : _features$windowName,
      rest = _objectWithoutProperties(features, ["windowName"]);

  return window.open(url, windowName, generateFeaturesString(rest));
};
/**
 * Provides `launchResponsiveWindow` method that will open the provided url in a popup window on desktop devices, or redirect to the provided url on mobile devices
 */

export var useLaunchWindow = function useLaunchWindow() {
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      win = _useState2[0],
      setWin = _useState2[1]; // Low level implementation


  var launchWindow = function launchWindow() {
    var launchedWindow = launchWindowHelper.apply(void 0, arguments);
    setWin(launchedWindow);
    return launchedWindow;
  }; // High level/opinionated window features


  var launchResponsiveWindow = function launchResponsiveWindow(url) {
    if (isMobile()) {
      return launchWindow(url, {
        windowName: '_blank'
      });
    } else {
      return launchWindow(url, _objectSpread({
        windowName: DEFAULT_WINDOW_NAME
      }, calculatePosition(DEFAULT_HEIGHT, DEFAULT_WIDTH)));
    }
  };

  return {
    launchResponsiveWindow: launchResponsiveWindow,
    launchWindow: launchWindow,
    win: win
  };
};