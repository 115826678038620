/**
 * READ ME.
 *
 * Please use snake-case for all routes and not camelCase.
 *
 * Right: /settings/my-stuff
 *
 * Wrong: /settings/myStuff
 */

export const routes = {
  oAuth: {
    authorize: '/oauth/authorize',
  },
  resetPassword: {
    index: '/users/password/edit',
    success: '/users/password/edit/success',
  },
  forgotPassword: {
    index: '/forgot-password',
  },
  login: '/',
}
