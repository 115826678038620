import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 16px;\n  font-weight: 600;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from '../utils/styling/styled-components';
export var Bold = styled.strong(_templateObject(), function (props) {
  return props.theme.colors.slate;
});