import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  width: 100%;\n  height: ", "px;\n  text-align: center;\n  z-index: 0;\n\n  @media screen and (max-width: ", "px){\n    height: 300px;\n  } \n\n  ", "\n\n  @media screen and (max-width: ", "px) {\n    ", "\n  }\n\n  background-image: ", ";\n\n  background-repeat: no-repeat;\n  background-position: center center;\n  box-shadow: ", ";\n  border-radius: ", ";\n\n  margin-bottom: 20px;\n  padding: 20px;\n\n  &:after {\n    content: '';\n\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    pointer-events: none;\n\n    ", "\n    background-repeat: no-repeat repeat;\n    opacity: ", ";\n    background-position: center 0;\n\n    ", "\n\n    z-index: 0;\n  }\n\n  & > * {\n    z-index: 1;\n  }\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", " 2s ease-in-out;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", " 2500ms ease-in-out;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    height: ", "px;\n    transform: translateY(", ");\n    z-index: 20;\n  }\n  60% {\n    height: ", "px;\n    transform: translateY(", ");\n    z-index: 20;\n  }\n  99% {\n    z-index: 20;\n  }\n  100% {\n    height: 350px;\n    transform: translateY(0);\n    z-index: 0;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", " 1.8s linear;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  from {\n    background-position: center 30px;\n  }\n  to {\n    background-position: center 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css, keyframes } from '../utils/styling/styled-components';
import { HERO_CARD } from '../utils/testing/TestIDs';
import LeafPatternSVGURL from './leaf.svg';
var backgroundScroll = keyframes(_templateObject());

var backgroundScrollRule = function backgroundScrollRule() {
  return css(_templateObject2(), backgroundScroll);
};

var shrink = function shrink(heightOffset) {
  var translateOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '-74px';
  return keyframes(_templateObject3(), window.innerHeight - heightOffset, translateOffset, window.innerHeight - heightOffset, translateOffset);
};

var shrinkRule = function shrinkRule() {
  return css(_templateObject4(), shrink(140));
};

var shrinkMobileRule = function shrinkMobileRule() {
  return css(_templateObject5(), shrink(70, '0px'));
};

var Container = styled.div.attrs({
  'data-testid': HERO_CARD
})(_templateObject6(), function (props) {
  return props.height ? props.height : 350;
}, function (props) {
  return props.theme.breakpoints.sm;
}, function (props) {
  return props.animate && "\n    animation: ".concat(shrinkRule, ";\n    will-change: height, transform;\n  ");
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.animate && "\n      animation: ".concat(shrinkMobileRule, ";\n    ");
}, function (props) {
  var gradient = props.gradient ? props.gradient : props.secondary ? props.theme.gradients.slate : props.theme.gradients.green;
  return props.inverted ? "linear-gradient(46deg, ".concat(gradient[1], " 0%, ").concat(gradient[1], " 100%)") : "linear-gradient(46deg, ".concat(gradient[0], " 0%, ").concat(gradient[1], " 100%)");
}, function (props) {
  return props.theme.shadows.lgMedium;
}, function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return !props.hasHiddenLeafImage && "\n      background: url(\"".concat(LeafPatternSVGURL, "\") no-repeat center center;\n    ");
}, function (props) {
  return props.secondary ? '0.07' : '0.03';
}, function (props) {
  return props.animate && "\n      animation-fill-mode: forwards;\n      animation: ".concat(backgroundScrollRule, "\n    ");
}, function (props) {
  return props.expand && "\n    @media screen and (max-width: ".concat(props.theme.breakpoints.md, "px) {\n      border-radius: 0;\n      margin: -40px -30px 20px;\n      width: auto;\n    }\n\n    @media screen and (max-width: ").concat(props.theme.breakpoints.sm, "px) {\n      margin: -20px -20px 20px;\n    }\n  ");
});
var HeroCard = Container;
export { HeroCard };