// Shareable typings and interfaces
export var StepDirectionTypes;

(function (StepDirectionTypes) {
  StepDirectionTypes["Top"] = "top";
  StepDirectionTypes["Right"] = "right";
  StepDirectionTypes["Bottom"] = "bottom";
  StepDirectionTypes["Left"] = "left";
})(StepDirectionTypes || (StepDirectionTypes = {}));

export var StepButtonPlacementTypes;

(function (StepButtonPlacementTypes) {
  StepButtonPlacementTypes["Middle"] = "middle";
  StepButtonPlacementTypes["Left"] = "left";
})(StepButtonPlacementTypes || (StepButtonPlacementTypes = {}));