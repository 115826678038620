const FEATURE_ID = 'twoFactorAuthEnterCode'
const MfaPageAnalytics = {
  screen: FEATURE_ID,
  screen_name: FEATURE_ID,
}

export const MfaScreenViewed = (previousRoute: string) => ({
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthEnterCode',
  origin: previousRoute,
})

export const ConfirmButtonTapped = (
  destinationRoute: string,
  ctaTitle: string,
) => ({
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthEnterCode',
  destination: destinationRoute,
  cta_title: ctaTitle,
})

export const InvalidCodeModalViewed = {
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthInvalid',
}

export const HelpModalViewed = {
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthHelp',
}

export const ResendButtonTapped = (ctaTitle: string) => ({
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthResend',
  cta_title: ctaTitle,
})

export const HelpButtonTapped = (ctaTitle: string) => ({
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthHelp',
  cta_title: ctaTitle,
})

export const ExpiredCodeModalViewed = {
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthSessionExpired',
}

export const ExceptionModalViewed = {
  ...MfaPageAnalytics,
  object_name: 'twoFactorAuthException',
}

export const TrustThisDeviceValue = (ctaTitle: string) => ({
  ...MfaPageAnalytics,
  object_name: 'trustThisDeviceInput',
  cta_title: ctaTitle,
})

export const LoginRedirect = (destinationRoute: string) => ({
  ...MfaPageAnalytics,
  destination: destinationRoute,
  object_name: 'loginRedirect',
})

const LOG_IN = 'logIn'
const loginAnalytics = {
  screen: LOG_IN,
  screen_name: LOG_IN,
}

export const logInViewed = {
  ...loginAnalytics,
  object_name: 'logIn',
}

export const logInForgotPassword = {
  ...loginAnalytics,
  object_name: 'logInForgotPassword',
}

export const logInNext = {
  ...loginAnalytics,
  object_name: 'logInNext',
}

const FORGOT_PASSWORD = 'logIn'
const forgotPasswordAnalytics = {
  screen: FORGOT_PASSWORD,
  screen_name: FORGOT_PASSWORD,
}

export const logInForgotPasswordViewed = {
  ...forgotPasswordAnalytics,
  object_name: 'logInForgotPassword',
}

export const logInForgotPasswordNext = {
  ...loginAnalytics,
  object_name: 'logInForgotPasswordNext',
}

export const logInForgotPasswordBack = {
  ...loginAnalytics,
  object_name: 'logInForgotPasswordBack',
}

export const loadingTimeLogIn = (time: number) => ({
  object_name: 'loadingTimeLogin',
  delta_seconds: time,
})

export const loadingTimeLogInSuccess = (time: number) => ({
  object_name: 'loadingTimeLoginSuccess',
  delta_seconds: time,
})

export const MaintenanceScreenViewed = (previousRoute: string) => ({
  screen: 'maintenance',
  screen_name: 'maintenance',
  object_name: 'maintenance',
  origin: previousRoute,
})

// Reset Password Index Page
export const invalidResetToken = () => ({
  object_name: 'invalidResetToken',
})

export const validResetToken = () => ({
  object_name: 'validResetToken',
})

// Invalid Reset Token Screen
export const invalidResetTokenScreenView = () => ({
  object_name: 'invalidResetTokenScreenView',
  event: 'screen_viewed',
})

// Reset Password Success Token Screen
export const resetPasswordSuccessScreenView = () => ({
  object_name: 'resetPasswordSuccessScreenView',
  event: 'screen_viewed',
})

// Reset Password Form Screen
export const resetPasswordFormScreenView = () => ({
  object_name: 'resetPasswordFormScreenView',
  event: 'screen_viewed',
})

export const resetPasswordFormErrors = (resetPasswordErrors) => ({
  object_name: 'resetPasswordFormErrors',
  event: 'screen_viewed',
  errors: resetPasswordErrors,
})

export const resetPasswordUserCredentialsNotFound = () => ({
  object_name: 'resetPasswordUserCredentialsNotFound',
  event: 'screen_viewed',
})

export const resetPasswordSuccess = () => ({
  object_name: 'resetPasswordSuccess',
  event: 'mutation_success',
})

export const resetPasswordButtonClicked = () => ({
  object_name: 'resetPasswordButtonClicked',
  event: 'button_clicked',
})
