import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useState } from 'react';
import { defaults } from '../HOCs/withTrackClicked/defaults';
import { useAnalyticsClient } from '../providers/analytics';
import { useOnView } from './useOnView';

var useTrackViewed = function useTrackViewed(viewType, _ref) {
  var fields = _ref.fields;

  var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref2$delay = _ref2.delay,
      delay = _ref2$delay === void 0 ? 1000 : _ref2$delay,
      _ref2$onlyTrackOnce = _ref2.onlyTrackOnce,
      onlyTrackOnce = _ref2$onlyTrackOnce === void 0 ? true : _ref2$onlyTrackOnce;

  var analyticsClient = useAnalyticsClient();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      tracked = _useState2[0],
      setTracked = _useState2[1];

  var defaultFields = defaults(viewType !== null && viewType !== void 0 ? viewType : 'Container Viewed');

  var _useOnView = useOnView(function (inView) {
    if (inView) {
      if (!tracked || !onlyTrackOnce) {
        var payload = _objectSpread(_objectSpread({}, defaultFields), fields);

        var resolvedViewType = viewType !== null && viewType !== void 0 ? viewType : 'Container Viewed';
        analyticsClient.track(resolvedViewType, payload);
        setTracked(true);
      }
    }
  }, {
    delay: delay,
    threshold: 0.5,
    triggerOnce: onlyTrackOnce
  }),
      ref = _useOnView.ref;

  return {
    ref: ref
  };
};

export { useTrackViewed };