export var stripWhiteSpace = function stripWhiteSpace() {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return val.replace(/\W/g, '');
};
export var capitalizeFirstLetter = function capitalizeFirstLetter(word) {
  var result = '';

  if (word !== null) {
    word = word.toLowerCase();
    var chars = word.split(' ');
    chars.map(function (w, i) {
      if (chars.length - 1 === i) {
        result += w.charAt(0).toUpperCase() + w.slice(1);
      } else {
        result += w.charAt(0).toUpperCase() + w.slice(1) + ' ';
      }
    });
  } else {
    result = null;
  }

  return result;
};