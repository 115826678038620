import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationModal, ModalActionTypes } from '@acorns/web-components'
import { DocumentNode } from 'graphql'
import { path } from 'ramda'
import {
  compose,
  getContext,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose'

import AlertBadgePath from '../../assets/icons/alert-badge.png'

export type ErrorDetails = {
  actions?: any
  body?: any
  eventFields?: object
  title?: any
  image?: string
}

export type ErrorPayload = {
  graphQLErrors: any
  networkError: any
}

export type ErrorModalProps = {
  closeErrorModal: () => void
  errorDetails: ErrorDetails
  showErrorModal: (details: ErrorDetails) => void
  showModal: boolean
}

type LifecycleProps = {
  innerRef: (ref: any) => void
}

type ErrorHandler = () => ErrorDetails | false

const mergeDefaults = (handler, details: ErrorDetails = {}): ErrorDetails => {
  return {
    actions: [
      {
        handler,
        label: 'Try Again',
        type: ModalActionTypes.primary,
      },
    ],
    body: 'It looks like something went wrong.',
    title: 'Oops!',
    image: AlertBadgePath,
    ...details,
  }
}

export const enhance = compose<ErrorModalProps, LifecycleProps>(
  withState('errorDetails', 'setErrorDetails', mergeDefaults(() => ({}))),
  withState('showModal', 'setShowModal', false),
  withHandlers({
    closeErrorModal: ({ setShowModal }) => () => {
      setShowModal(false)
    },
  }),
  withHandlers({
    showErrorModal: ({
      closeErrorModal,
      setErrorDetails,
      setShowModal,
    }) => errorDetails => {
      setErrorDetails(mergeDefaults(closeErrorModal, errorDetails))
      setShowModal(true)
    },
  }),
  lifecycle<LifecycleProps, {}>({
    componentDidMount() {
      const { innerRef } = this.props

      if (innerRef) {
        innerRef(this)
      }
    },
  }),
)

// Presentational component used for displaying errors
const ErrorModal = enhance(props => (
  <ConfirmationModal
    actions={props.errorDetails.actions}
    message={props.errorDetails.body}
    title={props.errorDetails.title}
    eventFields={props.errorDetails.eventFields}
    image={props.errorDetails.image}
    isOpen={props.showModal}
  />
))

// Registry for graphql error handlers
export class Provider extends Component<{}, any> {
  public static childContextTypes = {
    addCustomHandler: PropTypes.func.isRequired,
    closeErrorModal: PropTypes.func.isRequired,
    getCustomHandler: PropTypes.func.isRequired,
    removeCustomHandler: PropTypes.func.isRequired,
    showErrorModal: PropTypes.func.isRequired,
  }

  private modal: React.Component<ErrorModalProps>

  private customHandlerMap: Map<string, boolean | ErrorHandler>

  constructor(props) {
    super(props)

    this.customHandlerMap = new Map<string, boolean | ErrorHandler>()
  }

  public getChildContext() {
    const {
      addCustomHandler,
      closeErrorModal,
      getCustomHandler,
      removeCustomHandler,
      showErrorModal,
    } = this

    return {
      addCustomHandler,
      closeErrorModal,
      getCustomHandler,
      removeCustomHandler,
      showErrorModal,
    }
  }

  public addCustomHandler = (name, handler) => {
    this.customHandlerMap.set(name, handler)
  }

  public closeErrorModal = () => {
    this.modal.props.closeErrorModal()
  }

  public getCustomHandler = name => {
    return this.customHandlerMap.get(name)
  }

  public modalRef = (modal?: React.Component<ErrorModalProps>) => {
    this.modal = modal
  }

  public removeCustomHandler = name => {
    return this.customHandlerMap.delete(name)
  }

  public showErrorModal = details => {
    this.modal.props.showErrorModal(details)
  }

  public render() {
    return (
      <>
        {this.props.children}

        <ErrorModal innerRef={this.modalRef} />
      </>
    )
  }
}

// HOC for use by apollo-link-error to access registered error handlers
// and display error modal
export const withApolloErrorModal = compose(
  getContext({
    getCustomHandler: PropTypes.func,
    showErrorModal: PropTypes.func,
    closeErrorModal: PropTypes.func,
  }),
)

interface IWithErrorHandlerProps {
  addCustomHandler: (
    name: string,
    handler: (error: ErrorPayload) => ErrorDetails,
  ) => void
  closeErrorModal: () => void
  removeCustomHandler: (name: string) => void
}

type Handler<T> = (
  props: T,
) => (error: ErrorPayload) => ErrorDetails | false | Promise<ErrorDetails>

type Name = string | ((props: any) => string) | DocumentNode

export const resolveName = (name, props) => {
  switch (typeof name) {
    case 'function':
      return name(props)
    case 'object':
      return path(['definitions', 0, 'name', 'value'], name)
    default:
      return name
  }
}

// HOC for registering new error handlers for use by components that implement
// graphql queries and mutations
export const withApolloErrorHandler = <InnerProps extends any>(
  name: Name,
  handler: Handler<InnerProps>,
) =>
  compose<IWithErrorHandlerProps, {}>(
    getContext({
      addCustomHandler: PropTypes.func,
      closeErrorModal: PropTypes.func,
      removeCustomHandler: PropTypes.func,
    }),
    lifecycle<IWithErrorHandlerProps, {}>({
      componentDidMount() {
        this.props.addCustomHandler(
          resolveName(name, this.props),
          handler(this.props),
        )
      },

      componentDidUpdate() {
        this.props.addCustomHandler(
          resolveName(name, this.props),
          handler(this.props),
        )
      },

      componentWillUnmount() {
        this.props.removeCustomHandler(resolveName(name, this.props))
      },
    }),
  )
