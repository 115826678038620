import { theme as WebComponentsTheme } from '@acorns/web-components'
import { path as getForPath } from 'ramda'

export const theme = (path: string) => (props: any) =>
  getForPath(path.split('.'), props.theme) as string | number

export const style =
  (cb: (theme: typeof WebComponentsTheme) => any) => (props: any) =>
    cb(props.theme)

export default WebComponentsTheme
