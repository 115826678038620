import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import posed from 'react-pose';
import styled from '../utils/styling/styled-components';
import downPointer from './pointers/bottom-pointer.svg';
import upPointer from './pointers/top-pointer.svg';
import rightPointer from './pointers/right-pointer.svg';
import leftPointer from './pointers/left-pointer.svg';
var Body = styled(posed.div({
  enter: {
    scale: 1,
    transition: {
      scale: {
        damping: 25,
        stiffness: 850,
        type: 'spring'
      }
    }
  },
  exit: {
    scale: 0.5,
    transition: {
      scale: {
        damping: 25,
        stiffness: 850,
        type: 'spring'
      }
    }
  }
}))(_templateObject());
var Pointer = {
  top: function top(offset) {
    return "\n    background: url(\"".concat(downPointer, "\") no-repeat;\n    height: 14px;\n    width: 28px;\n    bottom: -13px;\n    ").concat(offset && "left: ".concat(offset, ";"), "\n  ");
  },
  bottom: function bottom(offset) {
    return "\n    background: url(\"".concat(upPointer, "\") no-repeat;\n    height: 14px;\n    width: 28px;\n    top: -13px;\n    ").concat(offset && "left: ".concat(offset, ";"), "\n  ");
  },
  left: function left(offset) {
    return "\n    background: url(\"".concat(rightPointer, "\") no-repeat;\n    right: -14px;\n    height: 28px;\n    width: 14px;\n    ").concat(offset && "top: ".concat(offset, ";"), "\n  ");
  },
  right: function right(offset) {
    return "\n    background: url(\"".concat(leftPointer, "\") no-repeat;\n    left: -13px;\n    height: 28px;\n    width: 14px;\n    ").concat(offset && "top: ".concat(offset, ";"), "\n  ");
  }
}; //This is used to help us figure out what direction the tip should be adjusted with, based on which direction the tip is pointing

var correspondingDirection = {
  top: 'left',
  bottom: 'left',
  right: 'top',
  left: 'top'
}; // Hide Learn Tip for integration test with cy.setLearnTipHidden()

export var LEARN_TIP_HIDDEN = 'learn_tip_hidden';
export { Body, Pointer, correspondingDirection };