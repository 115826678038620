import { useSessionStorage } from '../utils/storage/session-storage/useSessionStorage';
var LOCALHOST_REGEX = /^(https?:\/\/)?localhost:([a-z0-9]+)/;

var useUrlResolver = function useUrlResolver(url, projectName, isDevMode) {
  var queryString = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  var _useSessionStorage = useSessionStorage(),
      getItem = _useSessionStorage.getItem;
  /**
   * customBuild can look like feature.<hash>.js OR localhost:<port>
   */


  var customBuild = getItem("".concat(projectName, "_builds"));

  if (LOCALHOST_REGEX.test(url)) {
    return url;
  }

  if (LOCALHOST_REGEX.test(customBuild) && isDevMode) {
    return customBuild;
  }
  /**
   * projectFolderUrl takes something like "https://d2q8ok342705wr.cloudfront.net/feature/feature.js"
   * and transforms it to "https://d2q8ok342705wr.cloudfront.net/feature"
   */


  var projectFolderUrl = url.split('/').slice(0, 4).join('/');
  /**
   * build looks like "feature.js" or "feature.e67bg2.js". The latter will only
   * ever appear in dev mode.
   */

  var build = isDevMode && customBuild || "".concat(projectName, ".js");
  var finalUrl = projectFolderUrl + '/' + build + (isDevMode && customBuild ? '' : queryString);
  return finalUrl;
};

export { useUrlResolver };