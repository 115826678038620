import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 12px;\n  line-height: 22px;\n  text-align: center;\n\n  ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  font-size: 12px;\n  line-height: 22px;\n  text-align: center;\n  position: absolute;\n  top: -25px;\n  right: 23px;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  align-items: flex-start;\n  display: inline-flex;\n  position: relative;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 40px;\n  height: 60px;\n  line-height: 60px;\n  position: absolute;\n  right: 2px;\n  width: 23px;\n\n  @media screen and (max-width: ", "px) {\n    font-size: 24px;\n    height: 48px;\n    line-height: 48px;\n    right: -7px;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  color: ", ";\n  font-size: 14px;\n  font-weight: 600;\n  bottom: ", ";\n  left: 50%;\n  transform: translateX(-50%);\n  width: max-content;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-size: 40px;\n  height: 60px;\n  line-height: 60px;\n\n  @media screen and (max-width: ", "px) {\n    font-size: 24px;\n    height: 48px;\n    line-height: 48px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-size: 100px;\n  height: 110px;\n  line-height: 110px;\n\n  @media screen and (max-width: ", "px) {\n    font-size: 60px;\n    line-height: 75px;\n    height: 75px;\n  }\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  border-width: 0;\n  box-sizing: border-box;\n  color: ", ";\n  font-family: Avenir Next Web;\n  font-weight: normal;\n  line-height: normal;\n  max-width: 100%;\n  outline: none;\n  padding: 0;\n  vertical-align: middle;\n  width: 200px;\n  background-color: transparent;\n\n  ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &::selection {\n    background-color: #f5f5f5;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 40px;\n  font-weight: 500;\n  margin-top: 10px;\n  color: ", ";\n\n  @media screen and (max-width: ", "px) {\n    font-size: 24px;\n    margin-top: 10px;\n  }\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  min-height: 132px;\n  user-select: none;\n  ", "\n\n  @media screen and (max-width: ", "px) {\n    min-height: 98px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " 0.52s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  10%, 90% {\n    transform: translate3d(-2px, 0, 0);\n  }\n\n  20%, 80% {\n    transform: translate3d(3px, 0, 0);\n  }\n\n  30%, 50%, 70% {\n    transform: translate3d(-5px, 0, 0);\n  }\n\n  40%, 60% {\n    transform: translate3d(5px, 0, 0);\n  }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { isSafari } from '../../utils/is-safari';
import styled, { css, keyframes } from '../../utils/styling/styled-components';

var styleSafari = function styleSafari(styles) {
  return isSafari() ? styles : null;
};

var BREAKPOINT = 560;
var shakeKeyframes = keyframes(_templateObject());

var shake = function shake() {
  return css(_templateObject2(), shakeKeyframes);
};

export var Container = styled.div(_templateObject3(), function (props) {
  return props.shake && shake;
}, BREAKPOINT);
export var InputPrefix = styled.span(_templateObject4(), function (props) {
  return props.theme.colors.green;
}, BREAKPOINT, function (props) {
  return props.primaryColorOff && "color: ".concat(props.theme.colors.stoneLight);
}, function (props) {
  return props['data-error-color'] && "\n  color: ".concat(props['data-error-color'], ";\n");
});
var InputBase = styled.input(_templateObject5(), function (props) {
  return props.theme.colors.green;
}, function (props) {
  return props['data-error-color'] && "\n  color: ".concat(props['data-error-color'], ";\n");
}, function (props) {
  return props.theme.colors.stoneLight;
});
export var DollarInput = styled(InputBase)(_templateObject6(), BREAKPOINT, styleSafari("\n  height: 122px;\n  line-height: 122px;\n  margin-top: -14px;\n\n  &::-webkit-input-placeholder {\n    line-height: 138px;\n  }\n"));
export var CentInput = styled(InputBase)(_templateObject7(), BREAKPOINT);
export var HelperText = styled.p(_templateObject8(), function (props) {
  return props.theme.colors.green;
}, function (props) {
  return props.readOnly ? '-28px' : '-40px';
});
export var HundredthPlaceholder = styled.span(_templateObject9(), function (props) {
  return props.theme.colors.stoneLight;
}, BREAKPOINT); // Should be a fieldset with a legend inside, but we can't use flexbox in fieldset 😭
// @see https://bugs.chromium.org/p/chromium/issues/detail?id=375693

export var InputContainer = styled.div(_templateObject10());
export var Label = styled.span(_templateObject11());
export var Error = styled.div(_templateObject12(), function (props) {
  return props.theme.colors.red;
}, function (props) {
  return props.color && "color: ".concat(props.color);
});