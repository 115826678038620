import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'

import { createSink } from 'recompose'

export const trackPage = ({ location }) => {
  ReactGA.pageview(location.pathname + location.search)
}

export default withRouter(createSink(trackPage))
