export var formatAmountString = function formatAmountString(stringSplits) {
  if (stringSplits[0] === '$' || stringSplits[0] === '') {
    return '';
  }

  if (stringSplits.length > 1) {
    return '$' + parseInt(stringSplits[0], 10).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '.' + stringSplits[1];
  }

  return '$' + parseInt(stringSplits[0], 10).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export var getCaretPosition = function getCaretPosition(currentPosition, previousString, newString, unformattedString) {
  if (newString === '$0.') {
    return 3;
  } else if (previousString === '$0.' && currentPosition === 3) {
    return 2;
  } else if (newString === '$0.0' && previousString === '$0') {
    return 3;
  } else if (previousString === newString) {
    if (currentPosition === 1 && previousString.includes('$') && previousString.length >= 1 && previousString[1] === '0') {
      return currentPosition + 1;
    }

    return currentPosition - 1;
  } else if (previousString.includes('$') && !unformattedString.includes('$')) {
    return newString.length;
  } else if (previousString.includes('$0.') && !newString.includes('.')) {
    return currentPosition - 1;
  }

  var positionOffset = 0;
  var newCommaSplit = newString.split(',').length;
  var oldCommaSplit = previousString.split(',').length;
  positionOffset += newCommaSplit - oldCommaSplit;

  if (!previousString.includes('$')) {
    positionOffset += 1;
  }

  return positionOffset + currentPosition;
};
export var getDecimalSplitString = function getDecimalSplitString(stringSplits) {
  if (stringSplits.length > 1) {
    return stringSplits[0].replace(/\D/g, '') === '' ? '0' + '.' + stringSplits[1].replace(/\D/g, '').slice(0, 2) : stringSplits[0].replace(/\D/g, '') + '.' + stringSplits[1].replace(/\D/g, '').slice(0, 2);
  } else {
    return stringSplits[0].replace(/\D/g, '');
  }
};
export var getStringVariables = function getStringVariables(eventString, dollarAmountString) {
  var formattedEventString = eventString.replace(/[^0-9$.]/g, '');
  var unformattedString = formattedEventString;
  formattedEventString = formattedEventString.replace('..', '.');

  if (formattedEventString.split('.').length > 2) {
    formattedEventString = dollarAmountString.replace(/[^0-9$.]/g, '');
  } else if (formattedEventString === '.') {
    formattedEventString = '0.';
  } else if (formattedEventString === '$.0') {
    formattedEventString = '0.0';
  }

  var decimalSplit = formattedEventString.split('.');
  formattedEventString = getDecimalSplitString(decimalSplit);
  var numericalString = formattedEventString.split('.')[0].replace(/\D/g, '');
  return [formattedEventString, unformattedString, numericalString];
};