import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n  text-align: center;\n  margin-top: 20px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  height: 100px;\n  width: 300px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n  @media screen and (max-width: ", "px) {\n    height: 193px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  color: ", ";\n  margin-left: 10px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 14px;\n  line-height: 22px;\n  margin-left: 10px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin-top: 5px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 30px;\n  line-height: 38px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  width: 320px;\n  margin-bottom: 50px;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { Bodytext, Heading } from '../Typography';
import styled from '../utils/styling/styled-components';
export var PerformancePriceContainer = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.md;
});
export var PerformancePrice = styled(Heading)(_templateObject2());
export var PerformanceGainLossContainer = styled.div(_templateObject3());
export var PerformanceGainLossAmount = styled(Heading)(_templateObject4());
export var PerformanceGainLossDate = styled(Heading)(_templateObject5(), function (props) {
  return props.theme.colors.stone;
});
export var PerformanceChartContainer = styled.div(_templateObject6(), function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.theme.breakpoints.sm;
});
export var PerformanceChartFooter = styled(Bodytext)(_templateObject7(), function (props) {
  return props.theme.colors.stone;
});