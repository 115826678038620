import { v4 as uuidv4, validate } from 'uuid'

import { localStorageSupported } from './local-storage-utils'

const udidLocalStorageKey = 'udid_web'
const webUdidPrefix = 'web__'

let oneTimeUdid: string = null

function generateUdid() {
  return uuidv4()
}

function setUdid() {
  if (!localStorageSupported()) {
    return
  }

  // Note: Make sure we don't overwrite an existing udid; overwrite it if it's not valid.
  const udid = localStorage.getItem(udidLocalStorageKey)
  if (udid && validate(udid)) {
    return
  }

  localStorage.setItem(udidLocalStorageKey, generateUdid())
}

function getUdid() {
  return localStorageSupported()
    ? localStorage.getItem(udidLocalStorageKey) || null
    : null
}

function hasUdid() {
  return !!getUdid()
}

function getUdidForAuthentication() {
  let udid = getUdid()

  if (udid === null) {
    // Persist a one-time udid/namespace so back-to-back failed logins don't look like they're coming from different udids.
    if (oneTimeUdid === null) {
      oneTimeUdid = generateUdid()
    }
    udid = oneTimeUdid
  }

  return webUdidPrefix + udid
}

export { setUdid, getUdid, hasUdid, getUdidForAuthentication }
