import { object, string } from 'yup'

import { emailToPrefill } from 'pages/login/form'

import { Props } from './index'

export type Values = {
  email: string
}

type SubmitArgs = {
  props: Props
  setSubmitting: (value: boolean) => void
}

export const validationSchema = object().shape({
  email: string()
    .email()
    .required(),
})

// Used by Formik to set initial form values
export const mapPropsToValues = props => {
  const { email = '' } = props

  return {
    email: emailToPrefill ? emailToPrefill : email,
  }
}

export const handleFormSubmit = (
  values,
  { props, setSubmitting }: SubmitArgs,
) => {
  setSubmitting(true)

  return props
    .reset({ variables: values })
    .then(() => {
      props.setIsModalOpen(true)
    })
    .finally(() => setSubmitting(false))
}
