import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react'; // Credit: https://usehooks.com/useLocalStorage/
// Modified a bit by me

var useSessionStorage = function useSessionStorage() {
  var _useState = useState(function () {
    return new Map(Object.entries(sessionStorage));
  }),
      _useState2 = _slicedToArray(_useState, 2),
      storage = _useState2[0],
      setStorage = _useState2[1];

  var setItem = function setItem(key, value) {
    sessionStorage.setItem(key, value);
    setStorage(function (prevStorage) {
      prevStorage.set(key, value);
      return new Map(prevStorage);
    });
  };

  var getItem = function getItem(key) {
    return storage.get(key);
  };

  return {
    setItem: setItem,
    getItem: getItem,
    storage: storage
  };
};

export { useSessionStorage };