import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject14() {
  var data = _taggedTemplateLiteral(["cubic-bezier(0.55, -0.21, 0.14, 1.24) 300ms\n      ", ""]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n    animation: ", ";\n    animation-fill-mode: forwards;\n  "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: ", "px;\n  left: ", "px;\n  text-align: center;\n  color: ", ";\n  background: ", ";\n  box-shadow: ", ";\n  height: 30px;\n  font-size: 12px;\n  width: 93px;\n  margin: -105px 0px 0px -40px;\n  z-index: 4;\n  border-radius: 5px;\n  user-select: none;\n\n  @media screen and (max-width: ", "px) {\n    margin-top: -85px;\n  }\n  ", "\n  &::after {\n    content: ' ';\n    position: absolute;\n    top: 100%;\n    border-width: 5px;\n    border-style: solid;\n    border-color: ", " transparent transparent transparent;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n            ", " 300ms\n              cubic-bezier(0.55, -0.21, 0.14, 1.24)\n          "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n          animation: ", ";\n        "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n            ", " 300ms\n              cubic-bezier(0.55, -0.21, 0.14, 1.24)\n          "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n          animation: ", ";\n        "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  width: 2px;\n  left: ", "px;\n  z-index: 4;\n  user-select: none;\n  margin-left: 6px;\n  &::after {\n    content: ' ';\n    position: absolute;\n    top: 100%;\n    left: 50%;\n  }\n  mask-image: linear-gradient(\n    rgba(0, 0, 0, 1),\n    rgba(0, 0, 0, 0.75),\n    rgba(0, 0, 0, 0.5),\n    rgba(0, 0, 0, 0.25),\n    rgba(0, 0, 0, 0)\n  );\n  ", "\n  animation-fill-mode: forwards;\n  ", "\n  bottom: 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  fill: none;\n  stroke: ", ";\n  stroke-width: 3;\n  stroke-linecap: round;\n  stroke-linejoin: round;\n  stroke-dasharray: 3, 7;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  from {\n    height: ", ";\n    ", "\n  }\n  to {\n    height: ", "px;\n    ", "\n    opacity: 0;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  from {\n    height: ", ";\n    ", "\n  }\n  to {\n    height: ", ";\n    ", "\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  0% { opacity:0; }\n  95% { opacity:0; }\n  100% { opacity:1; }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { motion } from 'framer-motion';
import styled, { css, keyframes } from '../../utils/styling/styled-components';
import { theme } from '../../theme';
var LINE_HEIGHT_START = '0px';
var LINE_HEIGHT_END = window.screen.width > theme.breakpoints.sm ? '212px' : '130px;';
export var Wrapper = styled.div(_templateObject());
var delayedFadeInAnimation = keyframes(_templateObject2());
export var lineHeightIncreaseAnimation = function lineHeightIncreaseAnimation(lineHeight) {
  return keyframes(_templateObject3(), LINE_HEIGHT_START, lineHeight ? 'top: 0;' : '', lineHeight ? "".concat(lineHeight, "px") : LINE_HEIGHT_END, lineHeight ? 'top: -50px;' : '');
};

var lineHeightDecreaseAnimation = function lineHeightDecreaseAnimation(lineHeight) {
  return keyframes(_templateObject4(), lineHeight ? "".concat(lineHeight, "px") : LINE_HEIGHT_END, lineHeight ? 'top: -50px;' : '', LINE_HEIGHT_START, lineHeight ? 'top: 0;' : '');
};

export var StyledVerticalLineSvgLine = styled.line(_templateObject5(), function (_ref) {
  var lineColor = _ref.lineColor;
  return lineColor ? lineColor : '#ffffff';
});
export var StyledVerticalLineSvg = styled.svg(_templateObject6(), function (_ref2) {
  var x = _ref2.x;
  return x - 8;
}, function (_ref3) {
  var showLine = _ref3.showLine,
      lineHeight = _ref3.lineHeight;
  return showLine ? css(_templateObject7(), css(_templateObject8(), lineHeight ? lineHeightIncreaseAnimation(lineHeight) : lineHeightIncreaseAnimation())) : css(_templateObject9(), css(_templateObject10(), lineHeight ? lineHeightDecreaseAnimation(lineHeight) : lineHeightDecreaseAnimation()));
}, function (_ref4) {
  var lineHeight = _ref4.lineHeight;
  return lineHeight ? 'top: -50px' : '';
});
export var Legend = styled(motion.div)(_templateObject11(), function (_ref5) {
  var y = _ref5.y;
  return y - 18;
}, function (_ref6) {
  var x = _ref6.x;
  return x - 8;
}, theme.colors.white, theme.colors.slate, theme.shadows.smMedium, theme.breakpoints.md, function (_ref7) {
  var selectedIndex = _ref7.selectedIndex;
  return selectedIndex === 0 && "\n      width: 70px;\n      margin-left: -28px;\n    ";
}, theme.colors.slate);
export var TooltipText = styled.div(_templateObject12(), css(_templateObject13(), css(_templateObject14(), delayedFadeInAnimation)));