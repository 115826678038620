import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n        color: ", ";\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        color: ", ";\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        color: ", ";\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 100px;\n  padding: 19px 0;\n  text-align: center;\n  border: none;\n  margin: 0 auto;\n  max-width: 100%;\n  font-weight: 500;\n  background-color: transparent;\n  outline: none;\n  &::placeholder {\n    color: ", ";\n  }\n  ", ";\n  @media screen and (max-width: ", "px) {\n    font-size: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, css } from '../utils/styling/styled-components';
var StyledInput = styled('input')(_templateObject(), function (props) {
  return props.theme.colors.green;
}, function (props) {
  if (props.error === 'placeholder') {
    return css(_templateObject2(), function (props) {
      return props.theme.colors.green;
    });
  } else if (props.error === 'error') {
    return css(_templateObject3(), function (props) {
      return props.theme.colors.red;
    });
  } else {
    return css(_templateObject4(), function (props) {
      return props.theme.colors.green;
    });
  }
}, function (props) {
  return props.theme.breakpoints.sm;
}, function (props) {
  return props.length > 6 ? '60px' : '100px';
});
export { StyledInput };