import { useInView } from 'react-intersection-observer';
import { useRef, useEffect } from 'react';

var useOnView = function useOnView(callback) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$delay = _ref.delay,
      delay = _ref$delay === void 0 ? 1000 : _ref$delay,
      _ref$threshold = _ref.threshold,
      threshold = _ref$threshold === void 0 ? 0.5 : _ref$threshold,
      _ref$triggerOnce = _ref.triggerOnce,
      triggerOnce = _ref$triggerOnce === void 0 ? false : _ref$triggerOnce;

  var _useInView = useInView({
    delay: delay,
    triggerOnce: triggerOnce,
    threshold: threshold
  }),
      ref = _useInView.ref,
      inView = _useInView.inView;

  var savedCallback = useRef();
  useEffect(function () {
    savedCallback.current = callback;
  });
  useEffect(function () {
    var _savedCallback$curren;

    (_savedCallback$curren = savedCallback.current) === null || _savedCallback$curren === void 0 ? void 0 : _savedCallback$curren.call(savedCallback, inView);
  }, [inView]);
  return {
    ref: ref
  };
};

export { useOnView };