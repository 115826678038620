import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: white;\n  font-size: 22px;\n  font-weight: 400;\n  text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
import { HERO_TITLE } from '../utils/testing/TestIDs';
var HeroTitle = styled.div.attrs({
  'data-testid': HERO_TITLE
})(_templateObject());
export { HeroTitle };