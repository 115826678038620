import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AcornsIcon } from '@acorns/icons'
import {
  ConfirmationModal,
  Heading2,
  Loader,
  ModalActionTypes,
  Spinner,
} from '@acorns/web-components'
import { Type, write } from '@acorns/web-utils'
import { useFormik } from 'formikV2'

import AcornsLogoSvgPath from 'assets/icons/acorns-logo.svg'
import ConnectorSvgPath from 'assets/icons/connector.svg'
import Layout from 'components/Layout'
import {
  getDecodedBase64UrlObjects,
  getDecodedBase64UrlStrings,
} from 'utils/decode-base64'
import Environment, { withEnv } from 'utils/environment'
import { OAuthPartnerConfig } from 'utils/shared-types'
import {
  OAUTH_AUTHORIZATION_ALLOW_BUTTON,
  OAUTH_AUTHORIZATION_PARTNER_LOGO,
  OAUTH_AUTHORIZATION_PARTNER_NAME,
} from 'utils/testing/testIds'

import {
  ActionsContainer,
  AllowButton,
  CenterContainer,
  CheckboxContainer,
  Connector,
  DenyButton,
  Form,
  FormContainer,
  LoaderContainer,
  LogoContainer,
  PartnerLogo,
  StyledCheckboxInput,
  StyledDescription,
  StyledTitle,
} from './styled-components'
import { formatUserScopes } from './utils'

interface AuthValues {
  email: string
  password: string
  udid: string
}

interface HistoryProps {
  alternateAuthenticatorId: string
  challengeId: string
  email: string
  mode: string
  oAuthPartnerParams: OAuthPartnerConfig
  preservedQueryParams: string
  preservedRedirect: string
  values: AuthValues
}

const OAuthAuthorization = ({ env }: { env: Environment }) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const [title, setTitle] = useState('Authorize')

  const state = history.location.data
  const { oAuthPartnerParams, isMfaExemptOAuthUser } = state
  const onBackPress = () => {
    if (isMfaExemptOAuthUser) {
      history.push({
        pathname: '/',
      })
    } else {
      history.push({
        pathname: '/oauth/mfa-challenge',
        data: state,
      })
    }
  }

  const handleToggleHelpModal = () => {
    if (isHelpModalOpen) {
      setIsHelpModalOpen(false)
    } else {
      setIsHelpModalOpen(true)
    }
  }

  const { handleSubmit, handleChange, values, isSubmitting } = useFormik({
    initialValues: getDecodedBase64UrlObjects(
      oAuthPartnerParams.app_scopes,
    ).reduce((acc, checkbox) => {
      acc[checkbox.identifier] = checkbox.required
      return acc
    }, {}),

    onSubmit: () => {
      setIsLoading(true),
        setTitle('Processing'),
        write(Type.userScopes, formatUserScopes(values), {
          expires: new Date(new Date().getTime() + 2 * 60 * 1000), // expires two minutes from now
        })
      setIsLoading(false)
    },
  })

  const isAnyScopesChecked = () => {
    return Object.values(values).some((scope) => scope === true)
  }

  const onAnimationEnd = () => {
    setTitle('Success')
    if (!isLoading) {
      window.location.href =
        env.get('partnerLoginOAuthRedirectUrl') + state.preservedQueryParams
    }
  }

  const partnerName =
    oAuthPartnerParams.page_type === 'aggregator' &&
    oAuthPartnerParams.app_client_name
      ? oAuthPartnerParams.app_client_name
      : oAuthPartnerParams.app_name

  const partnerIcon = oAuthPartnerParams.app_image_uri

  if (isSubmitting) {
    return (
      <Layout title={<Heading2>{title}</Heading2>} showBack={false}>
        <CenterContainer>
          <LoaderContainer>
            <Loader
              loading={isLoading}
              endSegment={[90, 160]}
              onAnimationEnd={onAnimationEnd}
              minimumAnimationTime={1000}
            />
          </LoaderContainer>
        </CenterContainer>
      </Layout>
    )
  }

  return (
    <>
      <Layout
        title={<Heading2>Authorize </Heading2>}
        showBack={true}
        onBackPress={onBackPress}
        rightButton={[
          {
            text: 'Help',
            actionText: 'Help',
            handler: handleToggleHelpModal,
          },
        ]}
      >
        <FormContainer>
          <LogoContainer>
            <PartnerLogo
              src={getDecodedBase64UrlStrings(partnerIcon)}
              alt="Partner Logo"
              data-testid={OAUTH_AUTHORIZATION_PARTNER_LOGO}
              height={'64px'}
            />
            <Connector src={ConnectorSvgPath} alt="connector" />
            <PartnerLogo src={AcornsLogoSvgPath} alt="Acorns logo" />
          </LogoContainer>
          <StyledTitle data-testid={OAUTH_AUTHORIZATION_PARTNER_NAME}>
            Authorize {partnerName}
          </StyledTitle>
          <StyledDescription>
            {partnerName} is requesting permission to access your Acorns account
            information. All account information may not be available for access
            at this time.
          </StyledDescription>
          <Form onSubmit={handleSubmit}>
            <CheckboxContainer>
              {getDecodedBase64UrlObjects(oAuthPartnerParams.app_scopes).map(
                (checkbox, index) => (
                  <StyledCheckboxInput
                    key={index}
                    label={checkbox.title}
                    id={checkbox.identifier}
                    name={checkbox.identifier}
                    checked={values[checkbox.identifier]}
                    onChange={handleChange}
                    disabled={checkbox.required}
                  />
                ),
              )}
            </CheckboxContainer>
            <ActionsContainer>
              <AllowButton
                disabled={isSubmitting || !isAnyScopesChecked()}
                onPress={handleSubmit}
                data-testid={OAUTH_AUTHORIZATION_ALLOW_BUTTON}
                buttonType="submit"
              >
                {!isSubmitting ? (
                  'Allow'
                ) : (
                  <Spinner height={20} width={20} color={'white'} />
                )}
              </AllowButton>
              <DenyButton to="/">Deny</DenyButton>
            </ActionsContainer>
          </Form>
        </FormContainer>
      </Layout>
      <ConfirmationModal
        isOpen={isHelpModalOpen}
        actions={[
          {
            handler: handleToggleHelpModal,
            label: 'Ok',
            type: ModalActionTypes.secondary,
          },
        ]}
        title="Need help?"
        message={
          'If you are having trouble verifying, please get in touch with our support team: 855-739-2859'
        }
        image={<AcornsIcon icon={AcornsIcon.Icon.AlertBlack} width="80px" />}
      />
    </>
  )
}

export default withEnv(OAuthAuthorization)
