// Documentation: http://www.chartjs.org/docs/latest/
export default {
  animation: false,
  elements: {
    line: {
      tension: 0.4
    }
  },
  maintainAspectRatio: false,
  layout: {
    padding: 0
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  scales: {
    xAxes: [{
      display: false,
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      display: false,
      gridLines: {
        display: false
      }
    }]
  }
};