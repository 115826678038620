import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var formatter = function formatter(locale, currency) {
  var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return new Intl.NumberFormat(locale, _objectSpread({
    style: 'currency',
    currency: currency
  }, config));
};

var USDFormatter = formatter('en-US', 'USD');
var USDFormatterRound = formatter('en-US', 'USD', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
});

var FormatWithUnitPostfix = function FormatWithUnitPostfix(amount) {
  var isTrillion = amount >= 1.0e12;
  var isBillion = amount >= 1.0e9;
  var isMillion = amount >= 1.0e6;
  var isThousand = amount >= 1.0e3; // Checking less than 15 zeros

  switch (Math.abs(Number(amount)) < 1.0e15) {
    case isTrillion:
      return (amount / 1.0e12).toFixed(2) + 'T';

    case isBillion:
      return (amount / 1.0e9).toFixed(2) + 'B';

    case isMillion:
      return (amount / 1.0e6).toFixed(2) + 'M';

    case isThousand:
      return (amount / 1.0e3).toFixed(2) + 'K';

    default:
      return amount.toString();
  }
}; // Rounds and truncates amounts like 34.00 to $34 but formats amounts
// like 34.78 to $34.78


var USDFormatterAutoRound = function USDFormatterAutoRound(amount) {
  var isInteger = Number.isInteger(amount);
  return isInteger ? USDFormatterRound.format(amount) : USDFormatter.format(amount);
};

export { formatter, USDFormatter, USDFormatterRound, USDFormatterAutoRound, FormatWithUnitPostfix };