// @ts-ignore: Existing issue with library that was never addressed https://github.com/reactchartjs/react-chartjs-2/issues/131
import { Chart } from 'react-chartjs-2';
import { arc as d3Arc } from 'd3-shape';
import { styleArc } from '../utils';
var INNER_RADIUS_OFFSET = 5;
Chart.elements.BasicDoughnut = Chart.elements.Arc.extend({
  draw: function draw() {
    var _this$_chart, _this$_chart$active;

    var ctx = this._chart.ctx;
    var vm = this._view;
    var index = this._index;
    var options = this._chart.options;
    var startAngle = vm.startAngle;
    var endAngle = vm.endAngle;
    var outerRadius = vm.outerRadius;
    var innerRadius = vm.innerRadius + INNER_RADIUS_OFFSET;

    if (options.offset) {
      innerRadius += options.offset;
    }

    var active = (_this$_chart = this._chart) === null || _this$_chart === void 0 ? void 0 : (_this$_chart$active = _this$_chart.active) === null || _this$_chart$active === void 0 ? void 0 : _this$_chart$active[0];
    var isActive = active && active._index === index;
    var isBlurred = active && active._index !== index;

    if (!options["static"] && isActive) {
      outerRadius = outerRadius + 3;
      innerRadius = innerRadius - 3;
      startAngle = startAngle - 0.04;
      endAngle = endAngle + 0.04;
    }

    ctx.save();
    ctx.translate(this._chart.width / 2, this._chart.height / 2);
    var drawArc = d3Arc().startAngle(startAngle).endAngle(endAngle).outerRadius(outerRadius).innerRadius(innerRadius);

    if (options === null || options === void 0 ? void 0 : options.round) {
      drawArc = drawArc.padAngle(0.04).cornerRadius(5).context(ctx);
    } else {
      drawArc = drawArc.context(ctx);
    }

    ctx.beginPath();
    drawArc(ctx);
    ctx.closePath();
    ctx.restore();
    styleArc({
      context: ctx,
      options: options,
      backgroundColor: vm.backgroundColor,
      isActive: isActive,
      isBlurred: isBlurred,
      borderWidth: vm.borderWidth,
      borderColor: vm.borderColor,
      currentIndex: index,
      startAngle: startAngle,
      endAngle: endAngle,
      height: this._chart.height
    });
  }
}); // Copy over the defaults of the standard `doughnut` chart type

Chart.defaults.basic = Chart.helpers.clone(Chart.defaults.doughnut);
Chart.controllers.basic = Chart.controllers.doughnut.extend({
  dataElementType: Chart.elements.BasicDoughnut
});