import { initializeAcornsWebGlobalNamespace } from '../namespace'; // NOTE: `getWindow()` should generally be used in lieu of of using the global `window` object directly.
// See more information below.

export var getWindow = function getWindow() {
  initializeAcornsWebGlobalNamespace();
  return window;
}; // eslint-disable-next-line jest/no-commented-out-tests

/*
  "Why does this exist??"

  `getWindow()` exists primarily to make unit testing significantly easier + cleaner + more straightforward
  when working with the `window` object since `getWindow()` can be spied on to return a mocked `window` object.

  For example, when writing a unit test to assert something calls `window.location.assign()`
  for a redirect (or rather, `getWindow().location.assign()`), that unit test might look
  something like this (using 'jest-mock-extended' to auto-mock `window` to preserve type-safety):
  ```
    import { mock } from 'jest-mock-extended'
    import * as getWindow from 'utils/get-window'
    import type { WindowType } from 'utils/get-window'

    it('should redirect as expected', () => {
      // arrange
      const mockWindow = mock<WindowType>({
        location: {
          assign: jest.fn(),
        },
      })

      const getWindowSpy = jest
        .spyOn(getWindow, 'default')
        .mockReturnValue(mockWindow)

      // act
      [do something that calls `getWindow().location.assign('https://acorns.com')`]

      // assert
      expect(mockWindow.location.assign).toHaveBeenCalledWith('https://acorns.com')

      // clean up
      getWindowSpy.mockRestore()
    })
  ```

  This gets us away from doing a sub-optimal hack like deleting properties off of `window` in the arrangement
  of a unit test to assign spies to functions on `window` to assert on, which is (was) a common hack.
  Going off of the example above with `window.location.assign`, this type of hack looks (looked)
  something like this:
  ```
    it('should redirect as expected', () => {
      // arrange
      const originalWindowLocation = window.location
      delete window.location
      window.location = { assign: jest.fn() }

      // act
      ...

      // assert
      ...

      // clean up
      window.location = originalWindowLocation
    })
  ```

  There are a few other variations of this sub-optimal hack out there as well (ex. using `Object.defineProperty`).

  Using `getWindow()` and spying on it should be much more straightforward since spying on methods and
  mocking their implementation is a more typical/common pattern we have for writing unit tests.
*/