import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  all: unset;\n  background-color: ", ";\n  height: 44px;\n  width: 44px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 3px;\n  cursor: pointer;\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 30px 0px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 152px;\n  ", "\n  input {\n    width: 152px;\n    text-align: center;\n    font-size: 22px;\n    font-weight: 600;\n    color: ", ";\n  }\n  &:focus-within {\n    background: ", ";\n  }\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
import { TextInputV3 } from '../FormV3/TextInputsV3/TextInputV3';
export var StyledTextInput = styled(TextInputV3)(_templateObject(), function (props) {
  return props.isOverMaxNumber && "\n      border-color: red;\n    \n    ";
}, function (props) {
  return props.isZero ? props.theme.colors.stone : props.theme.colors.slate;
}, function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.theme.colors.ivory;
});
export var TargetAllocatorContainer = styled.div(_templateObject2());
export var GrayCircleIconButton = styled.button(_templateObject3(), function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.disabled && 'opacity: 0.4';
});