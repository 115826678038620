import { compose, replace, toLower } from 'ramda';
export var siftResults = function siftResults(data, target, sortProp) {
  // find correct data based on user input
  var result = data.filter(function (item) {
    return item.displayName.toLowerCase().includes(target.toLowerCase());
  }); // sort by name property, where it compares two strings returning either -1, 1, or 0 accordingly to sort
  // The final sorted array is in ascending order

  var sortedResult = result.sort(function (a, b) {
    var valueA = a[sortProp].toLowerCase();
    var valueB = b[sortProp].toLowerCase();
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
  return sortedResult;
};
export var formatUrlSlug = compose(encodeURIComponent, toLower, replace('%', '-percent'), replace(/ /g, '-'));