/**
 * Ensures that all required values are present on mobile identity
 */
export var validateMobileIdentity = function validateMobileIdentity(_ref) {
  var sessionId = _ref.sessionId,
      token = _ref.token,
      uuid = _ref.uuid;
  var mobileIdentity = {
    sessionId: sessionId,
    token: token,
    uuid: uuid
  };

  if (!sessionId || !token || !uuid) {
    return null;
  }

  return mobileIdentity;
};