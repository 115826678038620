import { path } from 'ramda';
var PARAM_REGEX = /:[a-zA-Z0-9]+$/;

var resolveRedirect = function resolveRedirect(pathname, matchPath) {
  var redirect = '/';
  var pathSegments = pathname.split('/').splice(1);

  if (pathSegments.length > 0) {
    var modifier = 1; // If path has url parameter, navigate up an additional level

    if (matchPath.match(PARAM_REGEX)) {
      modifier++;
    }

    pathSegments.splice(pathSegments.length - modifier);
    redirect = "/".concat(pathSegments.join('/'));
  }

  return redirect;
};

var handleBackAction = function handleBackAction(_ref) {
  var backOverride = _ref.backOverride,
      history = _ref.history,
      location = _ref.location,
      match = _ref.match,
      _ref$restoreScrollPos = _ref.restoreScrollPosition,
      restoreScrollPosition = _ref$restoreScrollPos === void 0 ? false : _ref$restoreScrollPos;
  var redirect = backOverride || path(['state', 'referrer'], location); // If no referrer is passed, navigate up one level

  if (!redirect) {
    redirect = resolveRedirect(location.pathname, match.path);
  }

  history.push(redirect, {
    restoreScrollPosition: restoreScrollPosition
  });
};

export { handleBackAction, resolveRedirect };