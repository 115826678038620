import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-size: 12px;\n  line-height: 16px;\n  color: #6f6f6f;\n  a {\n    color: #6f6f6f;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  padding-bottom: 0px !important;\n  font-size: 12px;\n  line-height: 16px;\n  color: #6f6f6f;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  font-size: 14px;\n  line-height: 22px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  font-size: 14px;\n  line-height: 22px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  font-size: 14px;\n  line-height: 22px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin: 30px 0px 30px 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { CheckboxInput } from '../../Form/CheckboxInput';
import styled from '../../utils/styling/styled-components';
export var TierContainer = styled.div(_templateObject());
export var TierLevelContainer = styled.div(_templateObject2());
export var TierPriceContainer = styled.div(_templateObject3());
export var Bodytext = styled.div(_templateObject4());
export var StyledCheckboxInput = styled(CheckboxInput)(_templateObject5());
export var CheckboxText = styled.span(_templateObject6());