// This is terrible.
//
// - When the feature toggle for Web App's side navigation bar was implemented, checks against the
//   feature toggle should _never_ have been included in our shared component library.
// - Unfortunately, feature toggle checks _were_ included in our shared component library, which
//   makes systemizing this toggle a mess.
// - We can not systemize this toggle in web-components as we typically would by "deleting one side"
//   of the toggle usage. If we did, this would result in Web App having the expected display behavior
//   for a shared component, but not other apps consuming the same shared component (like Acorns Hello
//   and Login App) because those apps do not have a side navigation bar to display.
// - This situtation leads us to this terrible hack to checking an indicator for whether the app consuming
//   web-components displays a side navigation bar (i.e. Web App) to replace the previous toggle checks.
//
// Resolving this more properly requires significantly more effort. This hack is the path of least
// resistance we're taking right now given the urgency around cleaning up Web App's feature toggles.
var HAS_SIDE_NAVIGATION_BAR_STORAGE_KEY = 'web__has_side_navigation_bar';
export function hasSideNavigationBar() {
  return window.sessionStorage.getItem(HAS_SIDE_NAVIGATION_BAR_STORAGE_KEY) === 'true';
}