import React from 'react'

import {
  NoSuspendedTokenExceptionModal,
  UserSuspendedExceptionModal,
} from 'components/Modals'
import Environment from 'utils/environment'

type Props = {
  exception: string
  isExceptionModalOpen: boolean
  handleCloseExceptionModal: () => void
  env?: Environment
}

const AuthenticateExceptionHandler = (props: Props) => {
  if (props.exception === 'UserSuspendedException') {
    return (
      <UserSuspendedExceptionModal
        handleCloseExceptionModal={props.handleCloseExceptionModal}
        env={props.env}
        isExceptionModalOpen={props.isExceptionModalOpen}
      />
    )
  } else if (props.exception === 'NoSuspendedTokenException') {
    return (
      <NoSuspendedTokenExceptionModal
        handleCloseExceptionModal={props.handleCloseExceptionModal}
        env={props.env}
        isExceptionModalOpen={props.isExceptionModalOpen}
      />
    )
  } else {
    return null
  }
}

export default AuthenticateExceptionHandler
