import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  vertical-align: middle;\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 600;\n  padding: 3px 11px;\n  border-radius: 11px;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
export var Badge = styled.div(_templateObject(), function (_ref) {
  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'default' : _ref$type,
      theme = _ref.theme;
  var backgroundColor;
  var color;

  switch (type) {
    case 'default':
      backgroundColor = theme.colors.ivory;
      color = theme.colors.stone;
      break;

    case 'primary':
      backgroundColor = theme.colors.sky;
      color = theme.colors.white;
      break;

    case 'overlay':
      backgroundColor = 'rgba(255, 255, 255, 0.1)';
      color = theme.colors.white;
      break;
  }

  return {
    backgroundColor: backgroundColor,
    color: color
  };
});
export default Badge;