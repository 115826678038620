import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _tooltipStylesMap;

import { theme } from '../theme';
import { TooltipStyle } from './types';
import { MARGIN } from './constants';
export var tooltipStylesMap = (_tooltipStylesMap = {}, _defineProperty(_tooltipStylesMap, TooltipStyle.Default, {
  color: theme.colors.white,
  backgroundColor: theme.colors.slate,
  padding: '6px 10px'
}), _defineProperty(_tooltipStylesMap, TooltipStyle.Green, {
  color: theme.colors.white,
  backgroundColor: theme.colors.green,
  padding: '4px 7px'
}), _tooltipStylesMap);
export var arrowPlacementResolvers = {
  bottom: function bottom() {
    return "\n    justify-content: center;\n    top: 0;\n    margin-top: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'bottom-start': function bottomStart() {
    return "\n    justify-content: center;\n    top: 0;\n    margin-top: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'bottom-end': function bottomEnd() {
    return "\n    justify-content: center;\n    top: 0;\n    margin-top: ".concat(-1 * MARGIN, "px;\n  ");
  },
  left: function left() {
    return "\n    align-items: center;\n    justify-content: flex-end;\n    right: 0;\n    margin-right: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'left-start': function leftStart() {
    return "\n    align-items: center;\n    justify-content: flex-end;\n    right: 0;\n    margin-right: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'left-end': function leftEnd() {
    return "\n    align-items: center;\n    justify-content: flex-end;\n    right: 0;\n    margin-right: ".concat(-1 * MARGIN, "px;\n  ");
  },
  right: function right() {
    return "\n    align-items: center;\n    justify-content: flex-start;\n    left: 0;\n    margin-left: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'right-start': function rightStart() {
    return "\n    align-items: center;\n    justify-content: flex-start;\n    left: 0;\n    margin-left: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'right-end': function rightEnd() {
    return "\n    align-items: center;\n    justify-content: flex-start;\n    left: 0;\n    margin-left: ".concat(-1 * MARGIN, "px;\n  ");
  },
  top: function top() {
    return "\n    align-items: flex-end;\n    justify-content: center;\n    bottom: 0;\n    margin-bottom: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'top-start': function topStart() {
    return "\n    align-items: flex-end;\n    justify-content: center;\n    bottom: 0;\n    margin-bottom: ".concat(-1 * MARGIN, "px;\n  ");
  },
  'top-end': function topEnd() {
    return "\n    align-items: flex-end;\n    justify-content: center;\n    bottom: 0;\n    margin-bottom: ".concat(-1 * MARGIN, "px;\n  ");
  }
};
export var arrowColorResolvers = {
  bottom: function bottom(arrowColor) {
    return "\n    border-bottom-color: ".concat(arrowColor, ";\n  ");
  },
  'bottom-start': function bottomStart(arrowColor) {
    return "\n    border-bottom-color: ".concat(arrowColor, ";\n  ");
  },
  'bottom-end': function bottomEnd(arrowColor) {
    return "\n    border-bottom-color: ".concat(arrowColor, ";\n  ");
  },
  left: function left(arrowColor) {
    return "\n    border-left-color: ".concat(arrowColor, ";\n  ");
  },
  'left-start': function leftStart(arrowColor) {
    return "\n    border-left-color: ".concat(arrowColor, ";\n  ");
  },
  'left-end': function leftEnd(arrowColor) {
    return "\n    border-left-color: ".concat(arrowColor, ";\n  ");
  },
  right: function right(arrowColor) {
    return "\n    border-right-color: ".concat(arrowColor, ";\n  ");
  },
  'right-start': function rightStart(arrowColor) {
    return "\n    border-right-color: ".concat(arrowColor, ";\n  ");
  },
  'right-end': function rightEnd(arrowColor) {
    return "\n    border-right-color: ".concat(arrowColor, ";\n  ");
  },
  top: function top(arrowColor) {
    return "\n    border-top-color: ".concat(arrowColor, ";\n  ");
  },
  'top-start': function topStart(arrowColor) {
    return "\n    border-top-color: ".concat(arrowColor, ";\n  ");
  },
  'top-end': function topEnd(arrowColor) {
    return "\n    border-top-color: ".concat(arrowColor, ";\n  ");
  }
};