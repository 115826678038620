function localStorageSupported() {
  // Note: Sometimes local storage isn't supported (ex. iOS Safari Privacy Browsing).
  // Detecting local storage: https://gist.github.com/paulirish/5558557
  try {
    localStorage.setItem('__key', '__value')
    localStorage.removeItem('__key')
    return true
  } catch (e) {
    return false
  }
}

export { localStorageSupported }
