import { useContext, useEffect } from 'react';
import { ApolloErrorModalContext } from './provider';
import { resolveName } from './handler';
export var useApolloErrorHandler = function useApolloErrorHandler(name, handler) {
  var dependencies = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var apolloErrorModalContext = useContext(ApolloErrorModalContext);
  useEffect(function () {
    var resolvedName = resolveName(name);
    apolloErrorModalContext.addCustomHandler(resolvedName, handler);
    return function () {
      return apolloErrorModalContext.removeCustomHandler(resolvedName);
    };
  }, dependencies);
  return apolloErrorModalContext;
};
export var useApolloErrorModal = function useApolloErrorModal() {
  return useContext(ApolloErrorModalContext);
};