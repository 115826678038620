import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import ColorInterpolate from './ColorInterpolate';

/**
 * TODO: This is stripped straight from the package: https://github.com/parksben/create-conical-gradient
 *
 * There are already plans to make this a part of the built-in canvas api so when that
 * is supported on all relevant browsers, we can take this out and use the built-in one.
 * https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/createConicGradient
 * */
export default function createConicalGradient(userContext) {
  var colorStops = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [[0, '#fff'], [1, '#fff']];
  var x = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var y = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var startAngle = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var endAngle = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 2 * Math.PI;
  var anticlockwise = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  var degStart = Math.floor(startAngle * 180 / Math.PI);
  var degEnd = Math.ceil(endAngle * 180 / Math.PI); // init off-screen canvas

  var canvas = document.createElement('canvas');
  canvas.width = userContext.canvas.width;
  canvas.height = userContext.canvas.height;
  var ctx = canvas.getContext('2d'); // user canvas corners

  var corners = [[0, 0], [userContext.canvas.width, 0], [userContext.canvas.width, userContext.canvas.height], [0, userContext.canvas.height]]; // gradient radius

  var radius = Math.max.apply(Math, _toConsumableArray(corners.map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        cx = _ref2[0],
        cy = _ref2[1];

    return Math.sqrt(Math.pow(cx - x, 2) + Math.pow(cy - y, 2));
  }))) + 10;
  ctx.translate(x, y);
  var lineWidth = 2 * Math.PI * (radius + 20) / 360; // color linear interpolate

  var interpolate = new ColorInterpolate(colorStops, degEnd - degStart + 1); // draw gradient image

  for (var i = degStart; i <= degEnd; i++) {
    ctx.save();
    ctx.rotate((anticlockwise ? -1 : 1) * (Math.PI * i) / 180);
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(radius, -2 * lineWidth);
    ctx.lineTo(radius, 0);
    ctx.fillStyle = interpolate.getColor(i - degStart);
    ctx.fill();
    ctx.closePath();
    ctx.restore();
  } // clip content overflow


  var cvsForClip = document.createElement('canvas');
  cvsForClip.width = userContext.canvas.width;
  cvsForClip.height = userContext.canvas.height;
  var clipCtx = cvsForClip.getContext('2d');
  clipCtx.beginPath();
  clipCtx.arc(x, y, radius, startAngle, endAngle, anticlockwise);
  clipCtx.lineTo(x, y);
  clipCtx.closePath();
  clipCtx.fillStyle = clipCtx.createPattern(canvas, 'no-repeat');
  clipCtx.fill();
  return userContext.createPattern(cvsForClip, 'no-repeat');
}

if (window.CanvasRenderingContext2D) {
  CanvasRenderingContext2D.prototype.createConicalGradient = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var ctx2d = this;
    var obj = {
      stops: [],
      addColorStop: function addColorStop(offset, color) {
        this.stops.push([offset, color]);
      },

      get pattern() {
        return createConicalGradient.apply(void 0, [ctx2d, this.stops].concat(args));
      }

    };
    return obj;
  };
}