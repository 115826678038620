import { min } from 'ramda';
import useScrollPosition from '@react-hook/window-scroll';
export var useStickyScroll = function useStickyScroll(_ref) {
  var _ref$scrollDistance = _ref.scrollDistance,
      scrollDistance = _ref$scrollDistance === void 0 ? 60 : _ref$scrollDistance;
  var scrollY = useScrollPosition(60);
  return {
    scrollPercent: min(scrollY, scrollDistance) / scrollDistance
  };
};
export var useScrollHeight = function useScrollHeight(_ref2) {
  var _ref2$scrollDistance = _ref2.scrollDistance,
      scrollDistance = _ref2$scrollDistance === void 0 ? 60 : _ref2$scrollDistance;
  var scrollY = useScrollPosition(60);
  return {
    scrollHeight: scrollY / scrollDistance
  };
};