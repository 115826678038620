import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { compose, withPropsOnChange } from 'recompose';
import { withViewportMeta } from '../../HOCs/withViewportMeta';
var SIZES = {
  lg: {
    buffer: 2,
    cent: 48,
    character: 58,
    comma: 26,
    "default": 60
  },
  sm: {
    buffer: 0,
    cent: 30,
    character: 36,
    comma: 12,
    "default": 36
  }
}; // Calculates width of dollar input based on formatted value

var withInputWidth = function withInputWidth(propName) {
  return compose(withViewportMeta, withPropsOnChange([propName, 'pageWidth'], function (props) {
    var sizes = props.pageWidth > 560 ? SIZES.lg : SIZES.sm;
    var value = props[propName];
    var centWidth = {
      centWidth: "".concat(sizes.cent, "px")
    };

    if (!value) {
      return _objectSpread({
        width: "".concat(sizes["default"], "px")
      }, centWidth);
    }

    var commaAmount = (value.match(/,/g) || []).length;
    var intAmount = value.length - commaAmount;
    var modifier = commaAmount * sizes.comma;
    var size = intAmount * sizes.character;
    return _objectSpread({
      width: "".concat(size + modifier + sizes.buffer, "px")
    }, centWidth);
  }));
};

export { withInputWidth };