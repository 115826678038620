import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100px;\n  height: 100px;\n  border-radius: 100px;\n  background-color: ", ";\n  color: ", ";\n  font-weight: 600;\n  font-size: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
export var DefaultSecurityIcon = styled.div(_templateObject(), function (props) {
  return props.backgroundColor || props.theme.colors.slate;
}, function (props) {
  return props.theme.colors.white;
});