import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  color: ", ";\n  font-size: 12px;\n  font-weight: 300;\n  margin-top: 3px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../../utils/styling/styled-components';
import { FieldError } from '../../Form/FieldError';
var ErrorLabel = styled(FieldError)(_templateObject(), function (props) {
  return props.theme.colors.red;
});
export { ErrorLabel };