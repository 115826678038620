import React, { ChangeEvent, FormEvent, useState } from 'react'
import { graphql } from 'react-apollo'

import {
  Button,
  ConfirmationModal,
  ModalActionTypes,
} from '@acorns/web-components'
import { Bodytext, Heading2, TextInputV3, Title } from '@acorns/web-components'
import { GenericAnalyticsClient, withAnalyticsClient } from '@acorns/web-utils'
import { FormikActions, withFormik } from 'formik'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import styled from 'styled-components'

import { emailToPrefill } from 'pages/login/form'
import {
  logInForgotPasswordBack,
  logInForgotPasswordNext,
  logInForgotPasswordViewed,
} from 'utils/segment'
import { FormContainer, InputContainer } from 'utils/styled-components'
import withAnalyticsIdentity from 'utils/with-analytics-identity'

import Layout from '../../components/Layout'
import {
  Values,
  handleFormSubmit,
  mapPropsToValues,
  validationSchema,
} from './form'
import { forgotPassword } from './mutation'

export type Props = {
  children: any
  values: Values
  isSubmitting: boolean
  isValid: boolean
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
  onBackPress: () => void
  handleModal: () => void
  handleContactSupport: () => void
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  submitForm: () => Promise<any>
  handleReset: () => void
  handleBlur: (event: FormEvent<HTMLInputElement>) => void
  reset: (options?: any) => any
  analytics: GenericAnalyticsClient
} & FormikActions<{ email: string }>

const Email = styled(TextInputV3)`
  display: block;
  max-width: 340px;
  width: 100%;
  margin-bottom: 30px;
`

const SubmitButton = styled(Button)`
  max-width: 340px;
  width: 100%;
`

const Body = styled(Bodytext)`
  text-align: center;
  display: block;
  margin-bottom: 30px;
  max-width: 340px;
`

const LoginTitle = styled(Title)`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const enhance = compose(
  withAnalyticsClient,
  withAnalyticsIdentity,
  graphql(forgotPassword, { name: 'reset' }),
  withState('isModalOpen', 'setIsModalOpen', false),

  withFormik({
    validationSchema,
    mapPropsToValues,
    handleSubmit: handleFormSubmit,
  }),
  withHandlers({
    onBackPress:
      ({ history, analytics }) =>
      () => {
        analytics.track('Button Tapped', logInForgotPasswordBack)
        const url = document.referrer.split('/')
        if (url[3] === 'redeem') {
          history.push('/redeem')
        } else {
          history.push('/')
        }
      },
    handleModal:
      ({ isModalOpen, setIsModalOpen, history, analytics }) =>
      () => {
        analytics.track('Button Tapped', logInForgotPasswordNext)
        if (isModalOpen) {
          setIsModalOpen(false)
          history.push('/')
        } else {
          setIsModalOpen(true)
        }
      },
    handleContactSupport:
      ({ setIsModalOpen }) =>
      () => {
        setIsModalOpen(false)
        window.location.href = 'https://help.acorns.com/contact'
      },
  }),
  withHandlers({
    handleReset:
      ({ handleModal, submitForm }) =>
      (event) => {
        event.preventDefault()
        submitForm().then(handleModal)
      },
  }),
  lifecycle<Props, {}>({
    componentDidMount() {
      this.props.analytics.track('Screen Viewed', logInForgotPasswordViewed)
    },
  }),
)

export const ForgotPassword = ({
  onBackPress,
  handleReset,
  handleChange,
  values,
  isSubmitting,
  isValid,
  isModalOpen,
  handleModal,
  handleContactSupport,
}: Props) => {
  return (
    <Layout
      title={<Heading2>Password Reset</Heading2>}
      showBack={true}
      onBackPress={onBackPress}
    >
      <FormContainer>
        <Form noValidate={true} onSubmit={handleReset}>
          <LoginTitle>Forgot Password</LoginTitle>
          <Body>
            Please enter your email address. Reset instructions will be sent to
            you.
          </Body>
          <InputContainer>
            <Email
              label={{ value: 'Email' }}
              inputProps={{
                autoFocus: true,
                name: 'email',
                placeholder: 'Enter your email',
                onChange: handleChange,
                value: values.email,
              }}
            />
          </InputContainer>

          <SubmitButton
            disabled={isSubmitting || (!emailToPrefill && !isValid)}
            onPress={handleReset}
          >
            Reset Password
          </SubmitButton>
        </Form>
      </FormContainer>

      <ConfirmationModal
        isOpen={isModalOpen}
        actions={[
          {
            handler: handleContactSupport,
            label: 'Contact Support',
            type: ModalActionTypes.secondary,
          },
          {
            handler: handleModal,
            label: 'Got it!',
            type: ModalActionTypes.primary,
          },
        ]}
        title="Forgot Password"
        message="If our records match the email provided, you'll receive an email with reset instructions. If you do not receive an email in the next few minutes, please try again."
      />
    </Layout>
  )
}

export default enhance(ForgotPassword)
