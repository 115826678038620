/**
 * Naming convention: Anchor Position + Content Position
 *
 * Example: BottomCenter instructs the component to "align the bottom of the anchor with the center of the content"
 *
 * Note: the order of these enum values matter. Please see utils in ./alignment.ts
 */
export var Alignment;

(function (Alignment) {
  Alignment[Alignment["TopCenter"] = 0] = "TopCenter";
  Alignment[Alignment["TopLeft"] = 1] = "TopLeft";
  Alignment[Alignment["TopRight"] = 2] = "TopRight";
  Alignment[Alignment["BottomCenter"] = 3] = "BottomCenter";
  Alignment[Alignment["BottomLeft"] = 4] = "BottomLeft";
  Alignment[Alignment["BottomRight"] = 5] = "BottomRight";
  Alignment[Alignment["LeftBottom"] = 6] = "LeftBottom";
  Alignment[Alignment["LeftCenter"] = 7] = "LeftCenter";
  Alignment[Alignment["LeftTop"] = 8] = "LeftTop";
  Alignment[Alignment["RightBottom"] = 9] = "RightBottom";
  Alignment[Alignment["RightCenter"] = 10] = "RightCenter";
  Alignment[Alignment["RightTop"] = 11] = "RightTop";
})(Alignment || (Alignment = {}));