import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 60px;\n  padding: 20px;\n  font-size: 14px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: ", ";\n  cursor: pointer;\n  transition: background 150ms ease-in-out, transform 150ms linear;\n\n  &:hover,\n  &:active,\n  &:focus {\n    background: ", ";\n  }\n\n  &:first-child {\n    border-radius: ", "\n      0 0;\n  }\n\n  &:last-child {\n    border-radius: 0 0\n      ", ";\n  }\n\n  @media screen and (max-width: ", "px) {\n    padding: 20px 30px;\n  }\n\n  &:not(:last-child) {\n    border-bottom: 1px solid ", ";\n  }\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0.6;\n  position: fixed;\n  right: 0;\n  top: 70px;\n  z-index: 1;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff;\n  border-radius: ", ";\n  box-shadow: ", ";\n  position: absolute;\n  right: 10px;\n  top: 94px;\n  width: 240px;\n  z-index: 2;\n  &::before {\n    content: '';\n    display: inline-block;\n    position: absolute;\n    bottom: 100%;\n    border: 14px solid transparent;\n    border-bottom: 11px solid #fff;\n    right: 28px;\n  }\n\n  @media screen and (max-width: ", "px) {\n    bottom: 20px;\n    left: 20px;\n    position: fixed;\n    right: 20px;\n    top: auto;\n    width: auto;\n\n    &::before {\n      display: none;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var Menu = styled.div(_templateObject(), function (props) {
  return props.theme.borderRadius.large;
}, function (props) {
  return props.theme.shadows.lgMedium;
}, function (props) {
  return props.theme.breakpoints.md;
});
var Overlay = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.slate;
});
var Item = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.slate;
}, function (props) {
  return props.theme.colors.ivoryLight;
}, function (props) {
  return props.theme.borderRadius.large + ' ' + props.theme.borderRadius.large;
}, function (props) {
  return props.theme.borderRadius.large + ' ' + props.theme.borderRadius.large;
}, function (props) {
  return props.theme.breakpoints.md;
}, function (props) {
  return props.theme.colors.ivory;
}, function (props) {
  return props.danger && "\n  color: ".concat(props.theme.colors.red, ";\n");
});
export { Menu, Overlay, Item };