var COMMA_REGEX = /,/g;
var NON_DIGIT_REGEX = /\D/g;
export var sanitize = function sanitize(value) {
  return value.replace(NON_DIGIT_REGEX, '').replace(COMMA_REGEX, '').trim();
};
export var hasAlpha = function hasAlpha(value) {
  return Boolean(value.match(NON_DIGIT_REGEX)) && value.length === 1;
};
export var getKey = function getKey(event) {
  var key = event.key || event.keyCode;

  if (!key || key.toLowerCase() === 'unidentified') {
    var code = event.which || event.code;
    key = String.fromCharCode(code);
  }

  return key.toLowerCase();
};
export var scheduleSelectionChange = function scheduleSelectionChange(input) {
  var value = input.value;
  var selectionStart = input.selectionStart;

  if (value[selectionStart] === ',') {
    selectionStart = selectionStart + 1;
  }

  if (selectionStart < value.length) {
    setTimeout(function () {
      return input.setSelectionRange(selectionStart, selectionStart);
    }, 0);
  }
};