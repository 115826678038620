import memoize from 'lodash.memoize';
import { isAndroid, resolveAndroidToken } from './android';
import { isIos, resolveIosToken } from './ios';
import { validateMobileIdentity } from './utils';
/**
 * Reads auth values passed to webview via query parameters.
 * Note: this is now the preferred way for passing credentials from mobile app to webview
 */

export var resolveFromQueryParams = function resolveFromQueryParams() {
  var params = new URLSearchParams(window.location.search);
  var token = params.get('mobileToken') || undefined;
  var uuid = params.get('mobileUuid') || undefined;
  var sessionId = params.get('mobileSessionId') || undefined;
  return validateMobileIdentity({
    sessionId: sessionId,
    token: token,
    uuid: uuid
  });
};
/**
 * Accounts for legacy implementations of webviews where credentials are provided via global variables
 * Note: this is NOT the preferred way of passing credentials from mobile app to webview
 */

export var resolveFromWindow = function resolveFromWindow() {
  var _ref = window,
      sessionId = _ref.mobileSessionId,
      token = _ref.mobileToken,
      uuid = _ref.mobileUuid;
  return validateMobileIdentity({
    sessionId: sessionId,
    token: token,
    uuid: uuid
  });
};
/**
 * Credential chain. Attempts to resolve credentials from mobile app in the following order
 *
 * 1. Checks url query parameters (resolveFromQueryParams)
 * 2. Checks global variables (resolveFromWindow)
 * 3. Checks via legacy implementation (resolveIosToken or resolveAndroidToken)
 * 4. Otherwise returns null
 */

export var getMobileIdentity = function getMobileIdentity(client) {
  return new Promise(function (resolve) {
    var normalizedClient = (client === null || client === void 0 ? void 0 : client.toLowerCase()) || ''; // First attempt to read from query params, if not defined check global variables

    var mobileIdentity = resolveFromQueryParams() || resolveFromWindow();

    if (mobileIdentity) {
      resolve(mobileIdentity);
    } else if (isIos(normalizedClient)) {
      resolveIosToken(resolve);
    } else if (isAndroid(normalizedClient)) {
      resolveAndroidToken(resolve);
    } else {
      resolve(null);
    }
  });
};
/**
 * Safely attempts to resolve mobile credentials for webview.
 * Memoized as it can be called multiple times a session depending on how the GQL client is configured.
 */

export var safeGetMobileIdentity = memoize(function (client) {
  return getMobileIdentity(client)["catch"](function () {
    return null;
  });
});