import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useEffect, useRef } from 'react';
import { defaults } from '../../utils/HOCs/withTrackClicked/defaults';
import { useAnalyticsClient } from '../providers/analytics';
import { verifyRender } from '../common/analytics/verifyRender';

var useTrackRendered = function useTrackRendered(events) {
  var analytics = useAnalyticsClient();
  var _events$defaultFields = events.defaultFields,
      defaultFields = _events$defaultFields === void 0 ? defaults('Container Rendered') : _events$defaultFields,
      _events$fields = events.fields,
      fields = _events$fields === void 0 ? {} : _events$fields;
  var componentRef = useRef(null);
  useEffect(function () {
    if (verifyRender(componentRef)) analytics.track('Container Rendered', _objectSpread(_objectSpread({}, defaultFields), fields));
  }, []);
  return {
    componentRef: componentRef
  };
};

export { useTrackRendered };