import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'

import { theme } from '@acorns/web-components'
import { useAnalyticsClient } from '@acorns/web-utils'
import { getImageAsset } from '@acorns/web-utils'

import acornsTitle from 'assets/images/acorns-title.svg'
import { routes } from 'src/routes'
import { invalidResetTokenScreenView } from 'utils/segment'
import { RESET_PASSWORD_INVALID_TOKEN } from 'utils/testing/testIds'

import Layout from '../../components/Layout'
import {
  BodyText,
  StyledButton,
  StyledDiv,
  StyledPage,
  StyledTitle,
} from './styled-components'

export const InvalidResetToken = () => {
  const analytics = useAnalyticsClient()
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm}px)`,
  })

  const history = useHistory()
  const invalidResetPasswordImage = getImageAsset(
    '/login/resetPassword/invalidToken',
    'svg',
  ).url

  const onPress = () => {
    history.push(routes.forgotPassword.index)
  }

  useEffect(() => {
    analytics.track('Screen Viewed', invalidResetTokenScreenView())
  }, [analytics])

  if (isMobile) {
    return (
      <Layout title={<img src={acornsTitle} width={97} height={26} />}>
        <StyledPage
          content={
            <StyledDiv>
              <img
                src={invalidResetPasswordImage}
                width={198.4}
                height={120.7}
                alt=""
                data-testid={RESET_PASSWORD_INVALID_TOKEN}
              />
              <StyledTitle>Password Reset Expired</StyledTitle>
              <BodyText>
                Oops, looks like the password reset link is invalid or has
                expired. Please try again.
              </BodyText>
            </StyledDiv>
          }
          footer={<StyledButton onPress={onPress}>Try Again</StyledButton>}
        />
      </Layout>
    )
  }
  return (
    <Layout title={<img src={acornsTitle} width={97} height={26} />}>
      <StyledDiv>
        <img
          src={invalidResetPasswordImage}
          width={198.4}
          height={120.7}
          alt=""
          data-testid={RESET_PASSWORD_INVALID_TOKEN}
        />
        <StyledTitle>Password Reset Expired</StyledTitle>
        <BodyText>
          Oops, looks like the password reset link is invalid or has expired.
          Please try again.
        </BodyText>
        <StyledButton onPress={onPress}>Try Again</StyledButton>
      </StyledDiv>
    </Layout>
  )
}
