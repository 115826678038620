import React from 'react'

import { ConfirmationModal, ModalActionTypes } from '@acorns/web-components'
import { GenericAnalyticsClient, withAnalyticsClient } from '@acorns/web-utils'
import Raven from 'raven-js'

import acornsTitle from 'assets/images/acorns-title.svg'
import { routes } from 'src/routes'
import getWindow from 'utils/get-window'

import Layout from '../../components/Layout'

type Props = {
  analytics: GenericAnalyticsClient
}
export class ReactErrorBoundaryError extends Error {
  constructor(errorBoundaryError: Error, windowLocation: string) {
    super()
    this.name = 'LoginAppErrorBoundaryError'
    this.message = `Exception caught with error boundary at ${windowLocation}: ${errorBoundaryError}`
  }
}

class ErrorBoundaryClass extends React.Component<Props, { hasError: boolean }> {
  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error: Error, info: any) {
    this.props.analytics.track('Login Page Crash', {
      error: error.toString(),
      error_boundary_url_location: getWindow().location.href,
      ...info,
    })

    Raven.captureException(
      new ReactErrorBoundaryError(error, getWindow().location.href),
      {
        extra: {
          info,
        },
      },
    )
  }

  public handleModalTrigger = () => {
    window.location.href = routes.login
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Layout title={<img src={acornsTitle} width={97} height={26} />}>
          <ConfirmationModal
            actions={[
              {
                handler: this.handleModalTrigger,
                label: 'Got it',
                type: ModalActionTypes.primary,
              },
            ]}
            isOpen={this.state.hasError}
            title="Oops!"
            message="Something went wrong. Please try again in a few minutes."
          />
        </Layout>
      )
    }
    return this.props.children
  }
}
export const ErrorBoundary = withAnalyticsClient(ErrorBoundaryClass)
