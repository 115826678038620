import React from 'react'

import { ConfirmationModal, ModalActionTypes } from '@acorns/web-components'

import AlertBadge from 'assets/icons/alert-badge.png'
import {
  NoSuspendedTokenExceptionModal,
  UserSuspendedExceptionModal,
} from 'components/Modals'
import Environment from 'utils/environment'

type Props = {
  exception: string
  isExceptionModalOpen: boolean
  handleCloseExceptionModal: () => void
  env?: Environment
}

const AuthenticateExceptionHandler = (props: Props) => {
  if (props.exception === 'UserLockedException') {
    return (
      <ConfirmationModal
        isOpen={props.isExceptionModalOpen}
        actions={[
          {
            label: 'Reset password',
            type: ModalActionTypes.primary,
            href: '/forgot-password',
          },
        ]}
        title="Account locked"
        message="Due to unusual activity, we’ve locked your account to keep your money safe. Please update your password to unlock it"
        image={AlertBadge}
      />
    )
  } else if (props.exception === 'InvalidCredentialsException') {
    return (
      <ConfirmationModal
        isOpen={props.isExceptionModalOpen}
        actions={[
          {
            handler: props.handleCloseExceptionModal,
            label: 'Try Again',
            type: ModalActionTypes.danger,
          },
        ]}
        title="Login Error"
        message="The email and password combination you entered is incorrect."
        image={AlertBadge}
      />
    )
  } else if (props.exception === 'DeviceBlockedException') {
    return (
      <ConfirmationModal
        isOpen={props.isExceptionModalOpen}
        actions={[
          {
            handler: props.handleCloseExceptionModal,
            label: 'Go back',
            type: ModalActionTypes.secondary,
          },
        ]}
        title="Sorry about this"
        message="For security reasons, we have blocked this device from further login attempts. We sent an email with instructions to regain access to your account."
        image={AlertBadge}
      />
    )
  } else if (props.exception === 'UserSuspendedException') {
    return (
      <UserSuspendedExceptionModal
        handleCloseExceptionModal={props.handleCloseExceptionModal}
        env={props.env}
        isExceptionModalOpen={props.isExceptionModalOpen}
      />
    )
  } else if (props.exception === 'NoSuspendedTokenException') {
    return (
      <NoSuspendedTokenExceptionModal
        handleCloseExceptionModal={props.handleCloseExceptionModal}
        env={props.env}
        isExceptionModalOpen={props.isExceptionModalOpen}
      />
    )
  } else if (props.exception === 'LoginRateLimitedException') {
    return (
      <ConfirmationModal
        isOpen={props.isExceptionModalOpen}
        actions={[
          {
            handler: props.handleCloseExceptionModal,
            label: 'Go Back',
            type: ModalActionTypes.secondary,
          },
        ]}
        title="Sorry about this"
        message="For your security, we limit login attempts and that limit has been met. Please wait a few minutes and try again."
        image={AlertBadge}
      />
    )
  } else {
    return null
  }
}

export default AuthenticateExceptionHandler
