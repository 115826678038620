import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 22px;\n  font-weight: ", ";\n  margin: 0;\n  text-align: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../utils/styling/styled-components';
var Title = styled.h1(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.slate;
}, function (props) {
  return props.bold ? '600' : '400';
}, function (props) {
  return props.textAlign ? props.textAlign : 'unset';
});
export { Title };