import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: normal;\n  color: ", ";\n  margin-top: 5px;\n\n  & a {\n    color: ", ";\n    text-decoration: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from '../../utils/styling/styled-components';
import { TEXT_INPUT_HELP_TEXT } from '../../utils/testing/TestIDs';
var HelperText = styled.span.attrs({
  'data-testid': TEXT_INPUT_HELP_TEXT
})(_templateObject(), function (props) {
  return props.theme.colors.stone;
}, function (props) {
  return props.theme.colors.stone;
});
export { HelperText };