/* tslint:disable */

import from 'promise-polyfill/src/polyfill'
import from 'regenerator-runtime/runtime'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import Application from './application'
import createEnvironment from './config/env'
import sentry from './config/sentry'
import theme from './theme'

const APP_ID = 'web-app'
const ENVIRONMENT = createEnvironment()
const GRAPHQL_ENDPOINT = ENVIRONMENT.get('graphql')
const BUILD_ENV = ENVIRONMENT.get('buildEnv')
const HEAD = ENVIRONMENT.get('head')
const SENTRY = ENVIRONMENT.get('sentry')
const RELEASE = ENVIRONMENT.get('release')

// Configure raven-js for sentry exception capturing
sentry(SENTRY, BUILD_ENV, HEAD)

ReactGA.initialize(ENVIRONMENT.get('googleAnalytics'))
ReactGA.set({
  build: HEAD,
  release: RELEASE,
})

export default ReactDOM.render(
  <Application
    appId={APP_ID}
    env={ENVIRONMENT}
    graphqlUrl={GRAPHQL_ENDPOINT}
    theme={theme}
  />,
  document.getElementById('app'),
)
