import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { compose, withHandlers, withState } from 'recompose';

var withRef = function withRef(name, setter) {
  return compose(withState(name, 'setRef', null), withHandlers(_defineProperty({}, setter, function (props) {
    return function (ref) {
      if (!props[name]) {
        props.setRef(ref);
      }
    };
  })));
};

export { withRef };