import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { theme } from '../theme';
import './helpers/createConicalGradient';
export var getData = function getData(data) {
  var colors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var borderWidth = 2;

  var borderColors = _toConsumableArray(colors);

  return {
    datasets: [{
      pointRadius: 0,
      label: null,
      borderWidth: borderWidth,
      backgroundColor: colors,
      hoverBackgroundColor: colors,
      borderColor: borderColors,
      data: data
    }]
  };
}; // Documentation: http://www.chartjs.org/docs/latest/

export var resolveOptions = function resolveOptions(onItemHighlight, animateRotate, animateScale) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return _objectSpread({
    maintainAspectRatio: false,
    cutoutPercentage: 60,
    animation: {
      animateScale: animateScale,
      animateRotate: animateRotate
    },
    hover: {
      mode: 'index'
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: 10
    },
    legend: {
      display: false
    },
    events: ['mousemove', 'mouseleave', 'touchstart'],
    onClick: onItemHighlight,
    onHover: onItemHighlight
  }, options);
};
var BLURRED_COLOR = '#EAEBEC';

var styleGradientArc = function styleGradientArc(_ref) {
  var context = _ref.context,
      backgroundColors = _ref.backgroundColors,
      startAngle = _ref.startAngle,
      endAngle = _ref.endAngle,
      height = _ref.height;
  var xCoordinate = height / 2;
  var yCoordinate = height / 2;
  var angleOffset = Math.PI / 2;
  var gradient = context.createConicalGradient(xCoordinate, yCoordinate, startAngle - angleOffset, endAngle - angleOffset);
  var stopPointLength = 1 / (backgroundColors.length - 1);
  var stopPoint = 0;
  backgroundColors.forEach(function (color) {
    gradient.addColorStop(stopPoint, color);
    stopPoint += stopPointLength;
  });
  context.fillStyle = gradient.pattern;
  context.strokeStyle = gradient.pattern;
};

export var styleArc = function styleArc(_ref2) {
  var context = _ref2.context,
      options = _ref2.options,
      backgroundColor = _ref2.backgroundColor,
      isActive = _ref2.isActive,
      isBlurred = _ref2.isBlurred,
      borderWidth = _ref2.borderWidth,
      borderColor = _ref2.borderColor,
      currentIndex = _ref2.currentIndex,
      startAngle = _ref2.startAngle,
      endAngle = _ref2.endAngle,
      height = _ref2.height;
  context.globalCompositeOperation = 'destination-over';

  if (!(options === null || options === void 0 ? void 0 : options.straightEdgeJoin)) {
    context.lineJoin = 'bevel';
  }

  context.lineWidth = borderWidth;
  context.shadowBlur = 0; // Currently a dashed index cannot also be a gradient arc.

  var dashedIndices = options.dashedIndices;
  var isStatic = options["static"];
  var isDashedIndex = dashedIndices === null || dashedIndices === void 0 ? void 0 : dashedIndices.includes(currentIndex);

  if (!isStatic && isActive) {
    context.globalCompositeOperation = 'source-over';
    context.shadowBlur = 10;
    context.shadowColor = 'rgba(0, 0, 0, 0.2)';

    if (isDashedIndex) {
      context.lineWidth = borderWidth + 2;
    }
  }

  if (!isStatic && isBlurred) {
    context.fillStyle = BLURRED_COLOR;
  } else {
    if (Array.isArray(backgroundColor)) {
      // Gradient interactive
      styleGradientArc({
        context: context,
        backgroundColors: backgroundColor,
        startAngle: startAngle,
        endAngle: endAngle,
        height: height
      });
    } else if (isStatic) {
      // Single color static
      context.fillStyle = backgroundColor;
      context.strokeStyle = 'white';
    } else {
      // Single color interactive
      context.fillStyle = backgroundColor;
      context.strokeStyle = borderColor;
    }

    if (isDashedIndex) {
      // Set dashed border styling
      context.setLineDash([4, 3]);
      context.strokeStyle = theme.colors.stoneLight;
      context.stroke();
    } else {
      context.setLineDash([]);
    }

    if (!isStatic) {
      context.stroke();
    }
  }

  context.fill();
};