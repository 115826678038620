import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { usePortal } from './PromptPortalProvider';

var PromptPortal = function PromptPortal(_ref) {
  var children = _ref.children,
      shouldShow = _ref.shouldShow;

  var _useState = useState(uuid()),
      _useState2 = _slicedToArray(_useState, 1),
      modalId = _useState2[0];

  var _usePortal = usePortal(),
      requestToShow = _usePortal.requestToShow,
      closeModal = _usePortal.closeModal;

  useEffect(function () {
    if (shouldShow) {
      requestToShow(modalId, children);
    } else {
      closeModal(modalId);
    }
  }, [shouldShow, children]);
  useEffect(function () {
    return function () {
      return closeModal(modalId);
    };
  }, []);
  return null;
};

export { PromptPortal };