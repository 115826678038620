import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import * as Yup from 'yup';
var MIN = 'min';
var MAX = 'max';
var ONE_SPECIAL_CHAR = 'one_special_char';
var ONE_UPPERCASE_AND_LOWERCASE_CHAR = 'one_uppercase_and_lowercase_char';
var ONE_NUMBER = 'one_number';
var PASSWORDS_MATCH = 'passwords_match';
export var PASSWORD_VALIDATION_OBJECT = Yup.string().required().min(8, MIN).max(32, MAX).matches(/\W/, ONE_SPECIAL_CHAR).matches(/(?=.*[a-z])(?=.*[A-Z])/, ONE_UPPERCASE_AND_LOWERCASE_CHAR).matches(/\d/, ONE_NUMBER); // Validation schema to be used by Formik.

export var generatePasswordValidationSchema = function generatePasswordValidationSchema(showPasswordConfirmationField) {
  var passwordName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'password';
  var confirmationPasswordName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'confirmPassword';

  if (showPasswordConfirmationField) {
    var _schema;

    var schema = (_schema = {}, _defineProperty(_schema, passwordName, PASSWORD_VALIDATION_OBJECT), _defineProperty(_schema, confirmationPasswordName, Yup.string().oneOf([Yup.ref(passwordName), null], PASSWORDS_MATCH).required()), _schema);
    return Yup.object().shape(schema);
  } else {
    var _schema2 = _defineProperty({}, passwordName, PASSWORD_VALIDATION_OBJECT);

    return Yup.object().shape(_schema2);
  }
};
export var passwordErrorMap = function passwordErrorMap(showPasswordConfirmationField) {
  return [{
    label: MIN,
    message: 'Minimum 8 characters'
  }, {
    label: ONE_SPECIAL_CHAR,
    message: 'One special character'
  }, {
    label: ONE_UPPERCASE_AND_LOWERCASE_CHAR,
    message: 'One uppercase and lowercase character'
  }, {
    label: ONE_NUMBER,
    message: 'One number'
  }].concat(_toConsumableArray(showPasswordConfirmationField ? [{
    label: PASSWORDS_MATCH,
    message: 'Passwords match'
  }] : []));
}; // Helper to handle custom error states for dynamic password field.

export var determinePasswordErrorState = function determinePasswordErrorState(formikValidationErrors, customValidationErrors, passwordLength, errorText) {
  var state = false;
  errorText = errorText || '';

  if (customValidationErrors && formikValidationErrors) {
    state = true;
  } else if (errorText !== '') {
    state = true;
  } else {
    if (customValidationErrors) {
      state = passwordLength > 0 ? true : false;
    }

    if (formikValidationErrors) {
      state = passwordLength > 0 ? false : true;
    }
  }

  return state;
};