import { withApolloErrorHandler } from 'utils/error'

import { isTimeoutError, timeoutErrorDetails } from '../../utils/login-utils'

export const AuthenticateErrorHandler = () => ({ graphQLErrors }) => {
  if (isTimeoutError(graphQLErrors)) {
    return timeoutErrorDetails
  } else {
    return {
      body:
        'It looks like something went wrong. Please go back and try that again.',
      title: 'Oops!',
    }
  }
}

export const withAuthenticateErrorHandler = withApolloErrorHandler(
  'Authenticate',
  AuthenticateErrorHandler,
)
