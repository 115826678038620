import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    animation: ", " ", "ms ease-in-out;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: ", ";\n  line-height: ", ";\n  overflow: hidden;\n  padding-top: ", ";\n  \n  ", "\n  animation-delay: ", "ms;\n  animation-fill-mode: forwards;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    padding-top: ", ";\n  }\n  100% {\n    padding-top: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css, keyframes } from '../utils/styling/styled-components';

var shift = function shift(height) {
  return keyframes(_templateObject(), height);
};

var ShiftUp = styled('div')(_templateObject2(), function (props) {
  return props.height;
}, function (props) {
  return props.height;
}, function (props) {
  return props.height;
}, function (props) {
  return css(_templateObject3(), shift(props.height), props.duration || 800);
}, function (props) {
  return props.delay || 0;
});
export { ShiftUp };