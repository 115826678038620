import { withRouter } from 'react-router-dom'

import { createSink } from 'recompose'

const pageTitleMap = new Map<string, string>([
  ['/', 'Login'],
  ['/forgot-password', 'Forgot Password'],
])

export const setPageTitle = ({ location }) => {
  const pathname = location.pathname.toLowerCase()

  // Default page title and suffix.
  let pageTitle = 'Acorns'

  if (pageTitleMap.has(pathname)) {
    pageTitle = `${pageTitleMap.get(pathname)} | ${pageTitle}`
  }

  document.title = pageTitle
}

export default withRouter(createSink(setPageTitle))
