import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Chart } from 'chart.js';
import { USDFormatterRound } from '../../utils/number';

var getStyle = function getStyle(el, property) {
  return el.currentStyle ? el.currentStyle[property] : document.defaultView.getComputedStyle(el, null).getPropertyValue(property);
};

var getRelativePosition = function getRelativePosition(evt, chart) {
  var mouseX;
  var mouseY;
  var e = evt.originalEvent || evt;
  var canvas = evt.target || evt.srcElement;
  var boundingRect = canvas.getBoundingClientRect();
  var touches = e.touches;

  if (touches && touches.length > 0) {
    mouseX = touches[0].clientX;
    mouseY = touches[0].clientY;
  } else {
    mouseX = e.clientX;
    mouseY = e.clientY;
  } // Scale mouse coordinates into canvas coordinates
  // by following the pattern laid out by 'jerryj' in the comments of
  // http://www.html5canvastutorials.com/advanced/html5-canvas-mouse-coordinates/


  var paddingLeft = parseFloat(getStyle(canvas, 'padding-left'));
  var paddingTop = parseFloat(getStyle(canvas, 'padding-top'));
  var paddingRight = parseFloat(getStyle(canvas, 'padding-right'));
  var paddingBottom = parseFloat(getStyle(canvas, 'padding-bottom'));
  var width = boundingRect.right - boundingRect.left - paddingLeft - paddingRight;
  var height = boundingRect.bottom - boundingRect.top - paddingTop - paddingBottom; // We divide by the current device pixel ratio, because the canvas is scaled up by that amount in each direction. However
  // the backend model is in unscaled coordinates. Since we are going to deal with our model coordinates, we go back here

  mouseX = Math.round((mouseX - boundingRect.left - paddingLeft) / width * canvas.width / chart.currentDevicePixelRatio);
  mouseY = Math.round((mouseY - boundingRect.top - paddingTop) / height * canvas.height / chart.currentDevicePixelRatio);
  return {
    x: mouseX,
    y: mouseY
  };
};

var getNearestItems = function getNearestItems(chart, position, distanceMetric) {
  var minDistance = Number.POSITIVE_INFINITY;
  var elements = [];
  var i;
  var ilen;
  var meta = chart.getDatasetMeta(0);

  for (i = 0, ilen = meta.data.length; i < ilen; ++i) {
    var element = meta.data[i];

    if (!element._view.skip) {
      var center = element.getCenterPoint();
      var distance = distanceMetric(position, center);

      if (distance < minDistance) {
        elements = [element];
        minDistance = distance;
      } else if (distance === minDistance) {
        // Can have multiple items at the same distance in which case we sort by size
        elements.push(element);
      }
    }
  }

  return elements;
};

var getDistanceMetricForAxis = function getDistanceMetricForAxis(axis) {
  var useX = axis.indexOf('x') !== -1;
  var useY = axis.indexOf('y') !== -1;
  return function (pt1, pt2) {
    var deltaX = useX ? Math.abs(pt1.x - pt2.x) : 0;
    var deltaY = useY ? Math.abs(pt1.y - pt2.y) : 0;
    return Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
  };
};

var getElementsAtXAxis = function getElementsAtXAxis(e, chart) {
  var position = getRelativePosition(e, chart);
  var distanceMetric = getDistanceMetricForAxis('x');
  var items = getNearestItems(chart, position, distanceMetric);
  var elements = [];

  if (!items.length) {
    return [];
  }

  var meta = chart.getDatasetMeta(0);
  var element = meta.data[items[0]._index];

  if (element && !element._view.skip) {
    elements.push(element);
  }

  return elements;
};

var getElementsAtIndex = function getElementsAtIndex(chart, index) {
  var elements = [];
  var i = 0;
  var k = Object.keys(chart.data.datasets[i]._meta)[0];
  var j = Math.min(Math.max(0, index), chart.data.datasets[i]._meta[k].data.length - 1);
  elements.push(chart.data.datasets[i]._meta[k].data[j]);
  return elements;
};

export var tooltip = function tooltip(chart, elements) {
  // This will enable drag-n-drop upon the marker (z-index: 2)
  chart.canvas.style.setProperty('z-index', 3);
  chart.canvas.style.setProperty('position', 'relative'); // required for safari

  var cp = elements === null || elements === void 0 ? void 0 : elements[0]; // current point

  var accountValue = chart.config.data.datasets[cp._datasetIndex].data[cp._index];
  return {
    accountValue: accountValue,
    projectedMarkerPosition: {
      x: cp === null || cp === void 0 ? void 0 : cp._model.x,
      y: cp === null || cp === void 0 ? void 0 : cp._model.y
    },
    primaryMarkerLegend: USDFormatterRound.format(accountValue),
    date: chart.config.data.labels[cp._index],
    currentIndex: cp._index
  };
};

var setupTooptip = function setupTooptip() {
  var instanceList = [];
  Object.values(Chart.instances).forEach(function (value) {
    return instanceList.push(value);
  }); // with need to find the performance graph within the possible different charts on a page

  var chart = instanceList.find(function (elem) {
    var _elem$config;

    return (elem === null || elem === void 0 ? void 0 : (_elem$config = elem.config) === null || _elem$config === void 0 ? void 0 : _elem$config.type) === 'line';
  });
  return chart;
};

export var initTooltip = function initTooltip() {
  var chart = setupTooptip();

  if (!chart) {
    return null;
  }

  var elements = getElementsAtIndex(chart, chart.data.datasets[0].data.length);
  return tooltip(chart, elements);
};
export var buildTooltip = function buildTooltip(event, points, dates, isClicked) {
  var chart = setupTooptip();

  var shallowPoints = _toConsumableArray(points);

  if (isClicked) {
    chart.config.data.labels = dates;
    chart.config.data.datasets[0].data = shallowPoints;
    chart.update();
  }

  if (!chart) {
    return null;
  }

  if (event.srcElement === undefined) {
    event.srcElement = event.target; // required for firefox
  }

  var elements = getElementsAtXAxis(event, chart);

  if (elements.length !== 1) {
    return null;
  }

  return tooltip(chart, elements);
};